/**
 * Instafeed.js
 */

function init() {
	if ($("#js-insta-carousel").length == 0) {
		return;
	}

	const feed = new Instafeed({
		get: "user",
		userId: "1590087315",
		accessToken: "1590087315.57c4e1f.fd626868cc6f4f8583053fd94038cedf",
		template:
			'<a href="{{link}}" target="_blank"><img data-src="{{image}}" class="[ instagram-image ]  [ lazyload ]"/></a>',
		target: "js-insta-carousel",
		resolution: "standard_resolution",
		after() {
			$("#js-insta-carousel").slick({
				infinite: true,
				arrows: false,
				slidesToShow: 8,
				slidesToScroll: 8,
				responsive: [
					{
						breakpoint: 1921,
						settings: {
							arrows: false,
							slidesToShow: 8,
							slidesToScroll: 8,
						},
					},
					{
						breakpoint: 1280,
						settings: {
							arrows: false,
							slidesToShow: 6,
							slidesToScroll: 6,
						},
					},
					{
						breakpoint: 1024,
						settings: {
							arrows: false,
							slidesToShow: 4,
							slidesToScroll: 4,
						},
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							slidesToShow: 3,
							slidesToScroll: 3,
						},
					},
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							slidesToShow: 3,
							slidesToScroll: 3,
						},
					},
				],
			});
		},
	});

	feed.run();
}

export { init };
