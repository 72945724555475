/**
 * JS CIRCLE PROGRESS
 */

// DOM
let $circleProgressItems;
let $circleProgressContainer;
let $window;

function init() {
	$circleProgressContainer = $(".js-circle-progress");
	if ($circleProgressContainer.length == 0) {
		return;
	}

	$circleProgressItems = $(".js-circle-progress-item");
	$window = $(window);

	_initCircleProgress();
	$window.scroll(isScrolledToView);
}

function _initCircleProgress() {
	const circleSize = $(window).width() < 1024 ? 100 : 150;

	$circleProgressItems
		.circleProgress({
			value: 0,
			size: circleSize,
			animation: false,
			fill: {
				color: "#c4a36c",
			},
		})
		.on("circle-animation-progress", function (event, progress) {
			const $keyFactNumber = $(this).data("keyfact-number");
			$(this)
				.find(".c-circle-progress__number")
				.html(parseInt($keyFactNumber * progress));
			$(this)
				.find(".c-circle-progress__comma-number")
				.html($keyFactNumber * progress.toFixed(1));
		});
}

function isScrolledToView() {
	const docViewTop = $window.scrollTop();
	const docViewBottom = docViewTop + $window.height();

	const elemTop = $circleProgressContainer.offset().top;
	const elemBottom = elemTop + $circleProgressContainer.height();

	const isCompletelyInView = elemBottom <= docViewBottom && elemTop >= docViewTop;

	if (isCompletelyInView) {
		_startAnimation();
		$(window).off("scroll", isScrolledToView);
	}
}

function _startAnimation() {
	$circleProgressItems.circleProgress({
		value: 1,
		animation: {
			duration: 2000,
		},
	});
}

export { init };
