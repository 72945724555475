function init() {
	if ("loading" in HTMLImageElement.prototype && "MutationObserver" in window) {
		const nativelyLazyLoad = () => {
			document.querySelectorAll("img.lazyload").forEach((img) => {
				img.loading = "lazy";
				const setSrc = (el) => {
					if (el.dataset.src) {
						el.src = el.dataset.src;
					}
					if (el.dataset.srcset) {
						el.srcset = el.dataset.srcset;
					}
				};
				setSrc(img);
				if (img.parentNode.nodeName.toUpperCase() === "PICTURE") {
					img.parentNode.querySelectorAll("source").forEach((source) => setSrc(source));
				}
				img.classList.remove("lazyload");
			});
		};

		nativelyLazyLoad();

		new MutationObserver(nativelyLazyLoad).observe(document.body, { attributes: true, childList: true, subtree: true });
	} else {
		const resourcesHostname = window.ocm.config.resourcesHostname;
		const script = document.createElement("script");
		script.src = resourcesHostname.concat("/_ui/shared/js/lazysizes.min.js");
		document.body.appendChild(script);
	}
}

export { init };
