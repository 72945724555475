let $teaser_header_more;

function init() {
	$teaser_header_more = $(".js-header-teaser-copy-more");
	$teaser_header_more.each(function () {
		const $this = $(this);
		const $contentContainer = $this.prev();
		const moreText = $this.attr("data-moretext");
		const lessText = $this.attr("data-lesstext");

		$this.text(moreText);

		$this.on("click", () => {
			if (!$contentContainer.hasClass("is-open")) {
				$contentContainer.addClass("is-open");
				$this.text(lessText).addClass("is-open");
			} else {
				$contentContainer.removeClass("is-open");
				$this.text(moreText).removeClass("is-open");
			}
		});
	});
}

export { init };
