const brandLogos = $(".js-brand-slider");

const prevArrow = `<button class="c-brand-slider__arrow  c-brand-slider__arrow--left" aria-label="Zurück">
							<svg class="svg-icon" style="transform: rotate(180deg)">
								<use xlink:href="#icon-arrow-fat-right" href="#icon-arrow-fat-right"/>
							</svg></button>`;
const nextArrow = `<button class="c-brand-slider__arrow c-brand-slider__arrow--right" aria-label="Weiter">
							<svg class="svg-icon">
								<use xlink:href="#icon-arrow-fat-right" href="#icon-arrow-fat-right"/>
							</svg></button>`;

function init() {
	brandLogos.each(function () {
		const slider = this;
		const $slider = $(slider);
		const slides = slider.querySelectorAll(".js-brand-slider__item");
		const slidesItemCount = slides.length;
		const autorotation = slider.dataset["autorotation"] === "true";
		const sliderWidth = slider.clientWidth;
		const slideWidth =
			slidesItemCount > 0 ? slides[0].offsetWidth + parseInt(getComputedStyle(slides[0]).marginRight) : 200; //assumed width if class .js-brand-slider__item is missing
		const slidesToShowCount = Math.floor(sliderWidth / slideWidth);

		const configurations = {
			slidesToShow: slidesToShowCount,
			slidesToScroll: 1,
			prevArrow,
			nextArrow,
			arrows: !autorotation,
			infinite: autorotation,
			centerMode: autorotation,
			variableWidth: true,
			autoplay: autorotation,
			autoplaySpeed: 0,
			speed: autorotation ? 8000 : 200,
			draggable: !autorotation,
			swipe: !autorotation,
			swipeToSlide: !autorotation,
			pauseOnFocus: !autorotation,
			pauseOnHover: !autorotation,
			cssEase: autorotation ? "linear" : "ease-in-out",
			dots: false,
			responsive: [
				{
					breakpoint: 719,
					settings: {
						arrows: false,
					},
				},
			],
		};

		if (slidesItemCount > slidesToShowCount) {
			$slider.slick(configurations);
		} else {
			$slider.css("text-align", "center");
		}
	});
}

export { init };
