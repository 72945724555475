/**
 * Password Change Layer
 */

import * as formValidate from "@sharedJS/formValidate";
import * as rest from "@sharedJS/rest";

let $myAccountForgottenPwChangeForm;

// Settings
let errorClass;

function init() {
	$myAccountForgottenPwChangeForm = $("#myAccountForgottenPwChangeForm");
	if ($myAccountForgottenPwChangeForm.length < 1) {
		return;
	}

	// Settings
	errorClass = "u-error-text";

	// Bind Actions
	registerPwChangeFormEvents();
}

function registerPwChangeFormEvents() {
	//--------------------------
	// Form Error Handling
	$myAccountForgottenPwChangeForm.attr("novalidate", "novalidate");

	$myAccountForgottenPwChangeForm.on("change", "input, select", (event) => {
		formValidate.validateElement(event.currentTarget, errorClass);
	});
	formValidate.removeErrorNoticeOnKeyUp($myAccountForgottenPwChangeForm, { errorClass });

	// submit Form
	$myAccountForgottenPwChangeForm.on("submit", function (event) {
		event.preventDefault();

		// validate form and start login process
		const isValid = formValidate.validateForm(this, { errorClass });
		if (isValid) {
			changePasswordAction();
			return true;
		}
	});
}

function changePasswordAction() {
	const context = {
		form: $myAccountForgottenPwChangeForm,
		callback: changePasswordAction,
	};
	rest.submitForm(context);
}

export { init };
