/**
 * Utility Objects
 *
 */

let slotButton;
let slotContent;

// --------------------------
function init() {
	slotButton = $(".js-slot-title");
	slotContent = $(".js-slot-content");

	if (slotContent.length) {
		slotContent.hide();

		slotButton.on("click", function () {
			$(this).toggleClass("is-open");
			slotContent.slideToggle("slow");
		});
	}
}

export { init };
