// hier werden die secondary-nav-items auf activ / inactiv gesetzt
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

const breadcrumbSelector = ".js-breadcrumb-wrapper ul li"; // breadcrumb-Elemente wenn es nicht vue ist  !!
const secondaryNavItemSelector = ".js-secondary-nav__item"; // secondary Nav Items (neu / sale/ premium / bekleidung / ... /marken / ...)
const secondaryNavLinkSelector = ".js-secondary-nav__link"; // secondary Nav Links (neu / sale/ premium / bekleidung / ... /marken / ...)
const allNavLinkSelector = ".js-nav-link"; // alle links in 2nd / flyout / ist auch in offcanvas .... c-acordion-nav ....
const activeClass = "is-active";

const secondaryNavItemsLinkList = [...document.querySelectorAll(`${secondaryNavLinkSelector}[href]`)]; // ... nur die Links
const secondaryNavItemsList = [...document.querySelectorAll(secondaryNavItemSelector)];

const isSpaPage = !!document.querySelector("#js-epoqSPA");

function init() {
	try {
		if (!isSpaPage) {
			// activate initially
			const initialActiveItem = checkForBreadcrumb() || checkForBrowserUrl() || checkForSubLinks();
			addActiveClass(initialActiveItem);
		} else {
			// activate on Navigation_Change in SPA
			OcmMessenger.subscribe(messengerPublicationTypes.NAVIGATION_CHANGE, (data) => {
				const url = data.data.url;
				const activeItem = findActiveNavItem(url) || checkForSubLinks(url);
				addActiveClass(activeItem);
			});
		}
	} catch (error) {
		console.error(error);
	}
}

// checks for active Items
function checkForBreadcrumb() {
	const bradcrumbPathname = document.querySelectorAll(breadcrumbSelector)[1]?.querySelector("a")?.pathname;
	return findActiveNavItem(bradcrumbPathname);
}

function checkForBrowserUrl() {
	return findActiveNavItem(window.location.pathname);
}

function checkForSubLinks(pathname = window.location.pathname) {
	return secondaryNavItemsList.find((item) => {
		const subLinks = [...item.querySelectorAll(`${allNavLinkSelector}[href], ${allNavLinkSelector}[data-goto]`)];

		return subLinks.some((el) => {
			const elementPathname =
				el.pathname ||
				(el.dataset.goto &&
					new URL(
						el.dataset.goto.startsWith("http")
							? el.dataset.goto
							: `https://${window.location.hostname}${el.dataset.goto}`
					)?.pathname);
			return elementPathname === pathname;
		});
	});
}

function findActiveNavItem(pathname) {
	if (!pathname) return;
	return secondaryNavItemsLinkList?.filter((el) => comparePathNames(el.pathname, pathname))?.[0]?.parentElement;
}

function comparePathNames(pathname1, pathname2) {
	// Ensure both pathnames have a trailing slash
	pathname1 += pathname1.endsWith("/") ? "" : "/";
	pathname2 += pathname2.endsWith("/") ? "" : "/";
	// is equal
	if (pathname1 === pathname2) return true;
	// only nameRoot is equal (sport == sport-overview)
	const pathNameItemToCompare = window.ocm?.isClassicSite ? 3 : 4;
	const nameRoot1 = pathname1.split("/")[pathNameItemToCompare].split("-")[0];
	const nameRoot2 = pathname2.split("/")[pathNameItemToCompare].split("-")[0];
	if (nameRoot1 === nameRoot2) return true; // main part is equal sport == sport-overview;
	// special Names Mapping
	const beautyWohnen = ["beauty", "wohnen"];
	if (beautyWohnen.includes(nameRoot1) && beautyWohnen.includes(nameRoot2)) return true;
	return false;
}

// add and remove active-Class
function addActiveClass(navItem) {
	removeActiveClass();
	navItem?.classList?.add(activeClass);
}

const removeActiveClass = () => {
	secondaryNavItemsList.forEach((navItem) => {
		navItem?.classList?.remove(activeClass);
	});
};

export { init };
