/**
 * Offer Slider on Brand Detail Page
 */

let mainSlider;
let homeSlider;
let fallbackSlider;

function init() {
	mainSlider = $(".js-offer-slider");
	homeSlider = $(".js-offer-home-slider");
	fallbackSlider = $(".js-offer-slider-fallback");

	offerMainSlider();
	offerHomeSlider();
	offerFallbackSlider();
}

function offerMainSlider() {
	mainSlider.each(function () {
		const thisSlider = $(this);
		const configurations = {
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			infinite: false,
			centerMode: false,
			variableWidth: false,
			autoplay: false,
			draggable: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: false,
			responsive: [
				{
					breakpoint: 719,
					settings: {
						slidesToShow: 1,
						arrows: false,
						draggable: true,
						swipe: true,
						swipeToSlide: true,
						dots: true,
					},
				},
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 2,
						arrows: false,
						draggable: true,
						swipe: true,
						swipeToSlide: true,
						dots: true,
					},
				},
			],
		};

		thisSlider.on("init", () => {}).slick(configurations);
	});
}

function offerHomeSlider() {
	homeSlider.each(function () {
		const thisSlider = $(this);
		const configurations = {
			mobileFirst: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			infinite: false,
			centerMode: false,
			variableWidth: false,
			autoplay: false,
			draggable: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: false,
			responsive: [
				{
					breakpoint: 3600,
					settings: "unslick",
				},

				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						row: 1,
						arrows: false,
						draggable: true,
						swipe: true,
						swipeToSlide: true,
						dots: true,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						row: 1,
						arrows: false,
						draggable: true,
						swipe: true,
						swipeToSlide: true,
						dots: true,
					},
				},
				{
					breakpoint: 720,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						row: 1,
						arrows: false,
						draggable: true,
						swipe: true,
						swipeToSlide: true,
						dots: true,
					},
				},
			],
		};

		thisSlider.on("init", () => {}).slick(configurations);
	});

	$(window).on("resize orientationchange", () => {
		homeSlider.slick("resize");
	});
}

function offerFallbackSlider() {
	fallbackSlider.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		infinite: false,
		centerMode: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					dots: true,
					speed: 300,
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					dots: true,
					speed: 300,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});
}

export { init };
