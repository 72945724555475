/**
 * Classic filter and sorting with isotope (http://isotope.metafizzy.co)
 * includes filtering by hash if given
 * sets filterbar to fixed with scrollToFixed(https://github.com/bigspotteddog/ScrollToFixed)
 *
 */

// DOM
let $grid;
let $selectedBar;
let $appendContainer;
let $body;
let $jobs;
let $filterBar;
let dataKM;

// DOM class/id-names
let filterGroup;

// Settings
let isTouchDevice;

let filterObject = {};
let singleValueVal = null;
let filterList = {};

function init() {
	// Dependencies
	// DOM
	$body = $("body");
	$grid = $(".js-item-container");

	// check if filter container exists
	if ($grid.length < 1) {
		return;
	}

	$selectedBar = $(".js-selected-item-bar");
	$appendContainer = $(".js-selected-filter-items-container");
	$jobs = $(".js-jobs-list");
	$filterBar = $(".js-cl-filter-bar__wrapper");

	//data
	dataKM = $filterBar.attr("data-km");

	// DOM class/id-names
	filterGroup = ".js-filter-group";

	// Settings
	isTouchDevice = Modernizr.touchevents;

	/* Example of the filter object
	var filterObject = {
		stars: {type: "singleValue", value: {id: 5,content: 5}},
		attributes: {type: "list", value: [{id: 'feature-culture',content:'Cultur'}, {id: 'feature-shuttle',content:'shuttle'}, {id: 'feature-nature',content:'nature'}]},
		distance: {type: "range", value: {min: 10, max: 100}}
	}
	*/

	if ($jobs.length > 0) {
		return;
		// _initJobFilter() will be evoked from careerRenderer, after Prescreen-Data is loaded
	}
	//fixed filterbar
	if (!isTouchDevice) {
		$filterBar.scrollToFixed({ marginTop: 49.5, zIndex: 120 });
	}
	_initFilter();

	if (window.location.search.length > 0) {
		_getHashFilter();
	}

	_setFilterActions();
	_resetFilterActions();
	_setSortingActions();
	_setSortItemActive();
	_displayCount();
}

// init filter for different purposes

//Default tile filter
function _initFilter() {
	$grid.isotope({
		// options
		itemSelector: ".js-filter-item",
		masonry: {
			columnWidth: 0,
		},
		getSortData: {
			name: ".js-sorting-name",
			date: "[data-date] parseInt",
		},
	});
}

// Job FIlter
function _initJobFilter() {
	// remove absolute positioning from isotope (http://codepen.io/desandro/pen/AEslp)
	Isotope.Item.prototype._create = function () {
		// assign id, used for original-order sorting
		this.id = this.layout.itemGUID++;
		// transition objects
		this._transn = {
			ingProperties: {},
			clean: {},
			onEnd: {},
		};
		this.sortData = {};
	};

	Isotope.Item.prototype.layoutPosition = function () {
		this.emitEvent("layout", [this]);
	};

	Isotope.prototype.arrange = function (opts) {
		// set any options pass
		this.option(opts);
		this._getIsInstant();
		// just filter
		this.filteredItems = this._filter(this.items);
		// flag for initalized
		this._isLayoutInited = true;
	};

	// TODO: Isotop sets height of container. Isn't needed anymore due to static positioned elements. Fix for now is to set the height via css to auto !improtant //

	Isotope.LayoutMode.create("none");

	// Bind Actions
	$grid.isotope({
		// options
		itemSelector: ".js-filter-item",
		layoutMode: "none",
		masonry: {
			columnWidth: 0,
		},
		getSortData: {
			name: ".js-sorting-name",
			date: "[data-date] parseInt",
		},
	});

	_setFilterActions();
	_resetFilterActions();
	_setSortingActions();
	_setSortItemActive();
	_displayCount();

	_setFilterClass();
}

// set filter functions
function _setFilterActions() {
	$body.on("click", ".js-star-filter", function () {
		singleValueVal = [{ id: $(this).data("stars"), content: $(this).data("stars") }];

		filterObject.stars = {
			type: "singleValue",
			value: singleValueVal,
		};

		filterAll();
	});

	$body.on("click", ".js-single-filter", function () {
		const $filterGroup = $(this).parents(filterGroup);
		const filterGroupName = $filterGroup.data("filter-group");
		singleValueVal = [{ id: $(this).data(filterGroupName), content: $(this).data("content") }]; // todo jobs content

		filterObject[filterGroupName] = {
			type: "singleValue",
			value: singleValueVal,
		};
		filterAll();
	});

	// Filter Checkbox filters
	$body.on("change", ".js-list-filter", function () {
		const $this = this;
		const listValue = { id: $this.value, content: $(this).data("content") };
		const $filterGroup = $(this).parents(filterGroup);
		const filterGroupName = $filterGroup.data("filter-group");
		const filterBehaviour = String($filterGroup.data("filter-behaviour")).toLowerCase();

		_listFilter(filterGroupName, filterBehaviour, listValue);
		filterAll();
	});
}

function _resetFilterActions() {
	// reset list filter
	$body.on("click", ".js-reset-list", function () {
		// todo: clean up !!!
		//var $filterGroup = $(this).parents('.c-cl-filter-bar__item').find(filterGroup);
		const $filterGroup = $(this).parents(".js-filter-bar-item").find("[data-filter-group]");
		const filterName = $filterGroup.data("filter-group");

		const $filterChildren = $filterGroup.find(".js-list-filter");

		$filterChildren.each(function () {
			$(this).attr("checked", false);
		});
		_resetListFilter(filterName);
		filterAll();
	});

	// Remove filter thorugh click on selected filter
	$body.on("click", ".js-remove-filter", function (e) {
		const $this = $(this);
		const _myName = $this.data("filter");
		const _myValue = $this.data("value");
		$(e.target).remove();

		if (_myName === "stars") {
			delete filterObject["stars"];
		} else if (_myName === "distances") {
			delete filterObject["distances"];
		} else {
			let index = -1;
			filterObject[_myName].value.forEach((currentValue, currentIndex) => {
				index = currentValue.id == _myValue ? currentIndex : index;
			});
			if (index > -1) {
				if (filterObject[_myName].type === "list") {
					filterObject[_myName].value.splice(index, 1);
					filterList[_myName].splice(index, 1);
					const _filterGroupObj = $(`.js-filter-group[data-filter-group="${_myName}"]`);
					const _filterName = $(`.js-list-filter[value="${_myValue}"]`);
					const _filterToUncheck = _filterGroupObj.find(_filterName);
					_filterToUncheck.prop("checked", false);
				} else {
					delete filterObject[_myName];
				}
			}
		}
		filterAll();
		_showFilterBar();
	});

	// Reset filter
	$body.on("click", ".js-clear-all-cl-filter", () => {
		$(".js-list-filter").each(function () {
			$(this).attr("checked", false);
		});
		// reset attributes list
		filterList = {};
		// reset the filter object
		filterObject = {};

		filterAll();
	});
}

function _setSortingActions() {
	// Sorting actions
	// default order
	$body.on("click", ".js-sort-default", () => {
		$grid.isotope({ sortBy: "original-order" });
	});
	// sort by name
	$body.on("click", ".js-sort-name", () => {
		$grid.isotope({ sortBy: "name" });
	});
	// sort by date
	$body.on("click", ".js-sort-date", () => {
		$grid.isotope({ sortBy: "date" });
	});
}

function _setSortItemActive() {
	const filterSortItem = $(".js-sort-item");

	filterSortItem.on("click", function () {
		filterSortItem.removeClass("is-selected");
		$(this).addClass("is-selected");
	});
}

function _displayCount() {
	const iso = $grid.data("isotope");

	let count;
	if (Array.isArray(iso.filteredItems)) {
		count = iso.filteredItems.length;
	} else {
		count = iso.filteredItems.matches.length;
	}

	$(".js-filter-bar-count").text(count);
}

// Isotope Filterung:
function filterAll() {
	$grid.isotope({ filter: tileShowCheck });
	_appendFilter();
	_showFilterBar();

	if ($jobs.length > 0) {
		_setFilterClass();
	}

	_displayCount();
}

// eslint-disable-next-line complexity
function tileShowCheck() {
	// filterObject[key]: Liste mit Filtergroups
	// filterObject[key].value : Liste mit Objekten gewählter Filter
	for (const key in filterObject) {
		// eslint-disable-next-line no-prototype-builtins
		if (filterObject.hasOwnProperty(key)) {
			const _filterType = filterObject[key].type;
			const _filterBehaviour = filterObject[key].behaviour;
			if (filterObject[key].value.length === 0) {
				// delete the key from the object after the first tile
				delete filterObject[key];
			} else if ($(this).data(key) === undefined) {
				return false;
			} else if (_filterType === "singleValue") {
				if (!(filterObject[key].value[0].id === $(this).data(key))) {
					return false;
				}
			} else if (_filterType === "range") {
				if (!(filterObject[key].value.min <= $(this).data(key) && filterObject[key].value.max >= $(this).data(key))) {
					return false;
				}
			} else if (_filterType === "list") {
				if ($(this).data(key).length == 0) {
					return false;
				}
				const _valueArray = $(this).data(key).split(","); // Filter der Kachel
				if (_filterBehaviour == "additive") {
					// ES6 some(), lodash / underscore _.intersection,
					// OR-Filter
					let hasIntersection = false;
					for (let i = 0; i < filterObject[key].value.length; i++) {
						const singleValue = filterObject[key].value[i].id;
						if ($.inArray(singleValue, _valueArray) >= 0) {
							hasIntersection = true;
							break;
						}
					}
					if (hasIntersection === false) {
						return false;
					}
				} else {
					// AND-Filter
					for (let i = 0; i < filterObject[key].value.length; i++) {
						const singleValue = filterObject[key].value[i].id;
						if ($.inArray(singleValue, _valueArray) < 0) {
							return false;
						}
					}
				}
			}
		}
	}
	return true;
}

// Different filter functions

function _listFilter(filterGroupName, filterBehaviour, listValue) {
	// check if the clicked element is already checked
	filterList[filterGroupName] = filterList[filterGroupName] || [];

	let index = -1;
	filterList[filterGroupName].forEach((currentValue, currentIndex) => {
		index = currentValue.id == listValue.id ? currentIndex : index;
	});

	if (index > -1) {
		// remove the already existing element from the filterList list
		filterList[filterGroupName].splice(index, 1);
	} else {
		// add the element to the filterList list
		filterList[filterGroupName].push(listValue);
	}

	filterObject[filterGroupName] = {
		type: "list",
		behaviour: filterBehaviour,
		value: $.merge([], filterList[filterGroupName]),
	};
}

function _resetListFilter(filterName) {
	filterList[filterName] = [];
	delete filterObject[filterName];
}

// Filterbar Functions

// Function that handles the hide and show of the selected filter bar
function _showFilterBar() {
	const $filterBar = $(".js-selected-filter-items-container");

	if ($filterBar.children().length > 0) {
		$selectedBar = $(".js-selected-item-bar");
		if ($selectedBar.hasClass("is-hidden")) {
			$selectedBar.removeClass("is-hidden");
		}
	} else {
		$selectedBar.addClass("is-hidden");
	}
}

function _appendFilter() {
	$appendContainer = $appendContainer.length ? $(".js-selected-filter-items-container") : $appendContainer;

	$appendContainer.empty();
	let selectedFilterBox = "";

	for (const key in filterObject) {
		if (key === "stars") {
			selectedFilterBox += '<span data-filter="stars" class="c-cl-filter-bar__selected-item js-remove-filter">';

			const starIcon = '<i class="c-icon c-icon--star-active is-selected"></i>';
			const max = parseInt(filterObject[key].value[0].content, 10);
			for (let j = 0; j < max; j++) {
				selectedFilterBox += starIcon;
			}
			selectedFilterBox += "</span>";
		} else {
			selectedFilterBox += _parseFilterType(filterObject, key);
		}
	}

	$appendContainer.append(selectedFilterBox);
}

function _parseFilterType(filterObject, key) {
	let selectedFilterBox = "";
	const myValue = filterObject[key].value;

	switch (filterObject[key].type) {
		case "singleValue":
			selectedFilterBox += `<span data-filter="${key}" data-value="${myValue[0].id}" class="c-cl-filter-bar__selected-item js-remove-filter">${myValue[0].content}</span>`;
			break;
		case "range":
			selectedFilterBox += `<span data-filter="${key}" class="c-cl-filter-bar__selected-item js-remove-filter">${myValue["min"]}${dataKM} - ${myValue["max"]}${dataKM}</span>`;
			break;
		case "list":
			for (let i = 0, count = myValue.length; i < count; i++) {
				const singleValue = myValue[i];
				selectedFilterBox += `<span data-filter="${key}" data-value="${singleValue.id}" class="c-cl-filter-bar__selected-item js-remove-filter">${singleValue.content}</span>`;
			}
			break;
	}

	return selectedFilterBox;
}

function _setFilterClass() {
	let elems;
	try {
		elems = $grid.isotope("getFilteredItemElements");
	} catch (err) {
		// fixme: beim Erstellen von isotope ist $grid.data('isotope').filteredItems.matches ein Object, nach dem Filtern wird es ein Array
		// getFiltereItemElements geht nur mit einem Array
		elems = $grid.data("isotope").filteredItems.matches.map((item) => item.element);
	}

	$(elems).removeClass("c-cl-list__item--odd");
	$(elems).filter(":odd").addClass("c-cl-list__item--odd");
}

// Get filter values if search string is set
// search string format: ?stars=5&distances=10+50&attributes=value+secondValue&list=value+secondValue
function _getHashFilter() {
	let _hash = window.location.search.substr(1);
	_hash = decodeURIComponent(_hash);
	// TODO: Check for & or + or other special characters in filter names
	const _hashItems = _hash.split("&");

	for (const i in _hashItems) {
		const _item = _hashItems[i];
		const _hashObject = _item.split("=");

		if (_hashObject[0] === "stars") {
			filterObject.stars = {
				type: "singleValue",
				value: [{ id: parseInt(_hashObject[1]), content: parseInt(_hashObject[1]) }],
			};
		} else if (_hashObject[0] === "distances") {
			const _rangeParams = _hashObject[1].split("+");
			filterObject.distances = {
				type: "range",
				value: { min: _rangeParams[0], max: _rangeParams[1] },
			};
		} else {
			const _params = _hashObject[1].split("+");
			const _filtergroupname = _hashObject[0];

			const parent = $(`.js-filter-group[data-filter-group='${_filtergroupname}']`);
			const behaviour = String(parent.data("filter-behaviour")).toLowerCase();

			const _filters = _params
				.map((param) => {
					const id = param;
					const child = parent.find(`.js-list-filter[value="${id}"]`);
					const content = child.data("content");

					child.attr("checked", true);
					return { id, content };
				})
				.filter((filter) => filter.content !== undefined);

			filterList[_filtergroupname] = filterList[_filtergroupname] || [];
			filterList[_filtergroupname] = _filters;

			filterObject[_filtergroupname] = {
				type: "list",
				behaviour,
				value: $.merge([], _filters),
			};
		}
	}

	filterAll();
}

function initJobFilter(_initialFilterObject) {
	_initJobFilter();
	if (_initialFilterObject !== null) {
		filterObject = _initialFilterObject;
		filterAll();
	} else {
		_setFilterClass();
	}
}

export { init, initJobFilter };
