import { onModalOpen, onModalClosed } from "@ocm/services/services.modal";
import { sendTracking } from "./infoModalsDataLayer";

const modalSelector = ".info-modal";
const triggerClass = "js-info-modal-trigger";
const closeButtonSelector = ".js-close-info-modal";
const openClass = "is-visible";
/** @type {HTMLElement | null} */
let triggerEl = null;

/**
 * @param {HTMLElement} modal
 * @param {HTMLElement} _triggerEl
 */
const showModal = async (modal, _triggerEl) => {
	modal.classList.add(openClass);
	onModalOpen(modal);

	if (_triggerEl) triggerEl = _triggerEl;
};

/**
 * @param {HTMLElement} modal
 */
const hideModal = (modal) => {
	modal.classList.remove(openClass);
	onModalClosed(triggerEl ?? undefined);
	triggerEl = null;
};

const init = () => {
	const modals = document.querySelectorAll(modalSelector);
	[...modals].forEach((modal) => {
		const triggers = [modal.previousElementSibling].filter((el) => el.classList.contains(triggerClass));
		const closeButtons = [...modal.querySelectorAll(closeButtonSelector)];
		const trackCategory = modal.dataset.trackCategory;

		// move modal out of the main container to prevent 'inert' from affecting it
		if (modal.closest("#js-main-container") !== null) document.body.appendChild(modal);

		const _showModal = (event) => {
			showModal(modal, event.currentTarget);
			// tracking
			const trackAction = event.currentTarget?.dataset.trackAction || `${trackCategory}Shown`;
			sendTracking(trackCategory, trackAction);
		};
		const _hideModal = (event) => {
			hideModal(modal);
			// tracking
			const trackAction = event.currentTarget?.dataset.trackAction || `${trackCategory}Closed`;
			sendTracking(trackCategory, trackAction);
		};

		// modal is initially open
		if (modal.classList.contains(openClass)) {
			onModalOpen(modal);
			sendTracking(trackCategory, `${trackCategory}Shown`);
		}

		triggers.forEach((el) => el.addEventListener("click", _showModal));
		closeButtons.forEach((el) => el.addEventListener("click", _hideModal));
		modal.addEventListener("click", (e) => e.target === modal && _hideModal(e));
	});
};

export { init, showModal, hideModal };
