/**
 * ocm.classicMapStyles.js
 *
 * Custom google maps styling. Returns the array
 *
 */

//---------------------------
// Init
//---------------------------
export default [
	{
		featureType: "all",
		elementType: "geometry",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "all",
		elementType: "geometry.fill",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "all",
		elementType: "geometry.stroke",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: 36,
			},
			{
				color: "#333333",
			},
			{
				lightness: 40,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#ffffff",
			},
			{
				lightness: 16,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#fefefe",
			},
			{
				lightness: 20,
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#fefefe",
			},
			{
				lightness: 17,
			},
			{
				weight: 1.2,
			},
		],
	},
	{
		featureType: "administrative.country",
		elementType: "geometry",
		stylers: [
			{
				visibility: "off",
			},
			{
				saturation: "0",
			},
		],
	},
	{
		featureType: "administrative.province",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "0",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
			{
				lightness: 20,
			},
		],
	},
	{
		featureType: "landscape.natural",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "50",
			},
		],
	},
	{
		featureType: "landscape.natural.landcover",
		elementType: "labels.text.fill",
		stylers: [
			{
				lightness: "0",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
			{
				lightness: 21,
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#dedede",
			},
			{
				lightness: 21,
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "0",
			},
			{
				saturation: "-100",
			},
			{
				color: "#c9c9c9",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 29,
			},
			{
				weight: 0.2,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 18,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "-27",
			},
			{
				color: "#e2e2e2",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 16,
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "geometry.fill",
		stylers: [
			{
				saturation: "-31",
			},
			{
				lightness: "0",
			},
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#f2f2f2",
			},
			{
				lightness: 19,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				lightness: 17,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry.fill",
		stylers: [
			{
				saturation: "-100",
			},
			{
				lightness: "-24",
			},
			{
				color: "#efefef",
			},
		],
	},
];
