/**
 * Form-Validierung
 *
 * validate forms / single form-elements
 * using HTML5 Attributes
 */

import * as errorMessages from "@sharedJS/errorMessages";

let errorClass;
const defaultErrorClass = "u-error-text c-form-error";

//eslint-disable-next-line
function validateFormOnSubmit(event) {
	event.preventDefault();

	let isValid = true;
	const target = event.target;
	const data = event.data || {};
	const callback = data.callback;
	const elements = event.target.elements;
	const length = elements.length;

	errorClass = data.errorClass ? data.errorClass : defaultErrorClass;

	for (let i = 0; i < length; i++) {
		if (_validateElement(elements[i], event.data.errorCallback) === false) {
			isValid = false;
		}
	}

	if (typeof callback === "function") {
		callback(target, isValid);
	} else {
		if (isValid === true) {
			target.submit();
		}
	}
}

function validateForm(form, options) {
	let isValid = true;
	const target = form;
	const data = options || {};

	const elements = target.elements;
	const length = elements.length;

	errorClass = data.errorClass ? data.errorClass : defaultErrorClass;

	for (let i = 0; i < length; i++) {
		if (_validateElement(elements[i]) === false) {
			isValid = false;
		}
	}

	return isValid;
}

function validateElementOnChange(event) {
	const element = event.target;
	const data = event.data || {};
	const value = $(element).val().trim();
	$(element).val(value);

	errorClass = data.errorClass ? data.errorClass : defaultErrorClass;

	_validateElement(element, event.data.errorCallback);
}

//eslint-disable-next-line
function validateElement(element, myErrorClass, errorCallback) {
	let elementID;
	if ($(element).is(":radio")) {
		elementID = $(element).prop("name");
	} else {
		elementID = $(element).prop("id");
	}
	const $errorDiv = $(`.error-${elementID}`);
	let errorMessage = "";

	errorClass = myErrorClass ? myErrorClass : defaultErrorClass;
	const validateObj = _validateHTML5Attributes(element);
	const isValid = validateObj.isValid;
	const error = validateObj.error;
	errorMessage = errorMessages.getErrorMessage(elementID, error);

	if (validateObj.hasRestrictions) {
		_toggleError($(element), $errorDiv, isValid, errorMessage);
	}

	if (typeof errorCallback === "function") {
		if (!isValid) {
			errorCallback(errorMessage, elementID);
		}
	}
	return isValid;
}

function removeErrorNoticeOnKeyUp($form, options) {
	const data = options || {};
	errorClass = data.errorClass ? data.errorClass : defaultErrorClass;

	$form.on("focus", "input", (event) => {
		const element = event.currentTarget;
		$(element).data("value", element.value);
	});

	$form.on("keyup", "input.u-error", (event) => {
		const element = event.currentTarget;

		if (event.which !== 13 && element.value !== $(element).data("value")) {
			element.classList.remove("is-not-valid", "u-error");
			$(element)
				.closest(".c-form-row")
				.find(`.${errorClass}`)
				.add($(element).closest(".js-form-row").find(`.${errorClass}`))
				.text("")
				.removeClass(errorClass);
		}
	});
}

function setGlobalError($form, showError, errorMessage) {
	if (showError) {
		const globalError = _getGlobalErrorHtml(errorMessage);
		$form.prepend(globalError);
	} else {
		$form.find(".u-error-text.error-otherError").remove();
	}
}

function setError(elementName, showError, errorMessage, myErrorClass) {
	const $element = $(`#${elementName}`);
	const $errorDiv = $(`.error-${elementName}`);
	const isValid = !showError;

	errorClass = myErrorClass ? myErrorClass : defaultErrorClass;
	_toggleError($element, $errorDiv, isValid, errorMessage);
}

function _validateElement(element, errorCallback) {
	return validateElement(element, errorClass, errorCallback);
}

function _toggleError($element, $errorDiv, isValid, errorMessage) {
	let $toggleElement = $element;
	if ($element.attr("type") === "radio") {
		const name = $element.attr("name");
		$toggleElement = $element.parents("form").find(`[name=${name}]`);
	}
	if (isValid === false) {
		console.log("Field %o is not valid!\nAdding message: %s\nClass: %s", $element.get(0), errorMessage, errorClass);
		$toggleElement.addClass("u-error is-not-valid");
		$errorDiv.text(errorMessage);
		$errorDiv.addClass(errorClass);
		if ($errorDiv.text() !== "") {
			$errorDiv.css("display", "block");
		} else {
			$errorDiv.css("display", "none");
		}
	} else {
		$toggleElement.removeClass("u-error is-not-valid");
		$errorDiv.removeClass(errorClass);
		$errorDiv.text("");
	}
}

function _getGlobalErrorHtml(message, tagName, errorClass) {
	tagName = tagName !== undefined ? tagName : "div";
	errorClass = errorClass !== undefined ? errorClass : "u-error-text";
	return `<${tagName} class="${errorClass} error-otherError">${message}</${tagName}>`;
}

//eslint-disable-next-line
function _validateHTML5Attributes(element) {
	const $element = $(element);
	const tag = $element.prop("nodeName");
	const type = $element.attr("type");
	const id = $element.attr("id");
	let value = $element.val();
	// Trim Text before Validation
	if (typeof value === "string") {
		value = value.trim();
		$element.val(value);
	}
	let hasRestrictions = false;
	const attributes = [];
	attributes.required = $element.attr("required");
	attributes.pattern = $element.attr("pattern");
	attributes.maxlength = $element.attr("maxlength");
	attributes.min = $element.attr("min");
	attributes.max = $element.attr("max");
	attributes.dataValidate = $element.attr("data-validate");

	// check required
	if (attributes.required) {
		hasRestrictions = true;
		if (tag.toLowerCase() === "input") {
			if (type === "checkbox" && !$(element).is(":checked")) {
				return { isValid: false, error: "required", hasRestrictions };
			} else if (id === "checkPwd" && (value.length < 1 || value !== $("#pwd").val())) {
				return { isValid: false, error: "required", hasRestrictions };
			} else if (id === "checkNewPassword" && (value.length < 1 || value !== $("#newPassword").val())) {
				return { isValid: false, error: "required", hasRestrictions };
			} else if (id === "chkEmail" && (value.length < 1 || value !== $("#newEmail").val())) {
				return { isValid: false, error: "required", hasRestrictions };
			} else if (type === "radio" && !element.checkValidity()) {
				return { isValid: false, error: "required", hasRestrictions };
			} else if (value.length < 1) {
				return { isValid: false, error: "required", hasRestrictions };
			}
		} else if (tag.toLowerCase() === "select") {
			if (value.length < 1) {
				return { isValid: false, error: "required", hasRestrictions };
			}
		} else if (tag.toLowerCase() === "textarea") {
			if (value.length < 1) {
				return { isValid: false, error: "required", hasRestrictions };
			}
		}
	}

	// check patterns
	if (attributes.pattern) {
		hasRestrictions = true;
		const regPat = new RegExp(attributes.pattern);
		const testPat = regPat.test(value);
		return { isValid: testPat, error: "pattern", hasRestrictions };
	}

	if (attributes.dataValidate === "creditCard") {
		hasRestrictions = true;
		let isValidCreditCard = false;
		let isValidCreditCardLength = false;
		let isValidLuhn = false;

		$element.validateCreditCard((result) => {
			isValidCreditCard = result.valid;
			isValidCreditCardLength = result.length_valid;
			isValidLuhn = result.luhn_valid;
		});

		if (!(isValidCreditCard || isValidCreditCardLength || isValidLuhn)) {
			return { isValid: false, error: "invalid", hasRestrictions };
		}
	}
	return { isValid: true, error: null, hasRestrictions };
}

export {
	validateFormOnSubmit,
	validateForm,
	validateElementOnChange,
	validateElement,
	removeErrorNoticeOnKeyUp,
	setGlobalError,
	setError,
};
