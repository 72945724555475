/**
 * SEO-Image
 -> Destroy Custom Scrollbar on mobile, re-initialize on desktop
 -> Togggle text-container height on mobile
 */

//----------------------------
// Variables
//----------------------------

// DOM
let $seoContainer;
let $readMore;

// DOM Selectors

// Dependencies

// Settings
let moreText;
let lessText;

//------------------------
// init
//------------------------

function init() {
	// DOM
	$seoContainer = $(".js-seo-text-container");

	if ($seoContainer.length < 1) {
		return;
	}

	$readMore = $(".js-seo-text-read-more");

	//DOM Selector

	//Settings
	moreText = $("#js-textReadMore").attr("data-moreText");
	lessText = $("#js-textReadMore").attr("data-lessText");

	$readMore.on("click", function () {
		if ($(this).parent().find($seoContainer).hasClass("is-active")) {
			$(this).parent().find($seoContainer).removeClass("is-active");
			$(this).removeClass("is-active");
			$(this).html(moreText);
		} else {
			$(this).parent().find($seoContainer).addClass("is-active");
			$(this).addClass("is-active");
			$(this).html(lessText);
		}
	});
}

export { init };
