// DOM
let $sortMobile;
let $sortItems;
let $sortClose;

// mobile Taps

// Settings

function init() {
	$sortMobile = $(".js-sort-mobile");
	$sortItems = $(".js-sort-mobile-items");
	$sortClose = $(".js-sort-mobile-close");

	$sortMobile.on("click", () => {
		if ($sortItems.hasClass("is-open")) {
			$sortItems.removeClass("is-open");
			$sortMobile.removeClass("is-open");
		} else {
			$sortItems.addClass("is-open");
			$sortMobile.addClass("is-open");
		}

		if ($(".js-filter-mobile").hasClass("is-open")) {
			$(".js-filter-mobile").removeClass("is-open");
			$(".js-filter-mobile-items").removeClass("is-open");
		}
	});

	$sortClose.on("click", () => {
		$sortItems.removeClass("is-open");
		$sortMobile.removeClass("is-open");
	});
}

export { init };
