/**
 * Tile description shortener for multi language
 */

//DOM
let $descriptionContainer;

//DOM Selectors
let len;
let limit;

//Dependencies

function init() {
	//DOM
	$descriptionContainer = $(".js-tile-description");

	if (!$descriptionContainer.length) {
		return;
	}

	limit = 150;

	$descriptionContainer.each(function () {
		len = $(this).text().length;
		if (len >= limit) {
			$(this).text(`${$(this).text().substr(0, limit)}…`);
		}
	});
}

export { init };
