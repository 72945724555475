// Hash => js-filter-item id (without "-section")
// e.g.:
// #psh2jhbykzdt7r8ladyawlai4ndc41i

let $element;
let $elementHash;

function _executeScroll(headerHeight) {
	if (window.location.hash) {
		$elementHash = window.location.hash;
		$element = $(`${$elementHash}-section`);
		const $child = $element.children(".js-accordion-headline");

		if ($child.length) {
			$("html, body").animate(
				{
					scrollTop: $child.offset().top - headerHeight,
				},
				500,
				() => {
					if ($child.hasClass("js-accordion-headline")) {
						$(".js-accordion-body").hide();
						$child.next(".js-accordion-body").show();
						$(".js-accordion-headline").removeClass("is-active");
						$child.addClass("is-active");
					}
				}
			);
		}
	}
}

function init() {
	if ($("#classicJobsContainer").length) {
		window.setTimeout(() => {
			_executeScroll(60);
		}, 500);
	}

	$(window).on("hashchange", () => {
		if ($("#classicJobsContainer").length) {
			_executeScroll(60);
		}
	});
}

export { init };
