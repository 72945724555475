import * as formValidate from "@sharedJS/formValidate";
import * as captcha from "@sharedJS/formCaptcha";
import * as rest from "@sharedJS/rest";

// DOM
let $passwordForgottenForm;

// ID-Element-Names
const passwordForgottenForm = "#forgottenPasswordPage";
const captchaElementID = "#captchaForgottenPasswordPage";

// Settings
let captchaElement;
const errorClass = "u-error-text";

function init() {
	// DOM
	$passwordForgottenForm = $(passwordForgottenForm);
	if ($passwordForgottenForm.length < 1) {
		return;
	}

	// Bind Actions
	registerPasswordForgottenFormEvents();

	// setCaptcha
	captcha.setCaptchaId(captchaElementID, captchaCallback);
	captchaElement = captcha.getCaptchaElement(captchaElementID);
}

//---------------------------
// Special Captcha
//---------------------------
function captchaCallback() {
	startForgottenPasswordAction();
}

function registerPasswordForgottenFormEvents() {
	//--------------------------
	// Form Error Handling
	$passwordForgottenForm.attr("novalidate", "novalidate");

	//--------------------------
	// change input
	$passwordForgottenForm.on("change", "input, select", (event) => {
		formValidate.validateElement(event.currentTarget, errorClass);
	});
	formValidate.removeErrorNoticeOnKeyUp($passwordForgottenForm, { errorClass });

	//--------------------------
	// submit Form
	$passwordForgottenForm.on("submit", function (event) {
		event.preventDefault();

		// validate form and start login process
		const isValid = formValidate.validateForm(this, { errorClass });

		// Formvalidation
		if (!isValid) {
			return;
		}

		// Captcha
		if (captchaElement) {
			captcha.execute(captchaElementID, "forgottenPassword");
		} else {
			startForgottenPasswordAction();
		}
	});
}

function startForgottenPasswordAction() {
	const context = {
		form: $passwordForgottenForm,
		callback: startForgottenPasswordAction,
	};
	rest.submitForm(context);
}

export { init };
