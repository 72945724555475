/* eslint-disable eqeqeq */

import * as rest from "@sharedJS/rest";
import * as formValidate from "@sharedJS/formValidate";
import commonPopup from "@JS/shop/10-login-component/commonPopup";
import * as ocm_utils from "@sharedJS/utils";
import * as captcha from "@sharedJS/formCaptcha";

let $layerHeader;
let $authForm;
let $authChangeUser;

// DOM-Element-Names
const captchaElementID = "captchaReauth";

let initialized = false;

function init() {
	if (initialized) {
		return;
	}
	initialized = true;

	$layerHeader = $("#AuthenticationHeader");
	$authForm = $("#authentication-form");
	$authChangeUser = $(".js-auth-change-user");

	replaceMailInLayerHeader();
	registerEvents();

	captcha.setCaptchaId(captchaElementID, _startLoginAction);
}

// sonst werden die Aktionen hier gebunden ???? Warum ??? Quickview ????
// todo: precheckSessionState(): diese Funktion gib einen Boolean zurück und bewirkt einen Seitenreload, wenn keine Session vorhanden ist, beim Kontaktformular macht dieses vorgehen Probleme, da das Formular nicht abgeschickt wird, wie ist es bei anderen Formularen / Stellen im Code?

function doReauth(minAuthLevel) {
	precheckSessionState();

	if (!initialized) {
		init();
	}
	if ($authForm.length > 0 && $("#master-common-layer").attr("data-isReauth") != "true") {
		const currentAuthLevel = _getAuthLevel();
		return Number.isInteger(minAuthLevel) && currentAuthLevel < minAuthLevel;
	}
	return false;
}

function openReauthLayer(postAction, postUrl) {
	if (!initialized) {
		init();
	}
	if ($authForm.length > 0) {
		commonPopup.setPopupPostActions(postAction, postUrl);
		commonPopup.showLayer("Authentication", true);

		return true;
	}

	return false;
}

function doPageReloadForCoopUser() {
	const currentAuthLevel = _getAuthLevel();
	const coopLevel = 200;
	if (currentAuthLevel === coopLevel) {
		// TODO TUB-7822: rewrite to use the new reauth/login handling without 'precheck' ...
		window.location = ocm_utils.getUrl("/");
		return true;
	}
	return false;
}

function replaceMailInLayerHeader() {
	if ($layerHeader.length > 0) {
		const mail = $authForm.find("#j_username").val();
		const replacedHtml = $layerHeader.html().replace("${mailaddress}", mail);
		$layerHeader.html(replacedHtml);
	}
}

function registerEvents() {
	$authForm.on("change", "input, select", (event) => {
		formValidate.validateElement(event.currentTarget);
	});

	$authForm.on("submit", function (event) {
		event.preventDefault();

		const isValid = formValidate.validateForm(this);
		if (!isValid) {
			return;
		}

		const hasCaptcha = captcha.execute(captchaElementID, "Reauthentication");
		if (!hasCaptcha) {
			_startLoginAction();
		}
	});

	$authChangeUser.on("click", changeUserAction);

	registerLinksForReauthCheck($("#miniCartReauthLink"), 500);
}

function registerLinksForReauthCheck(component, minAuthLevel) {
	component.on("click", (evt) => {
		evt.preventDefault();

		const targetPath = component.attr("href");
		if (doReauth(minAuthLevel)) {
			openReauthLayer("", targetPath);
		} else {
			window.location = ocm_utils.getUrl(targetPath);
		}
	});
}

const _startLoginAction = () => {
	const context = {
		form: $authForm,
		callback: _startLoginAction,
		ignoreRedirect: true,
	};
	rest.submitForm(context).done((data) => {
		$("#master-common-layer").attr("data-isReauth", "true");
		commonPopup.closeLayer();
		commonPopup.doStartPopupPostAction(data);
	});
};

function changeUserAction(event) {
	if ($authForm.find("button[type=submit]").prop("disabled")) {
		return;
	}

	precheckSessionState();
	event.preventDefault();
	const targetUrl = event.target.href;

	$.ajax({
		url: ocm_utils.getUrl("/logout/"),
		global: false,
		type: "GET",
		dataType: "html",
		async: false,
	})
		.done(() => {
			// Rückgabewert ist eine ganze Logoutseite
			window.location.assign(targetUrl);
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			console.error(errorThrown);
		});
}

function precheckSessionState() {
	let currentsessionvalid = false;

	$.ajax({
		url: ocm_utils.getUrl("/_common/currentsessionvalid/"),
		global: false,
		type: "GET",
		dataType: "html",
		async: false, // Important!!!
	})
		.done((responseHtml) => {
			if (responseHtml == "false") {
				window.location.reload(true);
			} else {
				currentsessionvalid = true;
			}
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			console.log("responseHTML fail");
			alert(
				`Failed to check session state. Error details [${JSON.parse(
					jqXHR.responseText
				)}, ${textStatus}, ${errorThrown}]`
			);
		});

	return currentsessionvalid;
}

//-------------------------------
// get server site authentication level
function _getAuthLevel() {
	let authLevel = 0;
	$.ajax({
		url: ocm_utils.getUrl("/login/authLevel/"),
		global: false,
		type: "GET",
		dataType: "html",
		async: false, // Important !!!
	}).success((data) => {
		authLevel = +(data ?? 0);
	});
	return authLevel;
}

//--------------------------
// public methods
//--------------------------

function openActions() {
	formValidate.setGlobalError($authForm, false, "");
	formValidate.setError("j_password", false, "");

	$("#j_password").val("");
}

//--------------------------
// Return Public
//--------------------------

export { init, openActions, doReauth, openReauthLayer, precheckSessionState, doPageReloadForCoopUser };
