import * as utils from "@sharedJS/utils";

const fillMyAccountOffCanvasHeadline = function () {
	$.ajax({
		type: "GET",
		dataType: "html",
		url: utils.getUrl("/canvas/myAccountHeadline/"),
		cache: false,
	}).done((data) => {
		$(".js-my-account-canvas-header").html(data);
	});
};

export { fillMyAccountOffCanvasHeadline };
