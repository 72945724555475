/**
 * If consent for serviceName:String is given fire callback:Function
 * e.g: onConsentGiven("epoq", setEpoqCartData);
 * Usercentrics: window.UC_UI and Event "UC_UI_INITIALIZED"
 */

function _checkService(serviceName, callback) {
	const isServiceAllowed =
		window.UC_UI.getServicesBaseInfo?.().find((service) => service.name === serviceName)?.consent?.status ?? false;
	if (isServiceAllowed) {
		callback();
	}
}

export function onConsentGiven(serviceName, callback) {
	if (window.UC_UI?.isInitialized?.() ?? false) {
		_checkService(serviceName, callback);
	} else {
		window.addEventListener(
			"UC_UI_INITIALIZED",
			function () {
				_checkService(serviceName, callback);
			},
			{ once: true }
		);
	}
}
