/**
 * ocm.classicMapZoom.js
 *
 * Handles the different functions on zoom. Shows the marker for the new zoom level on zoom change.
 *
 * Calls the closing infobox function on a zoom change.
 *
 */
import * as classicMapInfobox from "@classicJS/classicMapInfobox";

function init() {}

function showMarkerOnZoom(map, marker) {
	var currentZoom = map.getZoom();

	$.each(marker, function () {
		var currentMarker = this;

		if ($.inArray(currentZoom, this.ocmItem.priority) > -1) {
			currentMarker.setVisible(true);
		} else {
			currentMarker.setVisible(false);
		}

		if (currentZoom > 19) {
			currentMarker.setVisible(true);
		}
	});
}

function onZoomChange(map, marker, _infobox) {
	map.addListener("zoom_changed", function () {
		showMarkerOnZoom(map, marker);
		if (_infobox.map) {
			classicMapInfobox.closeInfobox(_infobox);
		}
	});
}

export { init, showMarkerOnZoom, onZoomChange };
