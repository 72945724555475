import * as classicFilter from "@classicJS/classicFilter";

function init() {
	const filterObject = _checkJobFilterParams();
	classicFilter.initJobFilter(filterObject);
}

// eslint-disable-next-line complexity
function _checkJobFilterParams() {
	const params = new URL(document.location).searchParams;
	const brandFilter = params.get("brandFilter");
	const teamFilter = params.get("teamFilter");
	const positionFilter = params.get("positionFilter");

	if (!(brandFilter || teamFilter || positionFilter)) {
		return null;
	}

	// encodeURIComponent(str)
	// Tätigkeitsbereich / Teamfilter zu finden in data-taetigkeitsbereich
	// teamFilter=
	// Anstellungsart / Positionfilter zu finden in data-anstellungsart
	// positionFilter=
	// Marken / brandfilter zu finden in data-brand
	// brandFilter=
	// z.B.
	// brandFilter=Fabiana%20Filippi
	// teamFilter=Teilzeit%20%2F%20Vollzeit
	// positionFilter=Lagerist%20%28w%2Fm%2Fd%29
	// gesamt
	// ?brandFilter=Fabiana%20Filippi&teamFilter=Teilzeit%20%2F%20Vollzeit&positionFilter=Lagerist%20%28w%2Fm%2Fd%29

	const filterObject = {};
	if (brandFilter) {
		filterObject.brand = {
			type: "singleValue",
			value: [
				{
					id: decodeURIComponent(brandFilter),
					content: decodeURIComponent(brandFilter),
				},
			],
		};
	}

	if (positionFilter) {
		filterObject.taetigkeitsbereich = {
			type: "singleValue",
			value: [
				{
					id: decodeURIComponent(positionFilter),
					content: decodeURIComponent(positionFilter),
				},
			],
		};
	}

	if (teamFilter) {
		filterObject.anstellungsart = {
			type: "singleValue",
			value: [
				{
					id: decodeURIComponent(teamFilter),
					content: decodeURIComponent(teamFilter),
				},
			],
		};
	}

	return filterObject;
}

export { init };
