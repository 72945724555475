/**
 * initFixedBar
 *
 * sets the given bar to fixed by scrolling down
 */

/*
 *  elementsHeight: the total height from where the bar gets fixed (e.g. $header.height() + $headerNav.height())
 *  elementArray: array of dom elements which gets the class is-fixed
 *  $fixedElement: fixed element for the if statement
 *
 * */
const setFixedBar = (elementsHeight, elementArray, $fixedElement) => {
	const $window = $(window);
	const switchOffset = elementsHeight;
	const scrollTop = $window.scrollTop();
	const $elements = $([...elementArray].flatMap((item) => item?.toArray() || item));

	// on breakpoints above 1024px the header nav bar becomes the sticky element,
	// so the JS class toggle shall be disabled on viewports 1024+ when there's no nav bar
	// (typically the case on landing pages)
	const hasStickyHeader = () => !!($(".js-header-nav").length || !matchMedia("(min-width: 64em)").matches);

	// initial
	if (hasStickyHeader() && scrollTop > switchOffset && !$($fixedElement).hasClass("is-fixed")) {
		document.documentElement.classList.add("is-header-sticky");
		$elements.addClass("is-fixed");
	}

	$window.on("scroll", () => {
		if (!hasStickyHeader()) return;
		const scrollTop = $window.scrollTop();
		if (scrollTop >= switchOffset && !$($fixedElement).hasClass("is-fixed")) {
			$elements.addClass("is-fixed");
		} else if (scrollTop < switchOffset && $($fixedElement).hasClass("is-fixed")) {
			$elements.removeClass("is-fixed");

			// force repaint to fix Safari bugs
			$elements.css("transform", "translateZ(0)");
			requestAnimationFrame(() => {
				$elements.css("transform", "");
			});
		}
	});
};

export { setFixedBar };
// todo: newheader
