/**
 * Created by Martin Hafranke on 18.03.2016.
 */

function closeAllTooltips() {
	$.powerTip.hide();
}

function init() {
	let $powerTipContainer;
	const $tooltipbutton = $(".js-has-tooltip");
	const tooltipContentClass = ".js-tooltip-content";
	const close =
		'<div class="[ c-tooltip__close js-tooltip-close ]"><span class="[ c-icon c-icon--close c-icon--small ]"></span></div>';

	//mousefollow
	const mousefollow = $(".js-tooltip-mousefollow");

	if (!(Modernizr.touchevents || Modernizr.mq("(max-width: 1025px)"))) {
		//init mousefollow Tooltip
		mousefollowTooltipShow(mousefollow);
	}

	const clickevent = Modernizr.touchevents ? "touchend" : "click";

	$.powerTip.destroy();
	$(document).off(clickevent, ".js-tooltip-close", closeAllTooltips);
	$(window).off("scroll", closeAllTooltips);

	// close functions
	$(document).on(clickevent, ".js-tooltip-close", closeAllTooltips);
	$(window).on("scroll", closeAllTooltips);

	$tooltipbutton.each(function () {
		// content may be within or directly after .js-has-tooltip
		const container = $(this).find(tooltipContentClass).length
			? $(this).find(tooltipContentClass)
			: $(this).next(tooltipContentClass);
		const contentHTML = container.html();

		if (contentHTML) {
			const content = contentHTML + close;
			const this_tooltip = $(this);
			this_tooltip.data("powertip", content);
			const position = container.data("position");
			const isMobileFullsize = container.data("mobile") === "fullsize";

			// initialize Tooltip
			this_tooltip.powerTip({
				manual: true,
				smartPlacement: true,
				placement: position,
			});

			// Tooltip open / close
			if (!Modernizr.touchevents) {
				this_tooltip.on({
					mouseenter: (event) => {
						$.powerTip.show(this, event);
					},
					mouseleave: (event) => {
						$.powerTip.hide(this, event);
					},
				});
			} else {
				this_tooltip.on({
					touchstart() {
						this_tooltip.powerTip("show");
						if (isMobileFullsize) {
							setTimeout(() => {
								$powerTipContainer = $("#powerTip");
								$powerTipContainer.addClass("mobile-fullsize");
							}, 50);
						}
					},
					mouseleave() {
						if (!isMobileFullsize) {
							this_tooltip.powerTip("hide");
						}
					},
				});
			}
		}
	});
}

// mousefollow tooltip
function mousefollowTooltipShow(mousefollow) {
	mousefollow.each(function () {
		const $this = $(this);
		$this.powerTip({
			followMouse: true,
		});
	});
}

export { init };
