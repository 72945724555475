import * as restErrorHandler from "@sharedJS/restErrorHandler";

/**
 * Submits REST form
 *
 * Context can have:
 * - $form jquery selector of the form that will be submitted
 * - callback function   : The function which is calling this method
 * - saveRedirectToHistory boolean    : Should javascript saveRedirectToHistory or just assign a new url
 * - callbackData object : Pass this data to the callback function
 * - ignoreRedirect The document.ajaxSuccess() will ignore the redirect url sent by the backend.
 */
const submitForm = (context) => {
	context.rest = true;
	return $.ajax({
		type: "POST",
		url: context.form.attr("action"),
		data: context.form.serialize(),
		context,
	});
};

const submitJSON = (context) => {
	context.rest = true;
	return $.ajax({
		type: "POST",
		url: context.formUrl,
		data: context.formData,
		context,
	});
};

$(document).ajaxSend((event, jqXHR, settings) => {
	const context = settings.context;
	if (_isRest(context)) {
		const getUrl = $(context.form).attr("data-getUrl");
		if (typeof getUrl !== "undefined") {
			jqXHR.setRequestHeader("getUrl", getUrl);
		}

		$(context.form).find("input[type=button], button").prop("disabled", true).addClass("is-disabled");
	}
});
$(document).ajaxError((event, response, settings) => {
	const context = settings.context;
	if (_isRest(context)) {
		if (context.form) {
			context.form.find("input[type=button], button").prop("disabled", false).removeClass("is-disabled");
			restErrorHandler.handleError(
				response,
				context.form.get(0).id,
				context.callback,
				context.callbackData,
				context.csrfErrorCallback,
				context.ignoreRedirect,
				context.fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
			);
		} else {
			restErrorHandler.handleGetError(
				response,
				context.callback,
				context.callbackData,
				context.ignoreRedirect,
				context.fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
			);
		}
	}
});

$(document).ajaxSuccess((event, response, settings) => {
	const context = settings.context;
	if (_setLoginTabActive(context)) {
		localStorage.setItem("loginTabActive", true);
	}
	if (_isRest(context)) {
		if (shouldNotRedirect(response, context)) {
			$(context.form).find("input[type=button], button").prop("disabled", false).removeClass("is-disabled");
			return;
		}

		handleRedirect(response, context);
	}
});

const _isRest = (context) => context && context.rest;

const _setLoginTabActive = (context) => context && context.setLoginTabActive;

const handleRedirect = (response, context) => {
	if (context.saveRedirectToHistory) {
		window.location.assign(response.responseJSON.redirectUrl);
	} else {
		window.location.replace(response.responseJSON.redirectUrl);
	}
};

const shouldNotRedirect = (response, context) =>
	!response.responseJSON || !response.responseJSON.redirectUrl || context.ignoreRedirect;

export { submitForm, submitJSON, handleRedirect };
