const setSticky = () => {
	const headerElement = document.querySelector("header.c-header");
	if (!headerElement) return;

	const headerTop = Math.abs(parseFloat(getComputedStyle(headerElement).top));

	const staticHeaderPages = ["is-landingpage", "is-checkout", "template-pages-SignInPageTemplate"];
	// landing- and checkout-pages use a distinct header and set identifying classes on the root element
	// the shop sign-in page uses the normal header, so the CMS template class on the body is used to
	// distiguish the login page from other shop pages
	// isSticky logic should coincide with /global/src/css/shared/components/header/_header.scss
	const isStaticOnDesktop = !!staticHeaderPages.filter((className) => document.querySelector(`.${className}`)).length;
	const isMobile = !matchMedia("(min-width: 64em)").matches;
	const isSticky = (isMobile || !isStaticOnDesktop) && (window.scrollY || 0) >= headerTop;

	if (isSticky) {
		document.documentElement.classList.add("is-header-sticky");
		headerElement.classList.add("is-fixed");
	} else {
		document.documentElement.classList.remove("is-header-sticky");
		headerElement.classList.remove("is-fixed");
	}
};

const setHelperClasses = () => {
	setSticky();
	["scroll", "resize"].forEach((event) => addEventListener(event, setSticky));
};

export default { setHelperClasses };
