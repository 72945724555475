let $scrollContent;
let scrollbarAccordionDescription;

function init() {
	$scrollContent = $(".scrollbar-inner");

	const disableBodyScroll = !Modernizr.touchevents;

	$scrollContent.scrollbar({
		disableBodyScroll,
	});
}

function destroyScrollBar($scrollContent) {
	$scrollContent.scrollbar("destroy");
}

function scrollbarAccordionInit() {
	scrollbarAccordion();

	$(window).resize(() => {
		scrollbarAccordion();
	});
}

function scrollbarAccordion() {
	scrollbarAccordionDescription = $(".c-product-description");
	if ($(window).width() < 720) {
		scrollbarAccordionDescription.removeClass("scrollbar-inner");
	} else {
		if (!scrollbarAccordionDescription.hasClass("scrollbar-inner")) {
			scrollbarAccordionDescription.addClass("scrollbar-inner");
		}
	}
}

export { init, destroyScrollBar, scrollbarAccordionInit };
