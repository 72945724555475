/**
 * Classic Image Slider
 */

let imageGallery;

function init() {
	imageGallery = $(".js-image-gallery");

	if (imageGallery.length === 0) {
		return;
	}

	imageSlider();
}

function imageSlider() {
	imageGallery.each(function () {
		$(this).slick({
			infinite: true,
			arrows: false,
			slidesToShow: 8,
			slidesToScroll: 8,
			// autoplay: true,
			// autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 1921,
					settings: {
						arrows: false,
						slidesToShow: 8,
						slidesToScroll: 8,
					},
				},
				{
					breakpoint: 1280,
					settings: {
						arrows: false,
						slidesToShow: 6,
						slidesToScroll: 6,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						arrows: false,
						slidesToShow: 4,
						slidesToScroll: 4,
					},
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 480,
					settings: {
						arrows: false,
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
			],
		});
	});
}

export { init };
