let $clearBtn;
let $searchInput;

function init() {
	$clearBtn = $(".js-clear-btn");

	if ($clearBtn.length) {
		clickClearBtn();

		$clearBtn.prev().on("keyup", function () {
			const $this = $(this);
			showClearIcon($this);
		});
	}

	$searchInput = $(".js-select-on-focus");

	if ($searchInput.length) {
		selectTextOnFocus();
	}
}

const clickClearBtn = function () {
	$clearBtn.each(function () {
		const $this = $(this);

		if (!$this.hasClass("is-hidden")) {
			$this.addClass("is-hidden");
		}

		$this.on("click", () => {
			$this.closest(".js-search").find("input").val("");
			$this.addClass("is-hidden");
		});
	});
};

const showClearIcon = function ($element) {
	if ($element.val()) {
		$element.parent().find(".js-clear-btn").removeClass("is-hidden");
	} else {
		$element.parent().find(".js-clear-btn").addClass("is-hidden");
	}
};

const selectTextOnFocus = function () {
	$searchInput.on("focus", (e) => {
		e.target.setSelectionRange(0, e.target.value.length);
	});
};

export { init };
