// DOM
const faqItems = document.querySelectorAll(".js-accordion-button");
const showMoreFAQ = document.querySelectorAll(".js-show-more-faq");
const headerFaqItems = document.querySelectorAll(".js-toggle-text");
const allContent = document.querySelectorAll(".faq__answer");

function init() {
	faqItems.forEach((faqItem) => faqItem.addEventListener("click", toggleAccordion, false));
	showMoreFAQ.forEach((showMoreBtn) => {
		showMoreBtn.addEventListener("click", toggleMoreFaq);
	});
	headerFaqItems.forEach((headerFaqItem) => {
		headerFaqItem.addEventListener("click", checkSelectedItem);
	});
}

function toggleAccordion() {
	const itemToggle = this.getAttribute("aria-expanded");

	faqItems.forEach((item) => {
		item.setAttribute("aria-expanded", "false");
	});

	if (itemToggle === "false") {
		this.setAttribute("aria-expanded", "true");
	}
}

function toggleMoreFaq(event) {
	const moreFaqBtn = event.currentTarget;
	const accordionItems = moreFaqBtn.closest(".js-faqs-item").querySelectorAll(".accordion__item");
	const hidden = accordionItems[accordionItems.length - 1].classList.contains("hidden");

	if (hidden) {
		moreFaqBtn.querySelector(".js-accordion__title").textContent = moreFaqBtn.getAttribute("data-text-less-questions");
		moreFaqBtn.classList.add("is-open");
		Array.from(accordionItems)
			.slice(3)
			.forEach((item) => item.classList.remove("hidden"));
	} else {
		moreFaqBtn.querySelector(".js-accordion__title").textContent = moreFaqBtn.getAttribute("data-text-more-questions");
		moreFaqBtn.classList.remove("is-open");
		Array.from(accordionItems)
			.slice(3)
			.forEach((item) => item.classList.add("hidden"));
	}
}

function checkSelectedItem(event) {
	headerFaqItems.forEach((el) => el.classList.remove("selected"));
	event.currentTarget.classList.add("selected");

	for (const content of allContent) {
		if (content.getAttribute("data-number") === event.currentTarget.getAttribute("data-number")) {
			content.style.display = "block";
		} else {
			content.style.display = "none";
		}
	}
}

export { init };
