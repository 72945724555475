/**
 * Render career job sites for Classic and Shop
 * Uses the prescreen.io API to get jobs
 * scrolltofixed to fix the job application sidebar (https://github.com/bigspotteddog/ScrollToFixed)
 *
 */

import * as accordion from "@sharedJS/accordion";
import * as careerFilter from "@classicJS/careerFilter";
import * as careerAnchor from "@classicJS/careerAnchor";
import * as renderTemplate from "@sharedJS/renderTemplate";

let $aside;
let $accordionHeader;
let $accordionBody;
let $jobsClassic;
let $jobsShop;
let $jobsRetail;

let url;

function init() {
	$jobsClassic = $(".js-jobs-classic");
	$jobsShop = $(".js-jobs-shop");
	$jobsRetail = $(".js-jobs-retail");

	const urlClassic =
		"https://holy.prescreenapp.io/job/list/mgvjbem2tolsz4unfh5w07y9ju45h34?format=json&lang=de&max_results=500";
	const urlShop =
		"https://outletcity.prescreenapp.io/job/list/3mbb85bwefmssc88go4ocs40kwss48k?format=json&lang=de&max_results=500";
	const urlRetail =
		"https://holy-retail-community.prescreenapp.io/job/list/qvxlfyz0c485zokxakea15jkf7csa1l?format=json&lang=de&max_results=500";

	if (!($jobsClassic.length || $jobsShop.length || $jobsRetail.length)) {
		return;
	}

	// set the correct job feed
	if ($jobsClassic.length > 0) {
		url = urlClassic;
	} else if ($jobsRetail.length > 0) {
		url = urlRetail;
	} else {
		url = urlShop;
	}

	_renderClassicJobs();
}

function _renderClassicJobs() {
	$.ajax({
		type: "GET",
		url,
		dataType: "json",
		success(data) {
			const jobs = $(data.jobs);

			_renderJobEntries(jobs);

			_renderJobFilter(data);

			careerFilter.init();

			$accordionHeader = $(".js-accordion-headline");
			$accordionBody = $(".js-accordion-body");

			accordion.setAccordion($accordionHeader, $accordionBody);

			_fixAside();

			careerAnchor.init();

			$(".js-loader").remove();
		},
	});
}

function _renderJobEntries(jobs) {
	const $container = $("#classicJobsContainer");
	const template = $("#classicJobsTemplate");

	jobs.each((index) => {
		const job = { job: jobs[index] };
		const description = $(jobs[index].description);

		const customDataFields = jobs[index]["custom_data_fields"];
		const labels = {};
		customDataFields.forEach((element) => {
			labels[element.name] = String(element.value).trim();
		});

		// add team filter to job object
		jobs[index]["teamFilter"] = labels["Bereich"] || labels["Tätigkeitsbereich"];

		// add position filter to job object
		jobs[index]["positionFilter"] = String(jobs[index].positionType.title).trim();

		// add team filter to job object
		jobs[index]["brandFilter"] = labels["Talent Pool"];
		jobs[index]["brand"] = labels["Talent Pool"];

		const descriptionNew = [];
		$.each(description, (descriptionIndex, descriptionValue) => {
			$.each(descriptionValue, (itemIndex) => {
				descriptionNew.push(descriptionValue[itemIndex]);
			});
		});
		job.job.description = descriptionNew;
	});

	jobs.sort((a, b) => {
		const brandA = a.brand;
		const brandB = b.brand;

		if (brandA < brandB) {
			return -1;
		}
		if (brandA > brandB) {
			return 1;
		}
		return 0;
	});

	jobs.each((index) => {
		const job = { job: jobs[index] };
		renderTemplate.render($container, template, job);
	});
}

function _renderJobFilter(data) {
	const $container = $("#classicJobsFilterContainer");
	const template = $("#classicJobsFilterTemplate");

	const filterCheckPosition = [];
	const filterCheckTeam = [];
	const filterCheckBrand = [];

	const jobs = $(data.jobs);

	const teamArray = [];
	const positionArray = [];
	const brandArray = [];

	jobs.each((index, value) => {
		// add position filter
		const positionName = value["positionType"]["title"];
		if (positionName) {
			_checkFilterEntry(positionName, filterCheckPosition, positionArray);
		}

		// add team filter
		const teamName = value["teamFilter"];
		if (teamName) {
			_checkFilterEntry(teamName, filterCheckTeam, teamArray);
		}

		// add brand filter
		const brandName = value["brandFilter"];
		if (brandName) {
			_checkFilterEntry(brandName, filterCheckBrand, brandArray);
		}
	});

	data["teamFilter"] = _sortFilter(teamArray);
	data["positionFilter"] = _sortFilter(positionArray);
	data["brandFilter"] = _sortFilter(brandArray);

	renderTemplate.render($container, template, data);
}

function _sortFilter(filter) {
	return filter.sort((filterA, filterB) => {
		const nameOfFilterA = String(filterA.name).toLowerCase();
		const nameOfFilterB = String(filterB.name).toLowerCase();

		if (nameOfFilterA < nameOfFilterB) {
			return -1;
		}
		if (nameOfFilterA > nameOfFilterB) {
			return 1;
		}
		return 0;
	});
}

function _checkFilterEntry(filterName, filterCheck, filterArray) {
	const filterValue = filterName.replace(/\s+/g, "-").replace(/&/g, "and").toLowerCase();
	const filterExists = $.inArray(filterName, filterCheck);

	if (filterExists === -1) {
		filterCheck.push(filterName);

		filterArray.push({
			name: filterName,
			value: filterValue,
		});
	}
}

function _fixAside() {
	$aside = $(".js-career-aside");

	//set the aside box to fixed if scrolled
	$aside.each((i) => {
		const item = $($aside[i]);
		const scrollLimit = item.closest(".js-scroll-parent").children(".js-scroll-limit");

		item.scrollToFixed({
			marginTop: 68,
			limit() {
				return scrollLimit.offset().top - $(this).outerHeight(true) - 10;
			},
			removeOffsets: true,
			zIndex: 200,
		});
	});
}

export { init };
