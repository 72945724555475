/**
 * NEWSLETTER
 */

// DOM
let mail;
let getiframesrc;
let setiframesrc;

function init() {
	const $iframe = $(".js-classic-newsletter");

	if ($iframe.length < 1) {
		return;
	}

	mail = decodeURIComponent(window.location.search);
	getiframesrc = $iframe.attr("src");
	setiframesrc = getiframesrc + mail;
	$iframe.attr("src", setiframesrc);
}

export { init };
