/**
 * Created by ajla on 31.05.16.
 */

export default {
	m_down: "not all and (min-width: 720px)",
	m: "(min-width: 720px) and (max-width: 1023px)",
	m_up: "(min-width: 720px)",
	l_down: "not all and (min-width: 1024px)",
	portrait: "(orientation : portrait)",
	landscape: "(min-width: 1024px) and (orientation : landscape), (min-width: 1024px) and (orientation : portrait)",
	l: "(min-width: 1024px) and (max-width: 1279px)",
	l_up: "(min-width: 1024px)",
	xl_down: "not all and (min-width: 1280px)",
	xl: "(min-width: 1280px) and (max-width: 1399px)",
	xl_up: "(min-width: 1280px)",
	xxl_down: "not all and (min-width: 1400px)",
	xxl_up: "(min-width: 1400px)",
};
