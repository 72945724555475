/**
 * Productlist infinite scrolling
 *
 * Handles infinite scrolling using the library infiniteScrollHelper:
 * https://github.com/expandtheroom/jquery-infinite-scroll-helper *
 *
 *
 */

// DOM
let $tileGrid;
let $loader;
let $loadMoreClass;

// Dependencies

// Settings
const isTouchDevice = Modernizr.touchevents;
let numItems;
let tileElement;
const tilesToShow = 3;

showTiles.current = 3;

function init() {
	// DOM
	$tileGrid = $(".c-tile-grid");

	// Loader anhängen
	$loader = $('<div class="[ c-loader c-loader--product-list-infinite ]"></div>');
	$tileGrid.after($loader);

	if (!isTouchDevice) {
		initScrollHelper();
	} else {
		// append load more button

		setInitTiles();

		$loadMoreClass = $(".js-load-more");

		$loadMoreClass.on("click", () => {
			showTiles(tilesToShow);
		});
	}

	tileElement = $(".c-tile").not(".not-inrange").not(".is-hidden");

	numItems = tileElement.length;
}

function updateTileElements() {
	tileElement = $(".c-tile").not(".not-inrange").not(".is-hidden");
	numItems = tileElement.length;
	initalLoadedElements();
}

function initalLoadedElements() {
	setInitTiles();

	reinitializeLoading();
}

function reinitializeLoading() {
	showTiles.current = 0;
	initScrollHelper();
}

function initScrollHelper() {
	const bottomBuffer = 200;

	$tileGrid.infiniteScrollHelper({
		loadMore,
		doneLoading,
		bottomBuffer,
		debounceInt: 100,
		// triggerInitialLoad: true
	});
}

function loadMore() {
	$loader.addClass("loading");
	showTiles(tilesToShow);
}

function doneLoading() {
	if (showTiles.current >= numItems) {
		destroyLoading();
	}
	return true;
}

function showTiles(_max) {
	tileElement.each((_ind, _el) => {
		if (_ind >= showTiles.current && _ind < showTiles.current + _max && !$(_el).hasClass("is-hidden")) {
			$(_el).addClass("c-tile--show");
		}
	});
	showTiles.current += _max;
	$loader.removeClass("loading");
}

function destroyLoading() {
	$tileGrid.infiniteScrollHelper("destroy");
}

function setInitTiles() {
	tileElement = $(".c-tile").not(".not-inrange").not(".is-hidden");

	$.each(tileElement, function (i) {
		$(this).addClass("c-tile--show");
		return i < tilesToShow - 1;
	});
}

export { init, updateTileElements, reinitializeLoading };
