let $closeBtn;

function init() {
	$closeBtn = $(".js-close-btn");
	closeButton();
}

const closeButton = function () {
	$closeBtn.on("click", function () {
		$(this).closest(".js-this-close").addClass("is-closed").delay(200).hide(0);
	});
};
export { init };
