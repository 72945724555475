/**
 * ocm.classicMapCurrentPosition.js
 *
 * Places a marker on the map representing your current position. If no geolocation is available or it is disabled, it shows a message.
 *
 * Accuracy, boundaries and center have a default value and can be overwritten in the CMS cockpit.
 *
 */

import * as classicMapConfig from "@classicJS/classicMapConfig";

import { map_messages } from "@JS/classic/ocmSettings";

//---------------------------
// Variables
//---------------------------

// DOM
var $positionTrackingButton, $messageWindow;
// Settings
var accuracy,
	mapCenter,
	mapRadius,
	observableArea,
	positionDefined,
	positionWatcher,
	positionMarker,
	positionActive,
	positionWatchActive,
	map,
	initialCheckTimeout,
	locationTimeout,
	messages,
	myPoint;

// Dependencies

var POSITIONCONFIG, WATCHOPTIONS;

//---------------------------
// Init
//---------------------------

function init(_map) {
	// Dependencies
	POSITIONCONFIG = classicMapConfig.getPositionConfig();
	WATCHOPTIONS = classicMapConfig.getPositionOptions();

	// DOM
	$positionTrackingButton = $(".js-maps-position-button");
	$messageWindow = $(".js-maps-message-container");

	// Settings
	accuracy = POSITIONCONFIG.accuracy;
	mapRadius = POSITIONCONFIG.radius;
	mapCenter = {
		lat: POSITIONCONFIG.lat,
		lng: POSITIONCONFIG.lng,
	};
	messages = map_messages;
	map = _map;
	positionDefined = false;
	positionWatchActive = false;
	positionActive = false;

	// Bindings

	if (navigator.geolocation) {
		$positionTrackingButton.addClass("is-visible");
		$positionTrackingButton.on("click", _positionTrackingButtonDelegator);
	}
}

function _positionTrackingButtonDelegator() {
	if (positionActive && myPoint) {
		_recenterMap();
	} else {
		if (positionWatchActive) {
			_clearPositionWatcher();
		} else {
			_createObservableArea();
			_startPositionWatcher();
		}
	}
}

function _clearPositionWatcher() {
	navigator.geolocation.clearWatch(positionWatcher);
	_resetMessages();
	positionDefined = false;
}

function _recenterMap() {
	map.panTo(myPoint);
	if (map.getZoom() > POSITIONCONFIG.recenterZoom) {
		map.setZoom(POSITIONCONFIG.recenterZoom);
	}
}

function _startPositionWatcher() {
	positionWatcher = navigator.geolocation.watchPosition(_checkPositionAccuracy, errorFunction, WATCHOPTIONS);

	_delayDeterminingLocationMessage();
	_setInitialCheckTimeout();
}

function _delayDeterminingLocationMessage() {
	if (!positionDefined) {
		locationTimeout = setTimeout(function () {
			if (!positionDefined) {
				_showMessage(messages.determiningLocation);
			}
		}, 1000);
	}
}

function _setInitialCheckTimeout() {
	initialCheckTimeout = setTimeout(function () {
		if (!positionDefined) {
			navigator.geolocation.clearWatch(positionWatcher);
			positionWatchActive = false;
			_showMessage(messages.locationNotFound);
		}
	}, 10000);
}

function _checkPositionAccuracy(position) {
	positionWatchActive = true;
	myPoint = {
		lat: position.coords.latitude,
		lng: position.coords.longitude,
	};

	if (_checkForPointInArea(myPoint)) {
		if (positionDefined) {
			_setPositionPoint(position);
			_resetMessages();
			return;
		}

		if (position.coords.accuracy <= accuracy) {
			_createInitialPositioning(position);
		}
		return;
	}
	positionWatchActive = false;
	clearTimeout(initialCheckTimeout);
	clearTimeout(locationTimeout);
	_showMessage(messages.outsideArea);
}

function _createInitialPositioning(position) {
	clearTimeout(initialCheckTimeout);
	clearTimeout(locationTimeout);
	positionDefined = true;
	positionActive = true;
	_resetMessages();
	_createPositionMarker(position);
}

function _checkForPointInArea(point) {
	return observableArea.getBounds().contains(point);
}

function _setPositionPoint(position) {
	var pointlat = position.coords.latitude,
		pointlng = position.coords.longitude,
		circleAccuracy = position.coords.accuracy >= 400 ? 500 : position.coords.accuracy + 100;

	positionMarker.setContent(
		'<div class="[ c-maps__position-marker ]"><span class="[ c-maps__position-point ]"></span><span class="[ c-maps__position-accuracy ]" style="width:' +
			circleAccuracy * 2 +
			"%; height:" +
			circleAccuracy * 2 +
			'%"></span></div>'
	);

	positionMarker.setPosition(new google.maps.LatLng(pointlat, pointlng));
}

function _createObservableArea() {
	observableArea = new google.maps.Circle({
		strokeOpacity: 0,
		fillOpacity: 0,
		map: map,
		center: mapCenter,
		radius: mapRadius,
	});
}

function _createPositionMarker(position) {
	var pointlat = position.coords.latitude,
		pointlng = position.coords.longitude,
		pos = { lat: pointlat, lng: pointlng };

	positionMarker = new RichMarker({
		map: map,
		flat: true,
		anchor: RichMarkerPosition.CENTER,
		position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
	});

	_setPositionPoint(position);
	map.setCenter(pos);
}

function _resetMessages() {
	$messageWindow.removeClass("is-visible");
}

function _showMessage(content) {
	$messageWindow.addClass("is-visible");
	$messageWindow.html(content);
}

function errorFunction() {
	$messageWindow.html("Error: " + messages.locationNotFound);
}

export { init };
