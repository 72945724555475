// DOM
let accordionLink;
let $navAccordion;

function init() {
	accordionLink = $(".js-accordion-link");
	$navAccordion = $(".js-accordion-nav");

	navAccordion();

	$(window).resize(() => {
		navAccordion();
	});
}

const navAccordion = function () {
	$navAccordion.navgoco({
		caretHtml: '<i class="[ c-icon  c-icon--arrow-down-big ]"></i>',
		accordion: true,
		openClass: "is-active",
		save: true,
		slide: {
			duration: 400,
			easing: "swing",
		},
	});

	accordionLink.each(function () {
		if ($(this).next("ul").length) {
			$(this).removeAttr("href");
		}
	});
};

export { init };
