/**
 *  Render Template global function
 */

// todo: init durch render ersetzen
function init($container, gridTemplate, list) {
	render($container, gridTemplate, list);
}

function render($container, gridTemplate, json) {
	if (gridTemplate.length) {
		const template = gridTemplate.html();
		//tell Mustache.js to iterate through the JSON and insert the data into the HTML template
		const output = Mustache.render(template, json);
		//append the HTML template to the DOM
		$container.append(output);
	}
}

export { init, render };
