window.ocm = window.ocm || {};
ocm = window.ocm || {};
ocm.error_messages = ocm.error_messages || {};

export const config = ocm.config || {};
export const error_messages = ocm.error_messages;
export const maptext = ocm.maptext || {};
export const map_messages = ocm.map_messages || {};
export const cityMap = ocm.cityMap || {};
export const adyen = ocm.adyen || {};
