import * as formValidate from "@sharedJS/formValidate";
import * as rest from "@sharedJS/rest";

let $loyaltyMigration;
let errorClass;

const init = function () {
	$loyaltyMigration = $("#loyalty_migration");
	if ($loyaltyMigration.length === 0) {
		return;
	}
	errorClass = "u-error-text";

	$loyaltyMigration.on("change", "input", (event) => {
		formValidate.validateElement(event.currentTarget, errorClass);
	});

	$loyaltyMigration.on("submit", (event) => {
		event.preventDefault();
		const isValid = formValidate.validateForm(event.target, { errorClass });

		if (!isValid) {
			return;
		}

		sendForm();
	});
};

const sendForm = function () {
	const context = {
		form: $loyaltyMigration,
		callback: sendForm,
	};
	rest.submitForm(context);
};

export { init };
