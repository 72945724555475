import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";
import stickyHeader from "@sharedJS/stickyHeader";

/**
 * @type {HTMLElement | null}
 */
let headerNavEl = null;
const _defaultHeaderNavHeight = 46;

const init = function () {
	// todo: newheader -> pageType anders ermitteln (siehe auch scss)

	$(".js-nav-login-button").on("click", (e) => {
		const _selectedLayerName = $("#master-common-layer").data("layerselected") || null;
		if (_selectedLayerName) {
			e.preventDefault();
			OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN, {
				isClosable: true,
			});
		}
	});

	headerNavEl = document.querySelector(".js-header-nav");

	stickyHeader.setHelperClasses();

	_onHeaderNavHeightChanged();

	const navContainer = document.querySelector(".js-primary-nav-container");
	if (navContainer) {
		navContainer.addEventListener("mouseenter", () => {
			document.querySelector(".js-primary-nav-toggle").classList.add("is-open");
		});
		navContainer.addEventListener("mouseleave", () => {
			document.querySelector(".js-primary-nav-toggle").classList.remove("is-open");
		});
	}

	const mediaQuery = window.matchMedia("(min-width: 64em)");
	if (mediaQuery.addEventListener) {
		mediaQuery.addEventListener("change", _onHeaderNavHeightChanged);
	}
};

const getHeaderNavHeight = () => headerNavEl?.offsetHeight ?? _defaultHeaderNavHeight;

const _onHeaderNavHeightChanged = () => {
	const headerNavHeight = getHeaderNavHeight();
	document.documentElement.style.setProperty("--header-nav-height", headerNavHeight);
	OcmMessenger.publish(messengerPublicationTypes.HEADER_NAV_HEIGHT_CHANGED, { headerNavHeight });
};

const header = {
	init,
};

export default header;
// todo: newheader
