/*global ocmStores*/
/**
 * ocm.classicCityMap
 *
 * Initializes the custom google maps implementation.
 *
 * The google maps script is loaded on demand.
 * Also initializes the close button and every module needed.
 *
 */

// init Richmarker and Infobox from goolge maps utils https://github.com/googlemaps/v3-utility-library
import * as googleMapsInfobox from "@classicJS/googleMapsInfobox";
import * as googleMapsRichmarker from "@classicJS/googleMapsRichmarker";

import * as config from "@classicJS/classicMapConfig";
import * as infobox from "@classicJS/classicMapInfobox";
import * as markerFactory from "@classicJS/classicMapMarker";
import * as buildings from "@classicJS/classicMapBuildings";
import * as zoom from "@classicJS/classicMapZoom";
import * as admin from "@classicJS/classicMapAdmin";
import * as currentPosition from "@classicJS/classicMapCurrentPosition";
import * as utils from "@sharedJS/utils";

import { maptext } from "@JS/classic/ocmSettings";

import { onConsentGiven } from "@ocm/services/services.consent";

// maps src url
const mapsSrcUrl =
	"https://maps.googleapis.com/maps/api/js?v=3.49&key=AIzaSyCPBVgmtA2WQ1FTry12vDNI4RiP_K-TUCc&libraries=geometry&callback=ocm.classicCityMap.initMap";

// DOM
let map;

// Settings
let data;
let _infobox;

// Usercentrics / Consent für Google Maps
const usercentricsTemplateID = "S1pcEj_jZX";
const usercentricsServiceName = "Google Maps";

// DOM
let $cityMapElement;
let $mapsClose;
let $mapsCloseText;

// Language specific content
let content;

function init() {
	$cityMapElement = $(".js-classic-city-map");
	if ($cityMapElement.length === 0) return;

	// Language specific Content
	content = {
		to: "",
		city: "",
		close: "",
	};
	$.extend(content, maptext);

	// Init Close Buttons
	_initCloseMap();

	// Init Accept Google Maps Banner
	_initConsentBanner();

	onConsentGiven(usercentricsServiceName, initGoogleMaps);
}

function initGoogleMaps() {
	const googleScript = document.createElement("script");
	googleScript.setAttribute("src", mapsSrcUrl);
	googleScript.setAttribute("async", "async");
	document.head.appendChild(googleScript);
}

function initMap() {
	googleMapsInfobox.init();
	googleMapsRichmarker.init();

	const infoboxConfig = infobox.infoboxConfig(google);

	// get the "store" object defined in the CMS cockpit
	data = ocmStores;

	// create a new google map. select the HTML DOM Object by accessing the jQuery object with [0]
	map = new google.maps.Map($cityMapElement[0]);
	// set the maps options and styles
	map.setOptions(config.getMapConfig());

	config.createMapBounds(map);

	// set the initial info box
	_infobox = new InfoBox(infoboxConfig);

	// init functions
	currentPosition.init(map);

	// create marker object according to CMS data
	const marker = markerFactory.setMapMarker(map, data, _infobox);

	// create the colored buildings including sepration lines
	buildings.createPolygonAreas(google, map);
	buildings.createPolyline(google, map);

	// add zoom logic and eventlistener
	zoom.init(_infobox);
	zoom.showMarkerOnZoom(map, marker);
	zoom.onZoomChange(map, marker, _infobox);

	// close infobox on click outside
	google.maps.event.addListener(map, "click", () => {
		infobox.closeInfobox(_infobox);
	});

	// check if active store is set in parameter
	markerFactory.checkForActiveMarker(utils.getUrlParameter("id"), marker, map, _infobox);

	if (utils.getUrlParameter("admin") === "true") {
		admin.initializeMapAdmin(marker, map);
	}
}

function _initCloseMap() {
	$mapsClose = $(".js-maps-back");
	$mapsCloseText = $(".js-maps-back-text");

	// translate
	$mapsCloseText.html(content.close);

	$mapsClose.on("click", (event) => {
		event.preventDefault();
		window.history.back();
	});
}

function _initConsentBanner() {
	const $acceptGoogleMaps = $(".js-accept-googleMaps");
	const $openMoreInfo = $(".js-open-consent-dialog");
	const $consentBanner = $(".js-google-maps-consent-banner");

	$acceptGoogleMaps.on("click", (event) => {
		if (typeof window.UC_UI !== "undefined") {
			event.preventDefault();
			window.UC_UI.acceptService(usercentricsTemplateID).then(() => initGoogleMaps());
		}
	});

	$openMoreInfo.on("click", (event) => {
		if (typeof window.UC_UI !== "undefined") {
			event.preventDefault();
			window.UC_UI.showSecondLayer(usercentricsTemplateID);
		}
	});

	$consentBanner.css("opacity", 1);
}

export { init, initMap };
