import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

function init() {
	const $openSearchButton = document.querySelector(".js-open-search");
	if (!$openSearchButton) return;
	$openSearchButton.addEventListener("click", () => {
		OcmMessenger.publish(messengerPublicationTypes.OPEN_SEARCH);
	});
}
export { init };
