/**
 * ocm.classicMapConfig.js
 *
 * centralized config file for maps and admin
 *
 * For every getConfig method it returns an object.
 *
 * Also has the function for creating the map bounds.
 *
 */
import classicMapStyles from "@classicJS/classicMapStyles";
import * as resize from "@sharedJS/resize";

import { cityMap } from "@JS/classic/ocmSettings";

// Magic Strings
var DESKTOP = "default",
	TABLET = "tablet",
	SMARTPHONE = "smartphone";

function getMapConfig() {
	return _getMapOptions({
		styles: classicMapStyles,
		center: { lat: 48.5357633, lng: 9.2850087 },
		zoom: 17,
		activeZoom: 19,
		minZoom: 15,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		zoomControl: false,
		gestureHandling: "greedy",
	});
}

function getTypeConfig() {
	return ["store", "gastro", "info", "parking", "multi", "service"];
}

function getFlagConfig() {
	return ["", "new", "soon"];
}

function getAnchorConfig() {
	return ["left", "right"];
}

function getFieldConfig() {
	return [
		{ id: "type", label: "Type", type: "select" },
		{ id: "id", label: "ID", type: "text" },
		{ id: "icon", label: "Icon", type: "text" },
		{ id: "address", label: "Adresse", type: "text" },
		{ id: "title", label: "Headline", type: "text" },
		{
			id: "label",
			label: "Label",
			type: "text",
			description:
				"Bei Multimarker werden mehrere Einträge mit , separiert. <%TEXT%> stellt hinter dem Namen eine Auszeichnung mit dem TEXT dar.",
		},
		{ id: "textde", label: "Text DE", type: "textarea" },
		{ id: "texten", label: "Text EN", type: "textarea" },
		{ id: "link", label: "Link", type: "text" },
		{ id: "flag", label: "Flag", type: "select" },
		{ id: "priority", label: "Anzeigelevel", type: "text" },
		{ id: "anchor", label: "Ausrichtung", type: "select" },
	];
}

function getPositionConfig() {
	return _getPositionConfig({
		radius: 2000,
		lat: 48.537161,
		lng: 9.282284,
		accuracy: 150,
		recenterZoom: 18,
	});
}

function getPositionOptions() {
	return {
		enableHighAccuracy: true,
		maximumAge: 0,
	};
}

// Public Functions
function createMapBounds(map) {
	// bounds of the desired area
	var allowedBounds = new google.maps.LatLngBounds(
		new google.maps.LatLng(48.358315, 8.700897),
		new google.maps.LatLng(48.755588, 9.845669)
	);
	var lastValidCenter = map.getCenter();

	google.maps.event.addListener(map, "center_changed", function () {
		if (allowedBounds.contains(map.getCenter())) {
			lastValidCenter = map.getCenter();
			return;
		}
		map.panTo(lastValidCenter);
	});
}

// Private Functions

function _getMapOptions(defaultOptions) {
	var mapOptions = defaultOptions;
	var currentOption = "";

	if (cityMap && cityMap.options) {
		var screenSizes = resize.getScreenSizes();
		var customOptions = cityMap.options;

		customOptions[DESKTOP] = customOptions[DESKTOP] || defaultOptions;
		customOptions[TABLET] = customOptions[TABLET] || customOptions[DESKTOP];
		customOptions[SMARTPHONE] = customOptions[SMARTPHONE] || customOptions[TABLET];

		currentOption = customOptions[DESKTOP];

		if (screenSizes.m.isScreenSize) {
			currentOption = customOptions[TABLET];
		} else if (screenSizes.m_down.isScreenSize) {
			currentOption = customOptions[SMARTPHONE];
		}
	}

	$.extend(mapOptions, currentOption);
	return mapOptions;
}

function _getPositionConfig(defaultConfig) {
	var positionOptions;

	positionOptions = defaultConfig;
	if (cityMap && cityMap.position) {
		positionOptions = $.extend(positionOptions, cityMap.position);
	}
	return positionOptions;
}

export {
	createMapBounds,
	getMapConfig,
	getTypeConfig,
	getFlagConfig,
	getAnchorConfig,
	getFieldConfig,
	getPositionConfig,
	getPositionOptions,
};
