import * as formValidate from "@sharedJS/formValidate";

let csrfTokenAttempts = 0;

// fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse to be fixed in TUB-9716
function handleError(
	errorResponse,
	formId,
	callback,
	callbackData,
	csrfErrorCallback,
	ignoreRedirect,
	fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
) {
	console.log("Handle Errors…");
	if (formId.lastIndexOf("#", 0) !== 0) {
		formId = `#${formId}`;
	}
	const $form = $(formId);
	_removeGlobalErrors($form);
	if (errorResponse.status === 412) {
		_handleCsrfError(errorResponse, callback, callbackData, csrfErrorCallback);
	} else if (errorResponse.status === 422) {
		_handleValidationError(errorResponse.responseJSON, $form);
	} else if (errorResponse.status === 401 || errorResponse.status === 403) {
		_handleAuthorisationError(
			errorResponse,
			ignoreRedirect,
			fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
		);
	}
}

function handleGetError(
	errorResponse,
	callback,
	callbackData,
	ignoreRedirect,
	fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
) {
	console.log("Handle Error…");
	if (errorResponse.status === 412) {
		_handleCsrfError(errorResponse, callback, callbackData);
	} else if (errorResponse.status === 401 || errorResponse.status === 403) {
		_handleAuthorisationError(
			errorResponse,
			ignoreRedirect,
			fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
		);
	}
}

function _removeGlobalErrors($form) {
	$form.children(".u-error-text").remove();
}

function _handleAuthorisationError(
	errorResponse,
	ignoreRedirect,
	fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse
) {
	if (ignoreRedirect && !fixThisErrorThatsNotShowingTheReauthPageAndDontBreakAnythingElse) {
		console.log("Ignore default 401 or 403 Error handling");
	} else {
		console.log("Handle 401 or 403 Error…");
		window.location.assign(errorResponse.responseJSON.redirectUrl);
	}
}

function _handleCsrfError(errorResponse, callback, callbackData, csrfErrorCallback) {
	console.log("Handle 412 CSRF error…");
	if (++csrfTokenAttempts < 3) {
		$.ajaxSetup({
			headers: { csrftoken: errorResponse.getResponseHeader("csrftoken") },
		});
		console.log("Set new CSRF token header to %s", errorResponse.getResponseHeader("csrftoken"));
		callback(callbackData);
	} else {
		csrfErrorCallback();
	}
}

function _handleValidationError(errors, $form) {
	console.log("Handle 422 Error…");
	_handleFieldErrors(errors.fieldErrors);
	_handleGlobalErrors(errors.globalErrorMessages, $form);
}

function _handleGlobalErrors(globalErrorMessages, $form) {
	if (globalErrorMessages.length === 0) {
		return;
	}
	console.log("Add global error messages…");
	globalErrorMessages.forEach((errorMessage) => {
		formValidate.setGlobalError($form, true, errorMessage);
	});
}

function _handleFieldErrors(fieldErrors) {
	fieldErrors.forEach((error) => {
		if (error.errorMessages.length > 0) {
			console.log(`Adding field error messages… ${error.fieldName}`);
			error.errorMessages.forEach((message) => {
				formValidate.setError(error.fieldName, true, message, "u-error-text");
			});
		} else {
			console.log("Remove field error messages…");
			formValidate.setError(error.fieldName, true, "", "");
		}
	});
}

export { handleError, handleGetError };
