import axios from "axios";

const init = () => {
	if (!window.ocm?.sprites) {
		return;
	}

	const benefitsCarelabelsUrl = `${window.ocm.config.resourcesHostname}/_ui/project/sprites/${window.ocm.sprites["benefits-carelabels"]}`;
	const iconsUrl = `${window.ocm.config.resourcesHostname}/_ui/project/sprites/${window.ocm.sprites.icons}`;
	const logoIconUrl = `${window.ocm.config.resourcesHostname}/_ui/project/sprites/${window.ocm.sprites.logos}`;
	const categoriesIconUrl = `${window.ocm.config.resourcesHostname}/_ui/project/sprites/${window.ocm.sprites.categories}`;

	getSpriteAndInject(benefitsCarelabelsUrl);
	getSpriteAndInject(iconsUrl);
	getSpriteAndInject(logoIconUrl);
	getSpriteAndInject(categoriesIconUrl);
};

async function getSpriteAndInject(spriteUrl) {
	try {
		const response = await axios.get(spriteUrl);
		document.body.insertAdjacentHTML("afterbegin", response.data);
	} catch (error) {
		console.error("sprite fetch:", error);
	}
}

export { init };
