import UpsellingBanner from "@classicJS/upsellingBanner";
import * as utils from "@sharedJS/utils";
import * as formCaptcha from "@sharedJS/formCaptcha";
import * as popUpOverlay from "@sharedJS/popUpOverlay";

import * as classicCountdown from "@classicJS/classicCountdown";
import * as classicCityMap from "@classicJS/classicCityMap";
import * as eightselect from "@JS/shop/10-components/eightselect";
import * as storage from "@JS/shared/10-components/storage";
import * as tooltip from "@JS/shared/10-components/tooltip";
import * as banderole from "@JS/shared/10-components/banderole";

window.ocm = window.ocm || {};

window.ocm.UpsellingBanner = UpsellingBanner || {};
window.ocm.utils = utils || {};
window.ocm.popUpOverlay = popUpOverlay || {};
window.ocm.eightselect = eightselect || {};
window.ocm.storage = storage || {};
window.ocm.tooltip = tooltip || {};

window.ocm.classicCountdown = classicCountdown || {};
window.ocm.classicCityMap = classicCityMap || {};

window.ocm.formCaptcha = formCaptcha || {};

window.ocm.banderole = banderole || {};

// Abhängigkeiten Tag-Manager: https://redbox.outletcity.com/jira/browse/TUB-7473
// Abhängigkeiten hybris CMS: https://redbox.outletcity.com/jira/browse/TUB-7472
