import * as resize from "./resize";

const jsClasses = ".js-scroll-to-label, .js-teaser-consultant-spikemark";
const headerOffset = 50;
const headerOffsetMobile = 50;

function init() {
	_bindScrollToTarget();
}

function _bindScrollToTarget() {
	const scrollButton = $(jsClasses);
	scrollButton.on("click", function (e) {
		const target = $(this).attr("href");
		const $target = $(target);

		if ($target.length > 0) {
			e.preventDefault();
			let offset = headerOffset;

			if (resize.getScreenSizes().m_down.isScreenSize === true) {
				offset = headerOffsetMobile;
			}

			const ScrollPosition = $target.offset().top - offset;
			$("html,body").animate(
				{
					scrollTop: ScrollPosition,
				},
				600
			);
		}
	});
}

export { init };
