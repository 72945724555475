const cache = {};

let contextPath = ocm && ocm.config && ocm.config.encodedContextPath ? ocm.config.encodedContextPath : "";

const init = () => {
	$.ajaxSetup({ cache: false });
};

const changeShopCountryContext = (countryCode) => {
	// countryCode : DE / AT / CH
	if (!["de", "at", "ch"].includes(countryCode)) {
		countryCode = "de";
	}

	if (!(typeof countryCode === "string")) {
		return { error: "wrong type" };
	}

	const currentContextPath = contextPath;
	// new context Path
	if (contextPath.indexOf("-de/shop") >= 0) {
		contextPath = `/${String(countryCode).toLowerCase()}-de/shop`;
	}

	return { contextPath, hasChanged: currentContextPath !== contextPath };
};

// --------------------------
const getUrl = (path) => {
	const locationOrigin = `${window.location.origin}`;
	if (path.match(`^${contextPath}`)) {
		return locationOrigin + path;
	}
	return locationOrigin + contextPath + path;
};

//-----------------
const siteUrlLinkResolver = (el) => {
	// Links im CMS-Cockpit
	//
	if (document.getElementById("js-login-interrupt") && el.closest("#mf-productlist")) {
		// verhindere die weitere Ausführung für CMS-Componenten, die in vue in die Produktliste geladen werden und den siteUrlLinkResolver aufrufen.
		return;
	}

	const ahrefTargetURL = el.getAttribute("data-href");
	const siteContext = contextPath;
	if (ahrefTargetURL !== undefined && siteContext !== undefined) {
		const targetURL = siteContext + ahrefTargetURL;
		window.location.href = window.location.origin + targetURL;
	}
};

// --------------------------
const getUrlParameter = (parameter) => {
	const url = window.location.href;
	const pageURL = decodeURIComponent(url);
	const pageVariable = pageURL.slice(pageURL.indexOf("?") + 1).split("&");
	let parameterName;
	let i;

	for (i = 0; i < pageVariable.length; i++) {
		parameterName = pageVariable[i].split("=");

		if (parameterName[0] === parameter) {
			return parameterName[1] === undefined ? true : parameterName[1];
		}
	}
	// todo: Rückgabe aller Parameter, wenn kein Parameter angefragt wurde?
};

// --------------------------
const getLangcode = () => {
	// todo: cache Vorgehen überprüfen
	if (!cache["langcode"]) {
		cache["langcode"] = $("html").attr("lang") || "de";
	}
	return cache["langcode"];
};

// --------------------------
// Looks for an Object within an Array or an Object
// and returns the content
const findValueOfPropertyInArray = (array, property) => {
	// todo: cachen // einen Flag für das Cachen or not als Parameter, falls es ein ungecachster Wert sein soll (wird dann neu gecacht!)
	let _valueOfProperty = null;
	const _obj = array;
	const _property = property;
	for (const i in _obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (!_obj.hasOwnProperty(i)) {
			continue;
		}
		if (i === _property) {
			return _obj[_property];
		}
		if (typeof _obj[i] === "object") {
			_valueOfProperty = findValueOfPropertyInArray(_obj[i], _property);
			if (_valueOfProperty !== null) {
				return _valueOfProperty;
			}
		}
	}
	return null;
};

const getRecommendation = () => {
	window.dataLayer = window.dataLayer || {};
	return findValueOfPropertyInArray(window.dataLayer, "Recommendation");
};

const getPageUid = () => {
	if (cache.pageUid) {
		return cache.pageUid;
	}

	try {
		const pageUid = getRecommendation().pageData.pageUid;
		cache.pageUid = pageUid;
		return pageUid;
	} catch (e) {
		cache.pageUid = null;
		return null;
	}
};

const getPageType = () => {
	if (cache.pageType) {
		return cache.pageType;
	}

	try {
		const pageType = getRecommendation().pageData.pageType;
		cache.pageType = pageType;
		return pageType;
	} catch (e) {
		cache.pageType = null;
		return null;
	}
};

// --------------------------
const replaceString = (string, oldString, newString) => {
	const replaceObject = {};
	oldString.forEach((key, index) => {
		replaceObject[key] = newString[index];
		return string;
	});
	const regex = new RegExp(Object.keys(replaceObject).join("|"), "gi");
	const replacedString = string.replace(regex, (matched) => replaceObject[matched]);
	return replacedString;
};

// --------------------------
// Load recources

const loadCssFile = (href) => {
	const cssLink = window.document.createElement("link");
	const head = window.document.getElementsByTagName("head")[0];
	cssLink.rel = "stylesheet";
	cssLink.media = "all";
	cssLink.href = href;
	head.appendChild(cssLink);
};

//--------------------------------
// todo: wird ersetzt !!!!
//--------------------------------
const disableFormSubmitOnEnter = (form) => {
	// todo: überall ersetzen (siehe loginForm)
	console.warn("disableFormSubmitOnEnter is deprecated");
	if (form.length > 0) {
		form.on("keypress", (e) => {
			console.log("keypress");
			const keyCode = e.keyCode || e.which;
			if (keyCode == 13) {
				e.preventDefault();
				return false;
			}
		});
	}
};

//-------------------------------
const setComponentDisabled = (comp, doDisable) => {
	comp.prop("disabled", doDisable);
};

//-------------------------------
const registerEnterKeyEvent = (component, callbackFn) => {
	component.on("keypress", (evt) => {
		if (evt.which == 13) {
			evt.preventDefault();
			callbackFn();
			return false;
		}
	});
};

//-------------------------------
const registerClickAndEnterKeyEvent = (component, callbackFn) => {
	registerEnterKeyEvent(component, callbackFn);
	component.on("click", (evt) => {
		evt.preventDefault();
		callbackFn();
	});
};

//-------------------------------
const setErrorMessage = (field, val) => {
	// todo: siehe formValidate _toggleError()
	console.log("Errorhandling  für diesen Layer anpassen");
	// console.log("setErrorMessage",$('.error-' + field));

	if (val !== "") {
		$(`.error-${field}`).addClass("c-error__text");
	} else {
		$(`.error-${field}`).removeClass("c-error__text");
	}
	$(`.error-${field}`).text(val);
};

const disableScrolling = (toggle) => {
	if (typeof toggle !== "boolean" || !toggle) {
		document.body.style.overflow = "";
		document.body.style.paddingRight = "";
	} else {
		document.body.style.overflow = "hidden";
		const scrollbarWidth = Math.abs(window.innerWidth - document.documentElement.clientWidth);
		if (scrollbarWidth) document.body.style.paddingRight = `${scrollbarWidth}px`;
	}
};

// IOS prevent overscrolling
//-------------------------------
const removeIOSRubberEffect = (element) => {
	$("body").on("touchstart", element, (e) => {
		const top = e.currentTarget.scrollTop;
		const totalScroll = e.currentTarget.scrollHeight;
		const currentScroll = top + e.currentTarget.offsetHeight;

		if (top === 0) {
			e.currentTarget.scrollTop = 1;
		} else if (currentScroll === totalScroll) {
			e.currentTarget.scrollTop = top - 1;
		}
	});
};

const mouseWheelHandler = (element) => {
	element.on("mousewheel DOMMouseScroll", function (e) {
		const e0 = e.originalEvent;
		const delta = e0.wheelDelta || -e0.detail;

		this.scrollTop += (delta < 0 ? 1 : -1) * 30;
		e.preventDefault();
	});
};

const lightenDarkenColor = (col, amt) => {
	let usePound = false;

	if (col[0] === "#") {
		col = col.slice(1);
		usePound = true;
	}

	const num = parseInt(col, 16);
	const r = _setRange((num >> 16) + amt);
	const b = _setRange(((num >> 8) & 0x00ff) + amt);
	const g = _setRange((num & 0x0000ff) + amt);

	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

const _setRange = (val, min = 0, max = 255) => {
	if (val > max) {
		val = max;
	} else if (val < min) {
		val = min;
	}
	return val;
};

export {
	init,
	getUrlParameter,
	replaceString,
	findValueOfPropertyInArray,
	getRecommendation,
	getLangcode,
	getPageUid,
	getPageType,
	registerClickAndEnterKeyEvent,
	registerEnterKeyEvent,
	setErrorMessage,
	setComponentDisabled,
	disableFormSubmitOnEnter,
	getUrl,
	siteUrlLinkResolver,
	disableScrolling,
	removeIOSRubberEffect,
	mouseWheelHandler,
	lightenDarkenColor,
	changeShopCountryContext,
	loadCssFile,
};
