import { error_messages } from "@JS/shared/ocmSettings";

const get = (obj, path, defaultValue = undefined) => {
	const travel = (regexp) =>
		String.prototype.split
			.call(path, regexp)
			.filter(Boolean)
			.reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
	const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
	return result === undefined || result === obj ? defaultValue : result;
};

const getErrorMessage = (id, type) =>
	get(error_messages, `${id}.error.${type}`) || get(error_messages, `${id}.${type}`) || "[NULL]";

export { getErrorMessage };
