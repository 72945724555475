let $offerClassic;
let $offerShop;
let $buttonClassic;
let $buttonShop;

function init() {
	$offerClassic = $(".js-brand-detail-offer-classic");
	$offerShop = $(".js-brand-detail-offer-shop");

	$buttonClassic = $(".js-offer-switch-classic");
	$buttonShop = $(".js-offer-switch-shop");

	$buttonClassic.on("click", () => {
		if ($offerShop.hasClass("is-active")) {
			$offerClassic.addClass("is-active");
			$offerShop.removeClass("is-active");
		}

		if (!$buttonClassic.hasClass("is-active")) {
			$buttonClassic.addClass("is-active");
			$buttonShop.removeClass("is-active");
		}
	});

	$buttonShop.on("click", () => {
		if (!$offerShop.hasClass("is-active")) {
			$offerShop.addClass("is-active");
			$offerClassic.removeClass("is-active");
		}

		if (!$buttonShop.hasClass("is-active")) {
			$buttonShop.addClass("is-active");
			$buttonClassic.removeClass("is-active");
		}
	});
}

export { init };
