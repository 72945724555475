let $popupMessagesDiv;
let $target;

function init() {
	$popupMessagesDiv = $("#js-ocm-popup-messages-layer");
	if ($popupMessagesDiv.length) {
		ocm.popUpOverlay.openPopup($popupMessagesDiv, { blur: false });
	}
	_closeErrorMessagePopUp();
}

function _closeErrorMessagePopUp() {
	$target = $(".js-ocm-popup-message-close");
	if ($popupMessagesDiv.length) {
		$target.on("click", () => {
			ocm.popUpOverlay.closePopup($popupMessagesDiv);
		});
	}
}

export { init };
