/**
 * Resize Utility
 *
 *
 * - Checks for breakpoints when browser window is resized
 * - Any Object kann register as an Listener
 *   a Listener must have a public function updateBreakpoint(breakpoints)
 */

import breakpoints from "@sharedJS/breakpoints";

// lokal Variables
let resizeTimeOut = null;
let screenSizes;
let timerPeriod;
let listenerForResize;
let listenersForBreakpoint;

function init() {
	// Settings
	listenerForResize = [];
	listenersForBreakpoint = [];
	timerPeriod = 250;

	screenSizes = {};
	for (const breakpoint in breakpoints) {
		// eslint-disable-next-line no-prototype-builtins
		if (breakpoints.hasOwnProperty(breakpoint)) {
			const query = breakpoints[breakpoint];
			// screenSizes[breakpoint] = [query, isAktScreenSize, hasChanged]
			screenSizes[breakpoint] = { query, isScreenSize: Modernizr.mq(query), hasChanged: true };
		}
	}

	const width = $(window).width();

	// Bind Actions
	$(window).on("resize", function () {
		if (resizeTimeOut) {
			if ($(this).width() != width) {
				clearTimeout(resizeTimeOut);
				resizeTimeOut = null;
			}
		}
		resizeTimeOut = setTimeout(_resizeStopped, timerPeriod);
	});
}

function addListener(listener, eventType) {
	if (typeof listener === "undefined") {
		console.error("Failed to add listener. Its undefined. Eventype: %s ", eventType);
		return;
	}
	if (eventType === "breakpoint") {
		if (listenersForBreakpoint.indexOf(listener) < 0) {
			listenersForBreakpoint.push(listener);
		}
	} else {
		if (listenerForResize.indexOf(listener) < 0) {
			listenerForResize.push(listener);
		}
	}

	return $.extend({}, screenSizes);
}

function getScreenSizes() {
	return $.extend({}, screenSizes);
}

function _resizeStopped() {
	let somethingChanged = false;

	// check for changes in breakpoints
	for (const breakpoint in screenSizes) {
		// eslint-disable-next-line no-prototype-builtins
		if (screenSizes.hasOwnProperty(breakpoint)) {
			const isAktScreenSize = Modernizr.mq(screenSizes[breakpoint].query);
			const hasChanged = screenSizes[breakpoint].isScreenSize !== isAktScreenSize;
			screenSizes[breakpoint].isScreenSize = isAktScreenSize;
			screenSizes[breakpoint].hasChanged = hasChanged;
			if (somethingChanged === false && hasChanged === true) {
				somethingChanged = true;
			}
		}
	}

	_sendUpdateToListeners();

	if (somethingChanged) {
		_sendUpdateToListeners("breakpoint");
	}
}

function _sendUpdateToListeners(eventType) {
	let listeners;

	if (eventType === "breakpoint") {
		listeners = listenersForBreakpoint;
	} else {
		listeners = listenerForResize;
	}

	listeners.forEach((listener) => {
		listener.updateResize($.extend({}, screenSizes));
	});
}

export { init, addListener, getScreenSizes };
