import pushDataLayer from "@ocm/services/dataLayer.js";

export function sendTracking(trackCategory, trackAction) {
	if (!trackCategory) return;
	const prefix = window.ocm?.isClassicSite ? "C_" : "S_";
	const datalayerObj = {
		event: "GAevent",
		event_category: trackCategory ? prefix + trackCategory : "",
		event_action: trackAction ? prefix + trackAction : "",
		event_label: "",
	};

	pushDataLayer(datalayerObj);
}
