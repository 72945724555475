let seoSlider;

function init() {
	seoSlider = $(".js-seo-image-slider");
	if (seoSlider.length < 1) {
		return;
	}
	seoImageSlider();
}

function seoImageSlider() {
	seoSlider.each(function () {
		const thisSlider = $(this);
		const configurations = {
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			infinite: true,
			centerMode: false,
			variableWidth: false,
			autoplay: true,
			draggable: true,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: true,
			dotsClass: "c-slider-control__dots--seo",
		};
		const setAutoplaySpeed = function () {
			const speed = thisSlider.data("autorotation");
			thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
		};

		thisSlider
			.on("init", () => {
				setTimeout(() => {
					setAutoplaySpeed();
				}, 500);
			})
			.slick(configurations);
	});
}

export { init };
