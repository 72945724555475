// DOM
let $loader;

function addLoader($container) {
	$loader = $(
		'<div class="c-svg-loader__overlay fade-in js-fullLoader"><div class="c-svg-loader__spinner"><img src="/_ui/responsive/common/images/loader/loading.gif "></div></div>'
	);
	$container.append($loader);
}

function removeLoader() {
	if ($(".js-fullLoader").length) {
		$(".js-fullLoader").remove();
	}
}

export { addLoader, removeLoader };
