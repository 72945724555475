function init() {
	if ($(".js-timeline").length < 1) {
		return;
	}

	$(".js-timeline").timeline({
		startItem: "first",
		categories: ["1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000", "2010", "2020", "2030", "2040"],
		nuberOfSegments: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
		yearsOn: false,
		itemMargin: 18,
	});
}

export { init };
