/**
 * ocm.classicMapInfobox.js
 *
 * Creates the custom google maps infoboxes.
 *
 * Displays the defined datastructure which comes from the CMS cockpit.
 *
 */

import * as utils from "@sharedJS/utils";
import { maptext } from "@JS/shop/ocmSettings";

var langCode = utils.getLangcode();

// Language specific content
var content;

// Language specific Content
// todo: generell ein (besseres) Vorgehen hierfür finden
content = {
	to: "",
	city: "",
	close: "",
};

$.extend(content, maptext);

function setInfoboxContent(map, marker, _infobox) {
	var item = marker.ocmItem;

	var infoText = marker.ocmItem["text" + langCode];

	switch (item.type) {
		case "store":
			var boxBody = '<h1 class="[ c-classic-headline ] [ u-mb-small ] ">' + item.title + "</h1>";
			infoText ? (boxBody += infoText) : null;
			item.link
				? (boxBody +=
						'<p><a href="/' +
						langCode +
						"/metzingen/" +
						item.link +
						'" target="_self" class="[ o-arrow-link ]">' +
						content.to +
						" " +
						item.title +
						"</a></p>")
				: null;
			item.address ? (boxBody += "<p>" + item.address + "<br>72555 Metzingen</p>") : null;
			break;
		case "multi":
			var boxBody = '<h1 class="[ c-classic-headline ] [ u-mb-small ] ">' + item.title + "</h1>";
			boxBody += "<p>" + item.address + "<br>72555 Metzingen</p>"; // todo: city.
			var markerText = item.label != "" ? "label" : "title";
			$.each(item[markerText], function (i) {
				boxBody +=
					'<a href="/' +
					langCode +
					"/metzingen/" +
					item.link[i] +
					'" target="_self" class="[ o-arrow-link ]">' +
					content.to +
					" " +
					item[markerText][i].replace(/<%[\s\S]*%>/, "") +
					"</a><br>";
			});
			break;
		default:
		case "store":
			var boxBody = '<h1 class="[ c-classic-headline ] [ u-mb-small ] ">' + item.title + "</h1>";
			infoText ? (boxBody += infoText) : null;
			item.address ? (boxBody += "<p>" + item.address + "<br>72555 Metzingen</p>") : null;
			break;
	}
	var boxText = document.createElement("div");
	boxText.className = "[ c-maps__marker-overlay ]";
	boxText.innerHTML = boxBody;
	_infobox.setContent(boxText);

	setInfoboxPosition(_infobox, item.type);
}

function setInfoboxPosition(_infobox, type) {
	switch (type) {
		case "store":
		case "multi":
			setInfoboxOffset(-125, -10, _infobox);
			break;
		default:
			setInfoboxOffset(-125, -15, _infobox);
	}
}

function addInfoboxEventlistener(map, marker, _infobox) {
	marker.addListener("click", function () {
		setInfoboxContent(map, this, _infobox);
		closeInfobox(_infobox);
		_infobox.open(map, this);
	});
}

function closeInfobox(_infobox) {
	_infobox.close();
}

function openInfobox(_infobox, map, box) {
	_infobox.open(map, box);
}

function setInfoboxOffset(positionX, positionY, _infobox) {
	_infobox.setOptions({
		pixelOffset: new google.maps.Size(positionX, positionY),
	});
}

//---------------------------
// Config
//---------------------------

// Basic Infobox options
function infoboxConfig(google) {
	var config = {
		disableAutoPan: false,
		maxWidth: 0,
		alignBottom: true,
		closeBoxMargin: "9px 9px 9px 9px",
		closeBoxURL: "/_ui/responsive/theme-classic/images/marker/close.gif",
		infoBoxClearance: new google.maps.Size(1, 1),
		isHidden: false,
		pane: "floatPane",
		enableEventPropagation: false,
	};
	return config;
}

export { infoboxConfig, setInfoboxContent, addInfoboxEventlistener, closeInfobox, openInfobox, setInfoboxPosition };
