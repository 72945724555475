// DOM
var map;

// mobile Taps

// Settings

var styles = [
	{
		featureType: "all",
		elementType: "geometry",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "all",
		elementType: "geometry.fill",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "all",
		elementType: "geometry.stroke",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: 36,
			},
			{
				color: "#333333",
			},
			{
				lightness: 40,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#ffffff",
			},
			{
				lightness: 16,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#fefefe",
			},
			{
				lightness: 20,
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#fefefe",
			},
			{
				lightness: 17,
			},
			{
				weight: 1.2,
			},
		],
	},
	{
		featureType: "administrative.country",
		elementType: "geometry",
		stylers: [
			{
				visibility: "off",
			},
			{
				saturation: "0",
			},
		],
	},
	{
		featureType: "administrative.province",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "0",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
			{
				lightness: 20,
			},
		],
	},
	{
		featureType: "landscape.natural",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "50",
			},
		],
	},
	{
		featureType: "landscape.natural.landcover",
		elementType: "labels.text.fill",
		stylers: [
			{
				lightness: "0",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
			{
				lightness: 21,
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#dedede",
			},
			{
				lightness: 21,
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "0",
			},
			{
				saturation: "-100",
			},
			{
				color: "#c9c9c9",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 29,
			},
			{
				weight: 0.2,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 18,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "-27",
			},
			{
				color: "#e2e2e2",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				lightness: 16,
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "geometry.fill",
		stylers: [
			{
				saturation: "-31",
			},
			{
				lightness: "0",
			},
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#f2f2f2",
			},
			{
				lightness: 19,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				lightness: 17,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry.fill",
		stylers: [
			{
				saturation: "-100",
			},
			{
				lightness: "-24",
			},
			{
				color: "#efefef",
			},
		],
	},
];

//---------------------------
// Init
//---------------------------

var mapContainer;
var $window;

const init = function () {
	mapContainer = $(".js-google-maps");

	$window = $(window);
	if (mapContainer.length > 0) {
		$window.on("scroll", checkScroll);
		// todo: Scroll-Event je nach breakpoint
	}

	ocm.classicMaps.initMap = initMap;
};

function checkScroll() {
	var mapIsVisible = mapContainer.is(":visible");
	if (!mapIsVisible) return;

	var mapContainerTop = mapContainer.offset().top;
	var windowHeight = $window.height();
	var mapPosition = mapContainerTop - windowHeight;
	var scrollTop = $window.scrollTop();

	if (mapPosition < scrollTop + 50) {
		loadMap();
		$window.off("scroll", checkScroll);
	}
}

function loadMap() {
	if (typeof google === "undefined") {
		var googleScript = document.createElement("script");
		googleScript.setAttribute(
			"src",
			"https://maps.googleapis.com/maps/api/js?v=3.37&key=AIzaSyCPBVgmtA2WQ1FTry12vDNI4RiP_K-TUCc&callback=ocm.classicMaps.initMap"
		);
		googleScript.setAttribute("async", "async");
		document.head.appendChild(googleScript);
	} else {
		initMap();
	}
}

function initMap() {
	_map();
}

function _map() {
	mapContainer.each(function () {
		var lat = parseFloat($(this).data("lat").toFixed(7));
		var lng = parseFloat($(this).data("lng").toFixed(7));
		var zoomLvl = parseInt($(this).data("zoom")) || 16;

		var uluru = { lat: lat, lng: lng };

		var map = new google.maps.Map(this, {
			zoom: zoomLvl,
			center: uluru,
		});

		var iconBase = "/_ui/responsive/theme-classic/images/marker/";
		var marker = new google.maps.Marker({
			position: uluru,
			map: map,
			icon: iconBase + "map-marker.png",
		});

		map.setOptions({
			styles: styles,
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
		});
	});
}

export { init, initMap };
