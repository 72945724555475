/**
 * Link Masking for SEO
 * https://rene.fyi/customer/account/login/referer/aHR0cHM6Ly9kZXYubGl0dGxlaGlwc3Rhci5jb20vZGUva2luZGVybW9kZS0yLTEwai8/
 */

function init() {
	linkMaskHandler();
}

const _isOpenInNewTab = (event) => event.ctrlKey || event.metaKey || event.currentTarget.getAttribute("target") === "_blank";

const linkMaskHandler = () => {
	$(".js-dynamicLink").on("click", (event) => {
		const path = event.currentTarget.getAttribute("data-goto");

		if (!path) {
			return;
		}

		let url;

		if (!path.startsWith("https://") && !path.startsWith("http://")) {
			url = new URL(path, window.location.origin);
		} else {
			url = new URL(path);
		}

		if (document.location.href === url.href) {
			return;
		}

		if (_isOpenInNewTab(event)) {
			window.open(url.href, "_blank");
			return;
		}

		document.location.href = url.href;
	});
};

export { init };
