/* eslint-disable func-style */
// todo: TUB-17307 suchen wo es noch verwendet wird und testen
// Settings
const forms = [];
let isInitialized = false;
let publickey = null;
let _captchaOptions = null;

// eslint-disable-next-line complexity
function init(captchaOptions) {
	// onload callback der recaptcha api | (captcha.tag)

	_captchaOptions = captchaOptions || {};

	publickey = _captchaOptions.publickey;

	if (!typeof grecaptcha || isInitialized === true || !publickey) {
		return;
	}
	isInitialized = true;

	// render Captcha-Badges // falls erforderlich können weitere Optionen dynamisch übergeben werden.
	for (let i = 0; i < forms.length; i++) {
		const elementId = forms[i].elementId;
		forms[i].widgetId = renderCaptcha(elementId, forms[i].callback);
	}
}

function renderCaptcha(elementID, callback) {
	return grecaptcha.render(elementID, {
		sitekey: publickey,
		callback,
		theme: "light",
		size: "invisible",
		badge: "inline",
	});
}

function setCaptchaId(elementId, callback) {
	const element = document.getElementById(elementId);

	if (!element) {
		return;
	}

	if (_getElement(elementId)) {
		if (!element.hasChildNodes()) {
			_rerender(elementId, callback);
		}
		return;
	}

	if (isInitialized === true) {
		const widgetId = renderCaptcha(elementId, callback);
		forms.push({ elementId, callback, widgetId });
	} else {
		forms.push({ elementId, callback });
	}

	return true;
}

function _rerender(elementId, callback) {
	const widgetId = renderCaptcha(elementId, callback);
	const index = forms.findIndex((element) => element.elementId === elementId);
	forms[index] = { elementId, callback, widgetId };
}

function execute(elementId, actionName = "submit") {
	if (!isInitialized) {
		return false;
	}

	const _element = _getElement(elementId);
	if (!_element) {
		return false;
	}

	const widgetId = _element.widgetId;

	// if executed a second time, recaptcha must be resetted / if there is a response it was executed before
	const response = getResponse(elementId);
	if (response) {
		reset(elementId);
	}

	grecaptcha.execute(widgetId, { action: actionName });
	return true;
}

function getResponse(elementId) {
	if (!isInitialized) {
		return false;
	}

	const widgetId = _getWidgetId(elementId);
	if (widgetId === undefined) {
		return false;
	}

	return grecaptcha.getResponse(widgetId);
}

function reset(elementId) {
	if (!isInitialized) {
		return false;
	}

	const widgetId = _getWidgetId(elementId);
	if (widgetId === undefined) {
		return false;
	}

	grecaptcha.reset(widgetId);
	return true;
}

function getCaptchaElement(elementId) {
	return _getElement(elementId);
}

function _getElement(elementId) {
	let i = 0;
	while (i < forms.length) {
		if (forms[i].elementId === elementId) {
			return forms[i];
		}
		i++;
	}
	return null;
}

function _getWidgetId(elementId) {
	const _element = _getElement(elementId);
	if (!_element) {
		return false;
	}
	return _element.widgetId;
}
//
export { init, setCaptchaId, execute, reset, getResponse, getCaptchaElement };
