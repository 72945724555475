function setCookie(cname, cvalue, exdays) {
	let expires = "";

	if (exdays) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = `; expires=${d.toUTCString()}`;
	}

	document.cookie = `${cname}=${cvalue}${expires};path=/`;
}

function getCookie(cname) {
	const name = `${cname}=`;
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function deleteCookie(cname) {
	setCookie(cname, "", -1);
}

//********************
// Web Storage
//********************
// Use cookies, when Storage doesn't work (i.E. Safari in private mode)

let useStorageAPI = true;
if (typeof localStorage === "object") {
	try {
		localStorage.setItem("storageAPI", "true");
		localStorage.removeItem("storageAPI");
	} catch (e) {
		useStorageAPI = false;
	}
} else {
	useStorageAPI = false;
}
/* eslint "no-unused-vars" : "off"*/
let setLocalStorage = function (label, content) {};
let getLocalStorage = function (label) {};
let setSessionStorage = function (label, content) {};
let getSessionStorage = function (label) {};

// Define function body
if (useStorageAPI === true) {
	setLocalStorage = function (label, content) {
		localStorage.setItem(label, content);
		return `${label}:${content}`;
	};
	getLocalStorage = function (label) {
		return localStorage.getItem(label);
	};
	setSessionStorage = function (label, content) {
		sessionStorage.setItem(label, content);
		return `${label}:${content}`;
	};
	getSessionStorage = function (label) {
		return sessionStorage.getItem(label);
	};
} else {
	setLocalStorage = function (label, content) {
		setCookie(`local_${label}`, content, 365);
		return `${label}:${content}`;
	};
	getLocalStorage = function (label) {
		return getCookie(`local_${label}`);
	};
	setSessionStorage = function (label, content) {
		setCookie(`session_${label}`, content);
		return `${label}:${content}`;
	};
	getSessionStorage = function (label) {
		return getCookie(`session_${label}`);
	};
}

export { setCookie, getCookie, deleteCookie, setLocalStorage, getLocalStorage, setSessionStorage, getSessionStorage };
