let $container;
let $showMore;

function init() {
	$container = $(".js-search-result");
	$showMore = $(".js-show-more");

	if ($container.length < 1) {
		return;
	}
	toggleContent();
}

function toggleContent() {
	$showMore.on("click", function () {
		const theContainer = $(this).parent().find($container);
		theContainer.toggleClass("js-search-result--active");

		if (theContainer.hasClass("js-search-result--active")) {
			$(this).find("button .js-more").hide();
			$(this).find("button .js-less").show();
		} else {
			$(this).find("button .js-less").hide();
			$(this).find("button .js-more").show();
		}
	});
}

export { init };
