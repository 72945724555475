/**
 * ocm.classicMapMarker.js
 *
 * Creates the custom google maps marker with labels.
 *
 * Displays the names/labels of the entries in the CMS cockpit.
 *
 * Can show custom flags defined in the classicMapclassicMapConfig.
 *
 * Sets an active marker if set in the URL. ?active=id
 *
 */

import * as classicMapInfobox from "@classicJS/classicMapInfobox";
import * as classicMapConfig from "@classicJS/classicMapConfig";
import * as utils from "@sharedJS/utils";

//---------------------------
// Config
//---------------------------

function setMapMarker(map, data, _infobox) {
	var markers = [];

	$.each(data, function () {
		var item = this;
		var itemType = item.type;

		var markerContent = _getRichMarkerContent(item, itemType);

		var marker = new RichMarker({
			position: new google.maps.LatLng(item.lat, item.lng),
			map: map,
			flat: true,
			content: markerContent,
			draggable: false,
			width: 0,
			height: 0,
		});

		switch (itemType) {
			case "store":
			case "multi":
				marker.setAnchor(RichMarkerPosition.TOP_LEFT);
				break;
			default:
				marker.setAnchor(RichMarkerPosition.TOP_RIGHT);
		}

		marker.ocmItem = item;

		classicMapInfobox.addInfoboxEventlistener(map, marker, _infobox);

		markers.push(marker);
		return marker;
	});

	return markers;
}

function checkForActiveMarker(activeStoreId, marker, map, _infobox) {
	if (activeStoreId) {
		var activeMarker = marker.filter(function (elem) {
			return elem.ocmItem.id === activeStoreId;
		});
		activeMarker = activeMarker[0];
		var activeMarkerType = activeMarker.ocmItem.type;

		var lat = activeMarker.ocmItem.lat;
		var lng = activeMarker.ocmItem.lng;
		map.setCenter(new google.maps.LatLng(lat, lng));
		map.setZoom(classicMapConfig.getMapConfig().activeZoom);

		classicMapInfobox.setInfoboxContent(map, activeMarker, _infobox);
		classicMapInfobox.setInfoboxPosition(_infobox, activeMarkerType);
		classicMapInfobox.openInfobox(_infobox, map, activeMarker);
	}
}

// Private Functions

function _getRichMarkerContent(item) {
	var flag = "";

	if (item.flag && item.type === "store") {
		flag = "flag-" + item.flag;
	}

	var richMarkerContent;

	var markerLabel;

	////////////////////////////////////////////
	// CONVERT OLD STRCUTURE TO NEW STRUCTURE //
	////////////////////////////////////////////

	if (!item.label) {
		item.label = [""];
	}
	if (!item.anchor) {
		item.anchor = "left";
	}

	if (item.title.constructor !== Array) {
		item.title = [item.title];
	}

	if (item.priority === "1" || item.priority === 1) {
		if (item.type === "multi") {
			item.priority = [17, 18];
		} else {
			item.priority = [17, 18, 19];
		}
	}

	if (item.priority === "2") {
		item.priority = [18, 19];
	}

	if (item.priority === "3") {
		item.priority = [19];
	}

	///////////////////
	// CONVERTER END //
	///////////////////

	if (item.label[0] != "") {
		markerLabel = utils.replaceString(
			item.label.join("<br>"),
			["<%", "%>"],
			['<span class="[ c-maps__marker-inline-flag ]">', "</span>"]
		);
	} else {
		markerLabel = utils.replaceString(
			item.title.join("<br>"),
			["<%", "%>"],
			['<span class="[ c-maps__marker-inline-flag ]">', "</span>"]
		);
	}

	switch (item.type) {
		case "multi":
		case "store":
			richMarkerContent = "";
			if (item.anchor === "left") {
				richMarkerContent += '<div class="[ c-maps__marker-container ]" data-marker="' + item.id + '">';
				richMarkerContent += '<div class="c-maps__marker-point"></div>';
			} else {
				richMarkerContent +=
					'<div class="[ c-maps__marker-container c-maps__marker-container--right ]" data-marker="' + item.id + '">';
				richMarkerContent += '<div class="c-maps__marker-point c-maps__marker-point--right"></div>';
			}
			richMarkerContent +=
				'<div class="[ c-maps__marker ] [ js-maps-marker-label ] ' + flag + '">' + markerLabel + "</div>";
			richMarkerContent += "</div>";
			break;
		case "service":
			richMarkerContent =
				'<div class="[ c-maps__marker-container c-maps__marker-container--icon ]" data-marker="' +
				item.id +
				'"><img src="' +
				(item.icon ? item.icon : "none") +
				'"></div>';
			break;
		default:
			richMarkerContent =
				'<div class="[ c-maps__marker-container c-maps__marker-container--icon ]" data-marker="' +
				item.id +
				'"><div class="[ js-maps-marker-label ]"><img src="/_ui/responsive/common/images/citymap/' +
				item.type +
				'.png"></div></div>';
	}
	return richMarkerContent;
}

export { setMapMarker, checkForActiveMarker };
