/**
 * SEO-Image
 -> Destroy Custom Scrollbar on mobile, re-initialize on desktop
 -> Togggle text-container height on mobile
 */
import * as resize from "@sharedJS/resize";
import * as utils from "@sharedJS/utils";

// DOM
let $seoContainer;
let $readMore;

// DOM Selectors

// Settings
let moreText;
let lessText;

function init() {
	// DOM
	$seoContainer = $(".js-seo-image-container");
	if ($seoContainer.length < 1) {
		return;
	}
	$readMore = $(".js-seo-image-read-more");

	//Settings
	const breakpoints = resize.addListener(classicSeoImage, "breakpoint");
	_initScreen(breakpoints);
	moreText = $("#js-textReadMore").attr("data-moreText");
	lessText = $("#js-textReadMore").attr("data-lessText");

	$readMore.on("click", function () {
		if ($seoContainer.hasClass("is-active")) {
			$seoContainer.removeClass("is-active");
			$readMore.removeClass("is-active");
			$(this).html(moreText);
		} else {
			$seoContainer.addClass("is-active");
			$readMore.addClass("is-active");
			$(this).html(lessText);
		}
	});
}

function _initScrollbar() {
	if (!$seoContainer.hasClass("scrollbar-inner")) {
		if (utils.getLangcode() !== "ar") {
			$seoContainer.addClass("scrollbar-inner");
			$seoContainer.scrollbar();
		}
	}
}

function _destroyScrollbar() {
	$seoContainer.scrollbar("destroy");
	if ($seoContainer.hasClass("scrollbar-inner")) {
		$seoContainer.removeClass("scrollbar-inner");
	}
}

function _initScreen(screenSizes) {
	const myBreakpoint = "m_down";

	if (screenSizes[myBreakpoint]) {
		if (screenSizes[myBreakpoint].isScreenSize) {
			_destroyScrollbar();
		} else {
			_initScrollbar();
		}
	}
}

function updateResize(breakpoints) {
	const myBreakpoint = "m_down";

	if (breakpoints[myBreakpoint] && breakpoints[myBreakpoint].hasChanged) {
		if (breakpoints[myBreakpoint].isScreenSize) {
			_destroyScrollbar();
		} else {
			_initScrollbar();
		}
	}
}

const classicSeoImage = {
	init,
	updateResize,
};

export default classicSeoImage;
