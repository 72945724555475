import { searchClose } from "@sharedJS/closeSearch";

import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

// global Modernizr
// DOM
let $flyouts;

// Settings
let isTouchDevice;

function init() {
	$flyouts = $(".js-flyout");
	isTouchDevice = Modernizr.touchevents;

	if ($flyouts.length) {
		_initFlyout();
	}

	// React to Route Changes
	OcmMessenger.subscribe(messengerPublicationTypes.ROUTE_CHANGE, () => {
		closeFlyout(null);
	});
}

function _initFlyout() {
	$flyouts.each(function () {
		const $this = $(this);
		if (!isTouchDevice && $this.hasClass("js-flyout--delayed")) {
			// no-touch-device and delayed
			$this.hoverIntent({
				over: () => {
					openFlyout($this);
				},
				out: () => {
					closeFlyout($this);
				},
				timeout: 250,
			});
		} else if (!isTouchDevice) {
			// no-touch-device and not delayed
			$this.hover(
				() => {
					openFlyout($this);
				},
				() => {
					closeFlyout($this);
				}
			);
		} else {
			// touch-device
			$this
				.children("a")
				.first()
				.on("touchstart", (event) => {
					const currentTargetHref = event.currentTarget.getAttribute("href");
					if (!$this.hasClass("is-open") && event.cancelable) {
						event.preventDefault();
						openFlyout($this);
					} else if (currentTargetHref === "#" || currentTargetHref === "javascript:void(0)") {
						closeFlyout($this);
					}
				});
			$this.on("touchend", (event) => {
				event.stopPropagation();
				if (
					event.target.classList.contains("c-header-content-navigationlist__linkbutton") &&
					event.target.hasAttribute("onclick")
				) {
					window.setTimeout(closeFlyout, 100, $this);
				}
			});
		}
	});
}

function closeFlyoutOnBodyTouch(event) {
	// timeout to let any kind of default behaviour happen
	if (
		(event.target instanceof HTMLInputElement && event.target.type === "text") ||
		event.target instanceof HTMLButtonElement
	) {
		// abort the following if clicked into an input/text aka searchfield or button
		return;
	}

	window.setTimeout(() => {
		closeFlyout($(".js-flyout.is-open"));
		$("body").off("touchend", closeFlyoutOnBodyTouch);
	}, 100);
}

function addCloseFlyoutOnBodyTouch() {
	// Close Flyout when clicked outside the flyouts
	const $body = $("body");
	$body.off("touchend", closeFlyoutOnBodyTouch);
	$body.on("touchend", closeFlyoutOnBodyTouch);
}

function openFlyout($flyout) {
	if (isTouchDevice) {
		closeFlyout($(".js-flyout.is-open"));
		addCloseFlyoutOnBodyTouch();
	}
	if ($flyout) {
		$flyout.addClass("is-open");
		searchClose();
	}
}

function closeFlyout($flyout) {
	if ($flyout) {
		$flyout.removeClass("is-open");
	} else {
		$flyouts.removeClass("is-open");
	}
}

export { init };
