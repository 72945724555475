/**
 * dateMaskField
 *
 * Enables a ProxyElement with styling and basic validation for filling out hidden Fields for dateDay dateMonth and dateYear, mri
 */

//todo: refactor with moment.js

/* global Cleave*/
import * as formValidate from "@sharedJS/formValidate";
import * as errorMessages from "@sharedJS/errorMessages";

// DOM
let $dateMaskField;
let $dateMaskFieldDate;

// Settings
let datePattern;
let delimiterDate;
let maxAge;
const minAge = 18;
let liveValidation;
let validationUsage;

let dateMaskFieldId;
let errorClass;

// Data
let $targetField;

//Validation
let $isValid;

let vueValidation;

const init = function (isVueValidation = false) {
	$isValid = true; //nothing Entered is ok
	vueValidation = isVueValidation;

	errorClass = "u-error-text";
	$dateMaskField = $(".js-dateMaskField");
	dateMaskFieldId = $dateMaskField.attr("id");
	liveValidation = $dateMaskField.attr("data-live-validation");
	validationUsage = $dateMaskField.attr("data-date-usage");

	if ($dateMaskField.length) {
		//Fields to be filled
		$dateMaskFieldDate = $(".js-dateMaskFieldDate"); //Correspond to ID of $dateMaskField 2 make Connection

		//Initial Settings
		$targetField = $dateMaskFieldDate;
		datePattern = ["d", "m", "Y"];

		delimiterDate = ".";

		//Validate plausible Birthyear
		maxAge = $dateMaskField.attr("data-years-max-age") ? $dateMaskField.attr("data-years-max-age") : 100;

		new Cleave($dateMaskField, {
			date: true,
			datePattern,
			delimiter: delimiterDate,
			onValueChanged(e) {
				if (liveValidation) {
					if ($targetField.length) {
						$targetField.val(e.target.value);
						validate(e.target.value);
					}
				}
			},
		});

		if (!vueValidation) {
			$dateMaskField.on("blur", (e) => {
				validate(e.target.value);
			});
		}
	}
};

function validate(_currValue) {
	$isValid = false;

	if (_currValue.length === 10) {
		if (validationUsage === "birthday") {
			$isValid = _validateBirthDate(_currValue);
		}
	} else if (_currValue.length === 0) {
		$isValid = true;
		_setError(dateMaskFieldId, false, "", errorClass);
	} else {
		_setError(dateMaskFieldId, true, errorMessages.getErrorMessage(dateMaskFieldId, "incomplete"), errorClass);
		$isValid = false;
	}
	return $isValid;
}

function _validateBirthDate(date) {
	const timeStampPart = date.match(/(\d+)/g);
	const timeStampDate = new Date(timeStampPart[2], timeStampPart[1] - 1, timeStampPart[0]);
	const _currentDay = new Date();
	const diff = dateDiffInYears(timeStampDate, _currentDay);

	let isValid = true;
	_setError(dateMaskFieldId, false, "", errorClass);

	if (timeStampDate >= _currentDay || diff >= maxAge || diff < minAge) {
		_setError(dateMaskFieldId, true, errorMessages.getErrorMessage(dateMaskFieldId, "invalid"), errorClass);
		isValid = false;
	}

	return isValid;
}

function isValid() {
	return $isValid;
}

function dateDiffInYears(oldDate, newDate) {
	let diff = newDate.getFullYear() - oldDate.getFullYear();
	if (oldDate.getMonth() > newDate.getMonth()) {
		diff--;
	} else {
		if (oldDate.getMonth() === newDate.getMonth()) {
			if (oldDate.getDate() > newDate.getDate()) {
				diff--;
			}
		}
	}
	return diff;
}

function _setError(elementName, showError, errorMessage, myErrorClass) {
	if (!vueValidation) {
		formValidate.setError(elementName, showError, errorMessage, myErrorClass);
	}
}

export { init, isValid, validate, dateDiffInYears };
