/**
 *  Classic offer shop product slider
 *
 *  - initProductGridSlider is used in upsellingBanner / Reco
 *  - initHeroSlider is used in product-list hero-products
 */

let $productCarouselFull;
let $productCarouselHalf;

function init() {
	$productCarouselFull = $(".js-shop-product-slider-full");
	$productCarouselHalf = $(".js-shop-product-slider-half");

	initShopProductSliderFull();
	initShopProductSliderHalf();
}

function initShopProductSliderFull() {
	$productCarouselFull.slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true,
		prevArrow:
			'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
		nextArrow:
			'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					dots: true,
					speed: 300,
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					dots: true,
					speed: 300,
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
		],
	});
}

function initShopProductSliderHalf() {
	$productCarouselHalf.slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		prevArrow:
			'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
		nextArrow:
			'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					dots: true,
					speed: 300,
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1279,
				settings: {
					dots: false,
					speed: 300,
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	});
}

export { init };
