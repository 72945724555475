/**
 * Go Top Buttons
 */

let isSmoothScrollingSupported;

function init() {
	isSmoothScrollingSupported = CSS.supports("scroll-behavior", "smooth");

	// simple go top button (Shop Home)
	document.querySelectorAll(".js-gotop")?.forEach((el) => {
		el.addEventListener("click", scrollToTop);
	});

	// go top button that fades in/out at a certain scroll position (Classic Offers, Classic Brands)
	const goTopButton = document.querySelector("#js-gotop-button");
	if ("IntersectionObserver" in window && goTopButton) {
		goTopButton.insertAdjacentHTML(
			"afterend",
			`<div class="js-gotop-button-sensor absolute w-full" style="top:${
				document.documentElement.clientHeight * 2
			}px" />`
		);
		goTopButton.addEventListener("click", scrollToTop);

		const sensor = document.querySelector(".js-gotop-button-sensor");
		const observer = new IntersectionObserver(([element]) => {
			element.isIntersecting || element.boundingClientRect.top < element.intersectionRect.top
				? goTopButton.classList.add("is-visible")
				: goTopButton.classList.remove("is-visible");
		});
		observer.observe(sensor);
	}
}

function scrollToTop() {
	/**
	 * scrollTo({ … behavior: "smooth" }) is supported on Mac OS/iOS since April 2021.
	 * If not supported, scrollTo() will scroll the page _directly_ to the specified position.
	 * But if that happens, the Intersection Observer does not respond – causing the Go Top Button to stay visible.
	 * To solve this issue we test support for CSS 'scroll-behavior: smooth',
	 * which is equally well supported as scrollTo({ … behavior: "smooth" })
	 */
	document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
	if (!isSmoothScrollingSupported && this.id === "js-gotop-button") this.classList.remove("is-visible");
}

export { init };
