/*eslint complexity: off*/
import * as utils from "@sharedJS/utils";
import breakpoints from "@sharedJS/breakpoints";

/**
 *
 * @param {string} imageUrl
 * @param {Record<string,string>} params
 * @returns
 */
function addParamsToUrl(imageUrl, params) {
	const isAbsoluteUrl = /^(https?:)?\/\//.test(imageUrl);

	const url = new URL(isAbsoluteUrl ? imageUrl : `https://example.com${imageUrl}`);
	Object.entries(params).forEach(([key, value]) => {
		url.searchParams.set(key, value.toString());
	});
	return url.href.replace("https://example.com", "");
}

export default function (wrapperDiv, settings) {
	let _render;
	let shopCountry;

	const mustacheJS = Mustache;
	let template;

	let _internal = 0;
	let _scarab = 0;
	let _scarabData = [];
	let _internalData = [];
	let _fallback = true;

	try {
		shopCountry = utils.findValueOfPropertyInArray(dataLayer, "Recommendation").pageData.shopCountry;
	} catch (exception) {
		shopCountry = "";
		console.error(exception);
	}

	const _settings = {
		code: settings.code,
		quantity: 6,
		display: "",
		promoText: "Das könnte Ihnen auch gefallen",
		upsellingTemplate: "A",
		$upsellingWrapper: $(wrapperDiv),
		showPromo: true,
		showName: true,
		showLabel: true,
		showPrice: true,
		showSuggestedPrice: true,
		showSale: true,
		linkText: "Jetzt <br>mehr Styles<br> entdecken",
		linkUrl: "",
		showDetailsPage: true,
		showRows: 2,
		showRowsMidScreen: 3,
		showRowsSmallScreen: 4,
		showSlider: true,
		sliderSettings: {
			infinite: false,
			swipe: true,
			swipeToSlide: false,
			slidesToShow: 6,
			slidesToShowMidScreen: 4,
			slidesToShowSmallScreen: 2,
			slidesToScroll: 6,
			slidesToScrollMidScreen: 4,
			slidesToScrollSmallScreen: 2,
		},
		internalProducts: 0,
		internalIndex: null,
		scarabLogic: "TOPICAL",
		customCommands: null,
		showScarab: true,
		forceGender: null,
		internalTimeout: 2000,
		scarabTimeout: 5000,
		discountThreshold: 100,
		fallback: false,
		stickyProducts: false,
		availabilityZone: shopCountry,
	};

	const _loadCustomSettings = function () {
		if (settings.upsellingTemplate !== undefined) {
			_settings.upsellingTemplate = settings.upsellingTemplate;
		}
		if (settings.quantity !== undefined) {
			_settings.quantity = settings.quantity;
		}
		if (settings.display !== undefined) {
			_settings.display = settings.display;
		}
		if (settings.promoText !== undefined) {
			_settings.promoText = settings.promoText;
		}
		if (settings.render !== undefined) {
			_render = settings.render;
		}
		if (settings.showPromo !== undefined) {
			_settings.showPromo = settings.showPromo;
		}
		if (settings.showName !== undefined) {
			_settings.showName = settings.showName;
		}
		if (settings.showLabel !== undefined) {
			_settings.showLabel = settings.showLabel;
		}
		if (settings.showPrice !== undefined) {
			_settings.showPrice = settings.showPrice;
		}
		if (settings.showSuggestedPrice !== undefined) {
			_settings.showSuggestedPrice = settings.showSuggestedPrice;
		}
		if (settings.showSale !== undefined) {
			_settings.showSale = settings.showSale;
		}
		if (settings.linkText !== undefined) {
			_settings.linkText = settings.linkText;
		}
		if (settings.linkUrl !== undefined) {
			_settings.linkUrl = settings.linkUrl;
		}
		if (settings.showDetailsPage !== undefined) {
			_settings.showDetailsPage = settings.showDetailsPage;
		}
		if (settings.showRows !== undefined) {
			_settings.showRows = settings.showRows;
		}
		if (settings.showRowsMidScreen !== undefined) {
			_settings.showRowsMidScreen = settings.showRowsMidScreen;
		}
		if (settings.showRowsSmallScreen !== undefined) {
			_settings.showRowsSmallScreen = settings.showRowsSmallScreen;
		}
		if (settings.showSlider !== undefined) {
			_settings.showSlider = settings.showSlider;
		}
		if (settings.sliderSettings !== undefined) {
			//				_settings.sliderSettings = settings.sliderSettings;
			if (settings.sliderSettings.infinite !== undefined) {
				_settings.sliderSettings.infinite = settings.sliderSettings.infinite;
			}
			if (settings.sliderSettings.swipe !== undefined) {
				_settings.sliderSettings.swipe = settings.sliderSettings.swipe;
			}
			if (settings.sliderSettings.swipeToSlide !== undefined) {
				_settings.sliderSettings.swipeToSlide = settings.sliderSettings.swipeToSlide;
			}
			if (settings.sliderSettings.slidesToShow !== undefined) {
				_settings.sliderSettings.slidesToShow = settings.sliderSettings.slidesToShow;
			}
			if (settings.sliderSettings.slidesToShowMidScreen !== undefined) {
				_settings.sliderSettings.slidesToShowMidScreen = settings.sliderSettings.slidesToShowMidScreen;
			}
			if (settings.sliderSettings.slidesToShowSmallScreen !== undefined) {
				_settings.sliderSettings.slidesToShowSmallScreen = settings.sliderSettings.slidesToShowSmallScreen;
			}
			if (settings.sliderSettings.slidesToScroll !== undefined) {
				_settings.sliderSettings.slidesToScroll = settings.sliderSettings.slidesToScroll;
			}
			if (settings.sliderSettings.slidesToScrollMidScreen !== undefined) {
				_settings.sliderSettings.slidesToScrollMidScreen = settings.sliderSettings.slidesToScrollMidScreen;
			}
			if (settings.sliderSettings.slidesToScrollSmallScreen !== undefined) {
				_settings.sliderSettings.slidesToScrollSmallScreen = settings.sliderSettings.slidesToScrollSmallScreen;
			}
		}
		if (settings.internalProducts !== undefined) {
			_settings.internalProducts = settings.internalProducts;
		}
		if (settings.internalIndex !== undefined) {
			_settings.internalIndex = settings.internalIndex;
		}
		if (settings.scarabLogic !== undefined) {
			_settings.scarabLogic = settings.scarabLogic;
		}
		if (settings.customCommands !== undefined) {
			_settings.customCommands = settings.customCommands;
		}
		if (settings.showScarab !== undefined) {
			_settings.showScarab = settings.showScarab;
		}
		if (settings.forceGender !== undefined) {
			_settings.forceGender = settings.forceGender;
		}
		if (settings.internalTimeout !== undefined) {
			_settings.internalTimeout = settings.internalTimeout;
		}
		if (settings.scarabTimeout !== undefined) {
			_settings.scarabTimeout = settings.scarabTimeout;
		}
		if (settings.discountThreshold !== undefined) {
			_settings.discountThreshold = settings.discountThreshold;
		}
		if (settings.fallback !== undefined) {
			_settings.fallback = settings.fallback;
		}
		if (settings.stickyProducts !== undefined) {
			_settings.stickyProducts = settings.stickyProducts;
		}
		if (settings.availabilityZone !== undefined) {
			_settings.availabilityZone = settings.availabilityZone;
		}
	};

	const _renderTemplate = function ($container, gridTemplate, list) {
		if (gridTemplate.length) {
			template = gridTemplate;
			//tell Mustache.js to iterate through the JSON and insert the data into the HTML template
			const output = mustacheJS.render(template, list);
			//append the HTML template to the DOM
			$container.append(output);
		}
	};

	_render = function (data) {
		let $discountBox;
		let promoHtml;
		let $productHeadline;
		let $productLabel;
		let $productName;
		let $productPrice;
		let $productCurrentPrice;
		let $productSuggestedPrice;
		let $productSale;
		let $upsellingProductLayer;
		let $upsellingProductSlider;
		let $availabilityzone;
		let $upsellingTemplate;
		let $upsellingTemplateMobile;
		let $upsellingProductLayerMobile;

		if (_settings.availabilityZone === "at") {
			$availabilityzone = "at";
		} else if (_settings.availabilityZone === "ch") {
			$availabilityzone = "ch";
		} else if (_settings.availabilityZone === "de") {
			$availabilityzone = "de";
		}

		const scarabItemAttr = _settings.showScarab ? 'data-scarabItem="{{item}}"' : "";

		$(data).each((index, entry) => {
			if (typeof entry.c_sale_at !== "boolean") {
				entry.c_sale_at = entry.c_sale_at === "true" ? true : entry.c_sale_at === "false" ? false : entry.c_sale_at;
				entry.c_sale_de = entry.c_sale_de === "true" ? true : entry.c_sale_de === "false" ? false : entry.c_sale_de;
				entry.c_sale_ch = entry.c_sale_ch === "true" ? true : entry.c_sale_ch === "false" ? false : entry.c_sale_ch;
			}
		});

		/*********************** Product Item Template **************************/
		const $upsellingTemplateProduct =
			`<div class="c-product-item u-ph-small js-product-item"${scarabItemAttr}>` +
			`<a href="{{link_${$availabilityzone}}}" data-productCode="{{item}}" class="u-relative u-block u-text-center" data-productid="{{item}}">` +
			`{{#c_sale_${$availabilityzone}}}<div class="u-relative js-product-sale">` +
			`<p class="o-badge o-badge--right c-badge c-badge__border c-badge--sale">sale</p>` +
			`</div>{{/c_sale_${$availabilityzone}}}` +
			`<img alt="{{c_label_${$availabilityzone}}} - {{title}}" class="c-product-item__image lazyload" data-src="{{image}}" />` +
			`<div class="c-product-item__body">` +
			`<div class="o-headline-product--small u-mb-tiny js-product-headline">` +
			`<span class="o-headline-product__top js-product-label"><strong>{{c_label_${$availabilityzone}}}</strong></span>` +
			`<span class="o-headline-product__bottom js-product-name u-hide-m-down"> {{title}}</span>` +
			`</div>` +
			`<p class="c-product-item__price js-product-price">` +
			`{{#c_msrpformatted_${$availabilityzone}}}<span class="c-product__old-price u-mr-tiny js-product-suggested-price">{{c_msrpformatted_${$availabilityzone}}}</span>{{/c_msrpformatted_${$availabilityzone}}}` +
			`{{#c_priceformatted_${$availabilityzone}}}<span class="c-product__current-price js-product-current-price">{{c_priceformatted_${$availabilityzone}}}</span>{{/c_priceformatted_${$availabilityzone}}}` +
			`</p>` +
			`{{#c_discountformatted_${$availabilityzone}}}<p class="c-info-box c-info-box--red js-discount-box" data-discount="{{c_discount_${$availabilityzone}}}">{{c_discountformatted_${$availabilityzone}}}</p>{{/c_discountformatted_${$availabilityzone}}}` +
			`</div>` +
			`</a>` +
			`</div>`;

		/*********************** Template Types **************************/
		if (_settings.upsellingTemplate === "A") {
			/** Type A - x products **/
			$upsellingTemplate = `<div class="js-product-item-wrapper">${$upsellingTemplateProduct}</div>`;

			_settings.$upsellingWrapper.append(
				'<div class="o-page-wrap"><div class="c-upselling js-product-grid js-product-grid-slider u-mb-l-up u-mb-large-l-down"></div></div>'
			);
			$upsellingProductSlider = _settings.$upsellingWrapper.find(".js-product-grid-slider");
			$upsellingProductLayer = _settings.$upsellingWrapper.find(".js-product-grid");

			// Products
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayer, $upsellingTemplate, entry);
			});

			// Headline
			if (_settings.showPromo) {
				promoHtml = `<h3 class="o-headline"><span>${_settings.promoText}</span></h3>`;
				$upsellingProductLayer.parent().prepend(promoHtml);
			}

			// to determine the count of rows for a banner
			const columns = Math.floor(_settings.quantity / _settings.showRows);
			const columnsMidScreen = Math.floor(_settings.quantity / _settings.showRowsMidScreen);
			const columnsSmallScreen = Math.floor(_settings.quantity / _settings.showRowsSmallScreen);

			const columnIndex = Modernizr.mq(breakpoints["l_up"])
				? columns
				: Modernizr.mq(breakpoints["m"])
				? columnsMidScreen
				: Modernizr.mq(breakpoints["m_down"])
				? columnsSmallScreen
				: 0;

			if (_settings.showSlider === false) {
				$upsellingProductLayer
					.find(`.js-product-item-wrapper:lt(${_settings.quantity - columnIndex})`)
					.addClass("u-mb");

				$upsellingProductLayer.find(".js-product-item-wrapper").addClass(`u-1/${columns}`);
				$upsellingProductLayer.find(".js-product-item-wrapper").addClass(`u-1/${columnsMidScreen}-m`);
				$upsellingProductLayer.find(".js-product-item-wrapper").addClass(`u-1/${columnsSmallScreen}-m-down`);
			}
		} else if (_settings.upsellingTemplate === "B") {
			/** Type B - 1 product **/
			$upsellingTemplate = $upsellingTemplateProduct;
			$upsellingProductLayer = _settings.$upsellingWrapper;

			// Products
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayer, $upsellingTemplate, entry);
			});
		} else if (_settings.upsellingTemplate === "C") {
			/** Type C - 4 products **/
			$upsellingTemplate = `<div class="o-layout__item u-1/2">${$upsellingTemplateProduct}</div>`;

			$upsellingProductLayer = _settings.$upsellingWrapper;
			$upsellingProductLayer.addClass("o-layout");

			// Products
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayer, $upsellingTemplate, entry);
			});

			if (_settings.showPromo) {
				promoHtml = `<h3 class="o-headline u-hide-m-down"><span class="js-style-text">${_settings.promoText}</span></h3>`;
				$upsellingProductLayer.parent().prepend(promoHtml);
			}

			// for a module with 1 keypiece and 2 styles (each styles with 4 product)
			// in order to copy the style text from the headline to the mobile version
			// the classes js-thumb-left and js-thumb-right have to available in the html in the cockpit
			const styleText = $(".js-style-text");
			const stylesNavSlider = $(".js-upselling-styles-nav-slider");
			const styleThumbLeft = $(".js-thumb-left");
			const styleThumbRight = $(".js-thumb-right");

			if (stylesNavSlider.length) {
				const textLeft = styleText.first().text();
				const textRight = styleText.last().text();

				styleThumbLeft.text(textLeft);
				styleThumbRight.text(textRight);
			}
		} else if (_settings.upsellingTemplate === "D") {
			/** Type D - 6 products **/
			$upsellingTemplate = `<div class="o-layout__item u-1/3 u-1/2-m-down">${$upsellingTemplateProduct}</div>`;

			$upsellingProductLayer = _settings.$upsellingWrapper;
			$upsellingProductLayer.addClass("o-layout");

			// Products
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayer, $upsellingTemplate, entry);
			});

			if (_settings.showPromo) {
				promoHtml = `<h3 class="o-headline"><span>${_settings.promoText}</span></h3>`;
				$upsellingProductLayer.parent().prepend(promoHtml);
			}
		} else if (_settings.upsellingTemplate === "E") {
			/** Type E - link text with x products **/
			$upsellingTemplate = `<div class="o-layout__item">${$upsellingTemplateProduct}</div>`;

			$upsellingTemplateMobile = `<div class="o-layout__item u-1/2">${$upsellingTemplateProduct}</div>`;

			_settings.$upsellingWrapper.append(
				`${
					'<div class="o-page-wrap c-upselling c-upselling--list">' +
					'<div class="o-layout u-hide-m-down">' +
					'<div class="o-layout__item u-5/6">' +
					'<div class="o-layout js-upselling-list-slider js-upselling-list-content">' +
					"</div>" +
					'</div><!-- --><div class="o-layout__item u-1/6">' +
					'<div class="c-upselling__link-wrapper">' +
					'<div class="u-aspect-ratio">' +
					'<div class="c-upselling__link-inner">' +
					'<a href="'
				}${_settings.linkUrl}" class="o-arrow-link">${_settings.linkText}</a>` +
					`</div>` +
					`</div>` +
					`</div>` +
					`</div>` +
					`</div> ` +
					`<div class="o-layout u-hide-m-up js-upselling-list-content-mobile js-link-mobile">` +
					`</div>` +
					`</div>`
			);

			$upsellingProductLayer = _settings.$upsellingWrapper.find(".js-upselling-list-content");
			$upsellingProductLayerMobile = _settings.$upsellingWrapper.find(".js-upselling-list-content-mobile");

			$upsellingProductSlider = _settings.$upsellingWrapper.find(".js-upselling-list-slider");

			// Products
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayer, $upsellingTemplate, entry);
			});

			// Products for mobile content
			$(data).each((index, entry) => {
				_renderTemplate($upsellingProductLayerMobile, $upsellingTemplateMobile, entry);
			});

			// Link - appending to the last position in mobile view
			const linkHtml =
				`${
					'<div class="o-layout__item u-1/2">' +
					'<div class="c-upselling__link-wrapper">' +
					'<div class="u-aspect-ratio">' +
					'<div class="c-upselling__link-inner">' +
					'<a href="'
				}${_settings.linkUrl}" class="o-arrow-link">${_settings.linkText}</a>` +
				`</div>` +
				`</div>` +
				`</div>` +
				`</div>`;
			$(".js-link-mobile").append(linkHtml);
		}

		if ($upsellingProductLayer.length) {
			$productHeadline = $upsellingProductLayer.find(".js-product-headline");
			$productLabel = $upsellingProductLayer.find(".js-product-label");
			$productName = $upsellingProductLayer.find(".js-product-name");

			$productPrice = $upsellingProductLayer.find(".js-product-price");
			$productCurrentPrice = $upsellingProductLayer.find(".js-product-current-price");
			$productSuggestedPrice = $upsellingProductLayer.find(".js-product-suggested-price");

			$productSale = $upsellingProductLayer.find(".js-product-sale");

			$discountBox = $upsellingProductLayer.find(".js-discount-box");

			if (!_settings.showSale) {
				$productSale.remove();
			}

			if (!_settings.showLabel && !_settings.showName) {
				$productHeadline.remove();
			} else if (!_settings.showLabel) {
				$productLabel.remove();
			} else if (!_settings.showName) {
				$productName.remove();
			}

			if (!_settings.showSuggestedPrice && !_settings.showPrice) {
				$productPrice.remove();
			} else if (!_settings.showPrice) {
				$productCurrentPrice.remove();
			} else if (!_settings.showSuggestedPrice) {
				$productSuggestedPrice.remove();
			}

			$discountBox.each(function () {
				const $this = $(this);

				if ($this.attr("data-discount") > _settings.discountThreshold) {
					$this.show();
				} else {
					$this.hide();
				}
			});
		}

		if (_settings.showSlider === true) {
			$upsellingProductSlider.slick({
				settings: "unslick",
				prevArrow:
					'<a href="javascript:void(0);" class="c-slider-control c-slider-control--left js-slider-control-left"></a>',
				nextArrow:
					'<a href="javascript:void(0);" class="c-slider-control c-slider-control--right js-slider-control-right"></a>',
				infinite: _settings.sliderSettings.infinite,
				swipe: _settings.sliderSettings.swipe,
				swipeToSlide: _settings.sliderSettings.swipeToSlide,
				touchMove: true,
				speed: 800,
				slidesToShow: _settings.sliderSettings.slidesToShow,
				slidesToScroll: _settings.sliderSettings.slidesToScroll,
				arrows: !Modernizr.touchevents,
				responsive: [
					{
						breakpoint: 1023,
						settings: {
							dots: true,
							speed: 300,
							slidesToShow: _settings.sliderSettings.slidesToShowMidScreen,
							slidesToScroll: _settings.sliderSettings.slidesToScrollMidScreen,
						},
					},
					{
						breakpoint: 720,
						settings: {
							dots: true,
							speed: 300,
							slidesToShow: _settings.sliderSettings.slidesToShowSmallScreen,
							slidesToScroll: _settings.sliderSettings.slidesToScrollSmallScreen,
						},
					},
				],
			});
		}
	};

	_loadCustomSettings();

	this.load = function () {
		if (shopCountry === "") {
			return;
		}

		if (_settings.display !== "") {
			// to execute script for different resolutions
			if (Modernizr.mq(breakpoints[_settings.display])) {
				if (_settings.internalProducts !== 0) {
					_loadInternalProducts(this);
				}
				if (_settings.showScarab === true) {
					_loadScarabProducts(this);
				}
			}
		} else {
			if (_settings.internalProducts !== 0) {
				_loadInternalProducts(this);
			}
			if (_settings.showScarab === true) {
				_loadScarabProducts(this);
			}
		}

		//For local testing uncomment the go command
		// ScarabQueue.push(['go']);
	};

	const _loadInternalProducts = function (upsellingBanner) {
		$.ajax({
			url: utils.getUrl(`/upsellingbanner?code=${_settings.code}&forceGender=${_settings.forceGender}`),
			dataType: "json",
			timeout: _settings.internalTimeout,
		})
			.done((data) => {
				upsellingBanner.requestFinished(
					"internal",
					data?.map((product) => ({ ...product, image: addParamsToUrl(product.image, { width: 424 }) })) ?? data
				);
			})
			.fail(() => {
				upsellingBanner.requestFinished("internal", []);
			});
	};
	const _loadScarabProducts = function (upsellingBanner) {
		if (_settings.customCommands != null) {
			for (const command in _settings.customCommands) {
				eval(_settings.customCommands[command]);
			}
		}
		if (_settings.fallback) {
			window.setTimeout(() => {
				_handleFallback(upsellingBanner);
			}, _settings.scarabTimeout);
		}
		if (typeof ScarabQueue !== "undefined") {
			//availability zone
			ScarabQueue.push(["availabilityZone", _settings.availabilityZone]);

			ScarabQueue.push([
				"recommend",
				{
					logic: _settings.scarabLogic,
					containerId: $(wrapperDiv).attr("id"),
					limit: _settings.quantity + 20,
					success(data) {
						_fallback = false;
						if (_scarab !== 1) {
							upsellingBanner.requestFinished("scarab", data.page.products);
							//window.myScarab = window.myScarab || {}
							//window.myScarab[_settings.scarabLogic] = data.page.products;
						}
					},
				},
			]);
		}
	};

	const _handleFallback = function (upsellingBanner) {
		if (_fallback) {
			_loadInternalProducts(upsellingBanner);
			_scarab = 1;
			console.warn("Scarab timeout - falling back to internal Products!");
		}
	};

	this.requestFinished = function (dataSource, data) {
		if (dataSource === "internal") {
			if (data != null) {
				_internalData = data.slice(
					0,
					_settings.internalProducts === 0 ? _settings.quantity : _settings.internalProducts
				);
			}
			_internal = 1;
		} else if (dataSource === "scarab") {
			_scarabData = data;
			_scarab = 1;
		}
		if ((_internal === 1 || _settings.internalProducts === 0) && (_scarab === 1 || _settings.showScarab === false)) {
			const _renderData = _mergeProducts(_internalData, _scarabData);
			if (_renderData.length > 0) {
				_render(_renderData);
			}
		}
	};

	const _mergeProducts = function (internalProducts, recommendedProducts) {
		let cartItems = [];
		dataLayer = dataLayer || [];
		const recommendation = utils.findValueOfPropertyInArray(dataLayer, "Recommendation");
		if (
			Object.prototype.hasOwnProperty.call(recommendation, "Recommendation") &&
			recommendation.Recommendation.productDataList &&
			!_settings.stickyProducts
		) {
			cartItems = recommendation.Recommendation.productDataList;
		}

		const cartFilteredInternalProducts = _findSetDifference(cartItems, internalProducts);
		const cartFilteredRecommendedProducts = _findSetDifference(cartItems, recommendedProducts);
		const cartFilteredRecommenedProductsWithouttInternalProducts = _findSetDifference(
			cartFilteredInternalProducts,
			cartFilteredRecommendedProducts
		);

		if (internalProducts.length === 0) {
			return cartFilteredRecommendedProducts.slice(0, _settings.quantity);
		} else if (recommendedProducts.length === 0) {
			return cartFilteredInternalProducts;
		}

		const resultList = [];

		for (let i = 0; i < _settings.quantity; i++) {
			if (_settings.internalIndex.length > 0 && _settings.internalIndex[0] === i) {
				const temp = cartFilteredInternalProducts.shift();
				if (temp !== undefined) {
					resultList.push(temp);
				} else if (!_pushIfDefined(cartFilteredRecommenedProductsWithouttInternalProducts, resultList)) {
					break;
				}
				_settings.internalIndex.shift();
			} else if (!_pushIfDefined(cartFilteredRecommenedProductsWithouttInternalProducts, resultList)) {
				break;
			}
		}
		return resultList;
	};

	const _pushIfDefined = function (list, resultList) {
		const temp = list.shift();
		if (temp !== undefined) {
			resultList.push(temp);
			return true;
		}
		return false;
	};

	const _findSetDifference = function (a, b) {
		const filteredList = [];
		for (const productB in b) {
			let isDuplicate = false;
			// productID-Property to compare depends on source: dataLayer-Array (cart) -> Property: styleProductID <=> scarab+internal-Array -> Property: item
			const articleNo = b[productB].styleProductId ? b[productB].styleProductId : b[productB].item;
			for (const productA in a) {
				const article2No = a[productA].styleProductId ? a[productA].styleProductId : a[productA].item;
				if (articleNo == article2No) {
					isDuplicate = true;
					break;
				}
			}
			if (!isDuplicate) {
				filteredList.push(b[productB]);
			}
		}
		return filteredList;
	};
}
