/* contactForm
 *
 * Description
 */

import * as formValidate from "@sharedJS/formValidate";
import * as captcha from "@sharedJS/formCaptcha";
import * as utils from "@sharedJS/utils";

// DOM
let $contactForm;

// DOM Element Names / IDs
let captchaElementID;

// Settings
let waitingForResponse;

function init() {
	// DOM
	$contactForm = $("#contact_form");
	if ($contactForm.length === 0) {
		return;
	}

	// Settings
	waitingForResponse = false;

	// Contact Form
	if ($contactForm.length) {
		$contactForm.attr("novalidate", "novalidate");
		$contactForm.on(
			"submit",
			null,
			{ callback: checkBeforeSubmitForm, errorCallback: null },
			formValidate.validateFormOnSubmit
		);
		$contactForm.on("change", "input, select, textarea", { errorCallback: null }, formValidate.validateElementOnChange);
	}

	// setCaptcha
	captchaElementID = "captchaContact";
	captcha.setCaptchaId(captchaElementID, sendForm);
}

function checkBeforeSubmitForm(target, isValid) {
	// Formvalidation
	if (!isValid) {
		return;
	}

	// Captcha
	const hasCaptcha = captcha.execute(captchaElementID, "contactForm");
	if (!hasCaptcha) {
		sendForm();
	}
}

function sendForm() {
	const formData = {};

	const elements = $contactForm[0].elements;
	const length = elements.length;

	const url = $contactForm.attr("action");

	for (let i = 0; i < length; i++) {
		const element = elements[i];
		const elementID = $(element).attr("name");
		if (elementID) {
			if ($(element).attr("type") === "checkbox") {
				formData[elementID] = $(element).is(":checked");
			} else {
				formData[elementID] = $(element).val();
			}
		}
	}

	if (waitingForResponse === false) {
		$.ajax({
			url,
			type: "POST",
			dataType: "json",
			data: formData,
		})
			.done((response) => {
				_handleResponse(response);
			})
			.fail((error) => {
				console.error(error);
			})
			.always(() => {
				waitingForResponse = false;
			});
		waitingForResponse = true;
	}
}

//eslint-disable-next-line
function _handleResponse(response) {
	if (!$.isEmptyObject(response)) {
		if (response.result === "failure") {
			if (response.title != null) {
				utils.setErrorMessage("title", response.title);
			} else {
				utils.setErrorMessage("title", "");
			}
			if (response.firstname != null) {
				utils.setErrorMessage("firstname", response.firstname);
			} else {
				utils.setErrorMessage("firstname", "");
			}
			if (response.lastname != null) {
				utils.setErrorMessage("lastname", response.lastname);
			} else {
				utils.setErrorMessage("lastname", "");
			}
			if (response.subject != null) {
				utils.setErrorMessage("subject", response.subject);
			} else {
				utils.setErrorMessage("subject", "");
			}
			if (response.message != null) {
				utils.setErrorMessage("message", response.message);
			} else {
				utils.setErrorMessage("message", "");
			}
			if (response.otherError != null) {
				// e.g. captcha-error
				utils.setErrorMessage("otherError", response.otherError);
			} else {
				utils.setErrorMessage("otherError", "");
			}
		} else {
			$contactForm.addClass("is-hidden");
			$("#successMessage").removeClass("is-hidden");
		}
	}
}

export { init };
