import * as rest from "@sharedJS/rest";
import * as restErrorHandler from "@sharedJS/restErrorHandler";
import * as formValidate from "@sharedJS/formValidate";
import * as dateMaskField from "@sharedJS/dateMaskField";
import * as errorMessages from "@sharedJS/errorMessages";

const DOM = {
	registerConfirmationForm: null,
	keyCode: null,
	titleCode: null,
	countryCode: null,
	birthdate: null,
};

const Ids = {
	registerConfirmationFormId: "#registerConfirmationForm",
	inputKeyCodeId: "keyCode", //set Error takes only ID without #
	inputTitleCodeId: "#gender",
	inputCountryCodeId: "#originCountryCode",
	inputBirthdateId: "#birthdate",
	deliveryNoteId: "js-delivery-note",
};

const Settings = {
	errorClass: "u-error-text is-not-valid",
};

const State = {
	isValid: false,
};

const init = () => {
	if (!_initDom()) {
		return;
	}
	_bindActions();
};

const _initDom = () => {
	if (!$(Ids.registerConfirmationFormId).length) {
		return false;
	}
	DOM.registerConfirmationForm = $(Ids.registerConfirmationFormId);
	DOM.keyCode = $(`#${Ids.inputKeyCodeId}`);
	DOM.titleCode = $(Ids.inputTitleCodeId);
	DOM.countryCode = $(Ids.inputCountryCodeId);
	DOM.birthdate = $(Ids.inputBirthdateId);
	DOM.deliveryNote = document.getElementById(Ids.deliveryNoteId);
	return true;
};

const _bindActions = () => {
	DOM.registerConfirmationForm.attr("novalidate", "novalidate");
	DOM.registerConfirmationForm.on("change", "select", (event) => {
		formValidate.validateElement(event.currentTarget, Settings.errorClass);

		if (event.currentTarget.id === "originCountryCode") {
			if (!["de", "at", "ch", ""].includes(event.currentTarget.value.toLowerCase())) {
				DOM.deliveryNote.classList.remove("u-hide");
			} else {
				DOM.deliveryNote.classList.add("u-hide");
			}
		}
	});
	if (DOM.keyCode.length) {
		DOM.keyCode.on("blur", () => {
			_precheckKeyCode();
		});
	}
	DOM.registerConfirmationForm.on("submit", (event) => {
		event.preventDefault();
		const isValid = [];
		isValid.push(formValidate.validateElement(DOM.titleCode, Settings.errorClass));
		isValid.push(formValidate.validateElement(DOM.countryCode, Settings.errorClass));
		if (DOM.birthdate.length) {
			isValid.push(dateMaskField.isValid());
		}
		State.isValid = !isValid.includes(false);

		if (State.isValid) {
			_startRegisterConfirmationAction();
		} else {
			_scrollToFirstError();
			return false;
		}
	});
};

const _startRegisterConfirmationAction = () => {
	const context = {
		form: DOM.registerConfirmationForm,
		callback: _startRegisterConfirmationAction,
	};
	rest.submitForm(context);
};

const _precheckKeyCode = () => {
	let isValid = false;
	const keyCodeVal = DOM.keyCode.val();
	const url = DOM.keyCode.attr("data-action-url");
	const data = `keyCode=${keyCodeVal}`;
	console.log(keyCodeVal);
	formValidate.setError(Ids.inputKeyCodeId, false, "", Settings.errorClass);

	if (keyCodeVal.length > 0 && keyCodeVal.length < 8) {
		formValidate.setError(
			Ids.inputKeyCodeId,
			true,
			errorMessages.getErrorMessage(Ids.inputKeyCodeId, "short"),
			Settings.errorClass
		);
		isValid = false;
	} else if (keyCodeVal.length === 8) {
		$.ajax({
			url,
			data,
			global: false,
			type: "POST",
			success() {
				console.log("Number: VALID");
				isValid = true;
			},
			error(responseData) {
				console.log("Number: INVALID");
				restErrorHandler.handleError(responseData, Ids.registerConfirmationFormId, _precheckKeyCode);
				isValid = false;
			},
		});
	} else if (keyCodeVal.length === 0) {
		formValidate.setError(Ids.inputKeyCodeId, false, "", Settings.errorClass);
		isValid = true;
	}
	return isValid;
};

const _scrollToFirstError = () => {
	const error = DOM.registerConfirmationForm.find("select.u-error, input.u-error").slice(0, 1);

	if (error.length && error[0].offsetTop) {
		$("html, body").animate({ scrollTop: parseInt(error[0].offsetTop - window.innerHeight / 4, 10) }, 600);
	}
};

export { init };
