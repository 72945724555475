/**
 * Handle auto-formatting for form-elements like checkboxes, radio-Buttons, select-Boxen
 */

function init() {
	// Format Checkboxen
	$("input.js-formElement:checkbox")
		.not(".c-checkbox__origin")
		.removeClass("js-form")
		.addClass("c-checkbox__origin")
		.wrap(jQuery('<span class="c-checkbox"></span>'))
		.after(jQuery("<span />", { class: "c-checkbox__selection" }));

	// Format Radio-Buttons
	$("input.js-formElement:radio")
		.not(".c-radio__origin")
		.removeClass("js-form")
		.addClass("c-radio__origin")
		.wrap(jQuery('<span class="c-radio"></span>'))
		.after(jQuery("<span />", { class: "c-radio__selection" }));

	// Format SelectBox
	$("select.js-formElement").each(function () {
		const $select = $(this);
		$select
			.not(".c-select__origin")
			.addClass("c-select__origin")
			.after(
				jQuery("<div />", { class: "js-dropdown c-dropdown" }).html(
					`<a href="javascript:void(0)" class="[ js-dropdown-label c-dropdown__label ]"> <span>${$select
						.find("option:selected")
						.text()}</span></a>`
				)
			);

		$(this)
			.next()
			.find(".js-dropdown-label")
			.after(jQuery("<ul />", { class: " scrollbar-inner js-dropdown-list c-dropdown__list " }));

		const dropdownList = $(this).next().find(".js-dropdown-list");

		dropdownList.wrap(jQuery('<div class="js-dropdown-wrap is-hidden"></div>'));

		$(this)
			.find("option")
			.each(function () {
				if ($(this).is(":selected")) {
					dropdownList.append(`<li class="is-selected">${$(this).text()}</li>`);
				} else {
					dropdownList.append(`<li>${$(this).text()}</li>`);
				}
			});
	});
}

export { init };
