let $dropDownList;
let $dropDownLabel;
let $dropDownItem;

function init() {
	$dropDownLabel = $(".js-dropdown-label");
	$dropDownList = $(".js-dropdown-list");
	$dropDownItem = $(".js-dropdown-list li");

	_dropDownLabelclick();
	_dropDownItemClick();
	_closeDropDownList();
}

function _dropDownItemClick() {
	$dropDownItem.on("click", function () {
		const $this = $(this);
		const $thisItems = $this.siblings();
		const $thisWrap = $this.closest(".js-dropdown-wrap");
		const $thisLabel = $thisWrap.siblings(".js-dropdown-label");

		$thisItems.removeClass("is-selected");
		$this.addClass("is-selected");
		$thisLabel.find("span").html($this.html());
		$thisWrap.addClass("is-hidden");
		$thisLabel.removeClass("is-open");

		_updateSelectValue($this);
		_changeInputImageDropDown($this);
	});
}

function _dropDownLabelclick() {
	$dropDownLabel.on("click", function () {
		const $current = $(this);
		const $currentWrap = $current.siblings(".js-dropdown-wrap");
		$currentWrap.addClass("current");
		$dropDownLabel.each(function () {
			const $this = $(this);
			const $thisWrap = $this.siblings(".js-dropdown-wrap");
			if ($thisWrap.hasClass("current")) {
				$current.toggleClass("is-open");
				$currentWrap.toggleClass("is-hidden");
				$currentWrap.removeClass("current");
			} else {
				if (!$thisWrap.hasClass("is-hidden")) {
					$this.addClass("is-open");
					$thisWrap.addClass("is-hidden");
				}
			}
		});
	});
}

function _closeDropDownList() {
	$(document).click((event) => {
		if (
			!$(event.target).is(".js-dropdown-label") &&
			!$(event.target).is(".js-dropdown-list") &&
			!$(event.target).is(".js-dropdown-label span")
		) {
			if ($dropDownList.length) {
				$dropDownList.each(function () {
					if (!$(this).closest(".js-dropdown-wrap").hasClass("is-hidden")) {
						$(this).closest(".js-dropdown-wrap").addClass("is-hidden");
						$(this).closest(".js-dropdown-wrap").prev().removeClass("is-open");
					}
				});
			}
		}
	});
}

function _updateSelectValue($item) {
	// select.js-formElement
	const indexItem = $item.index();
	if ($item.closest(".js-dropdown").prev().is("select")) {
		const $option = $item.closest(".js-dropdown").prev().find("option");
		$option.attr("selected", false);
		$option.eq(indexItem).prop("selected", true);
		$option.eq(indexItem).attr("selected", true);

		if ($item.closest("#my_profile")) {
			$item.closest(".js-dropdown").prev().change();
		}
	}
}

function _changeInputImageDropDown($item) {
	if ($item.closest(".js-dropdown").hasClass("c-dropdown-image")) {
		const value = $item.data("value");
		$item.closest(".js-dropdown").find("input").val(value).trigger("change");
	}
}

export { init };
