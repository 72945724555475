/**
 * Category Bar
 *
 * Tab function and Accordion for category bar in footer
 */

import breakpoints from "@sharedJS/breakpoints";

let categoryBarItem;
let categoryBarContent;

//settings
let flagmup;
let flagmdown;

function init() {
	categoryBarItem = $(".js-category-bar-item");
	categoryBarContent = $(".js-category-bar-content");

	flagmup = true;
	flagmdown = true;

	if (Modernizr.mq(breakpoints["m_up"])) {
		if (flagmup !== false) {
			if (categoryBarItem.length) {
				_showCategoryContent();
			}
		}
		flagmup = false;
		flagmdown = true;
	} else {
		flagmup = true;
		flagmdown = false;
	}

	$(window).resize(() => {
		if (Modernizr.mq(breakpoints["m_up"])) {
			if (flagmup !== false) {
				if (categoryBarItem.length) {
					_showCategoryContent();
				}
			}
			flagmup = false;
			flagmdown = true;
		} else {
			if (flagmdown !== false) {
				categoryBarContent.removeAttr("style");
			}
			flagmup = true;
			flagmdown = false;
		}
	});
}

function _showCategoryContent() {
	categoryBarItem.off("click").click(function (e) {
		e.preventDefault();
		const $thisItem = $(this);

		if ($thisItem.parent().hasClass("is-active")) {
			$thisItem.parent().removeClass("is-active");
			$thisItem.parent().find(categoryBarContent).slideUp("slow");
		} else {
			categoryBarItem.parent().removeClass("is-active");
			categoryBarContent.hide();
			$thisItem.parent().addClass("is-active");
			$thisItem.parent().find(categoryBarContent).slideDown("slow");
		}
	});
}

export { init };
