import * as brandslider from "./slider_brands";
import * as fullWidthTeaserSlider from "./slider_fullwidthteaser";

let rotationTeaser;
let socialTeaser;
let inspirationTeaser;
let consultantTeaser;
let fadeTeaser;
let testimonialBox;
let sliderCentermode;
let smallslides;
let bigslides;
let midcount;
let autoplay;
let arrow;
let basicSlider;
let categorySlider;
let teaserSliderContainerComponent;

function init() {
	rotationTeaser = $(".js-rotation-teaser");
	socialTeaser = $(".js-social-slider");
	fadeTeaser = $(".js-fade-slider");
	inspirationTeaser = $(".js-inspiration-teaser");
	consultantTeaser = $(".js-consultant-teaser");
	testimonialBox = $(".js-testimonial-slider");
	sliderCentermode = $(".js-slider-centermode");
	basicSlider = $(".js-slider-basic");
	categorySlider = $(".js-slider-category");
	teaserSliderContainerComponent = document.querySelectorAll("[data-teaser-slider-container-component]");

	rotationSlider();
	socialSlider();
	brandslider.init();
	fadeSlider();
	inspirationSlider();
	consultantSlider();
	testimonialSlider();
	_sliderCentermode();
	basicSliderInit();
	categorySliderInit();
	teaserSliderContainerComponentInit();
	fullWidthTeaserSlider.init();

	responsiveSliderReinitializer();
}

function basicSliderInit() {
	basicSlider.each(function () {
		const thisSlider = $(this);
		const configurations = {
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			infinite: true,
			centerMode: false,
			variableWidth: false,
			autoplay: true,
			draggable: true,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: true,
			dotsClass: "c-slider-control__dots",
		};
		const setAutoplaySpeed = function () {
			const speed = thisSlider.data("autorotation");
			thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
		};

		thisSlider
			.on("init", () => {
				setTimeout(() => {
					setAutoplaySpeed();
				}, 500);
			})
			.slick(configurations);
	});
}

function rotationSlider() {
	rotationTeaser.each(function () {
		let speedSlide;
		const thisSlider = $(this);
		const speed = thisSlider.data("autorotation");
		const firstSlide = $(".js-rotation-teaser-slides").first().data("speed");

		const configurations = {
			infinite: true,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: firstSlide != "" ? firstSlide : speed != "" ? speed : 3000,
			speed: 600,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: true,
			customPaging(slider, i) {
				const thumb = $(slider.$slides[i]).data("topic");
				if (thumb !== undefined) {
					return `<a href="javascript:void(0);" class="[ c-rotation-teaser__thumbs ]">${thumb}</a>`;
				}
				return '<button type="button" role="button"></button>';
			},
			responsive: [
				{
					breakpoint: 719,
					settings: {
						arrows: false,
						dots: true,
						customPaging(slider, i) {
							return $('<button type="button" data-role="none" role="button" tabindex="0" />').text(i + 1);
						},
					},
				},
			],
		};
		const setAutoplaySpeed = function (slick, currentSlide) {
			const curSlide = slick.$slides.filter(`[data-slick-index="${currentSlide}"]`);
			speedSlide = curSlide.data("speed");

			if (speedSlide != "") {
				thisSlider.slick("slickSetOption", "autoplaySpeed", speedSlide);
			} else {
				if (speed != "") {
					thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
				} else {
					thisSlider.slick("slickSetOption", "autoplaySpeed", 3000);
				}
			}
		};
		const handleVideos = function (slick, currentSlide) {
			const curSlide = slick.$slides.filter(`[data-slick-index="${currentSlide}"]`);
			const videoWrapper = curSlide.find(".js-video-player");
			const video = curSlide.find(".js-video-player video");

			video.each(function () {
				$(this).get(0).pause();
			});

			if (video.length) {
				if (!Modernizr.touchevents) {
					if (curSlide.hasClass("js-video-autoplay")) {
						video.on("loadeddata", () => {
							thisSlider.slick("slickPause");
							if (video.get(0).readyState >= 3) {
								video.get(0).play();
							}
						});
					}
				}

				videoWrapper.off("click").click(function () {
					const $this = $(this);

					if ($this.hasClass("is-paused") || videoWrapper.hasClass("is-finished")) {
						thisSlider.slick("slickPause");

						//for mobile devices
						//if enough data is available to start playing
						if (video.get(0).readyState == 4) {
							video.get(0).play();
						}
					} else if ($this.hasClass("is-playing")) {
						//for mobile devices
						//if enough data is available to start playing
						if (video.get(0).readyState == 4) {
							video.get(0).pause();
						}
					}
				});

				curSlide.find("video").on("ended", () => {
					thisSlider.slick("slickPlay");
				});
			}
		};

		thisSlider
			.on("init", (event, slick) => {
				setTimeout(() => {
					setAutoplaySpeed(slick, 0);
					handleVideos(slick, 0);
				}, 10);
			})
			.on("afterChange", (event, slick, currentSlide) => {
				setAutoplaySpeed(slick, currentSlide);
				handleVideos(slick, currentSlide);
			})
			.slick(configurations);
	});
}

function socialSlider() {
	socialTeaser.each(function () {
		const thisSlider = $(this);
		const configurations = {
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 1000,
			speed: 600,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: true,
			customPaging(slider, i) {
				const thumb = $(slider.$slides[i]).data("navigation");
				return `<a href="javascript:void(0);" class="[ c-social-slider__thumb ]  [ c-icon  c-icon--${thumb} ]"></a>`;
			},
		};
		const setAutoplaySpeed = function () {
			const speed = thisSlider.data("autorotation");
			thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
		};

		thisSlider
			.on("init", () => {
				setTimeout(() => {
					setAutoplaySpeed();
				}, 10);
			})
			.on("afterChange", () => {})
			.slick(configurations);
	});
}

function inspirationSlider() {
	inspirationTeaser.each(function () {
		const thisSlider = $(this);
		const speed = thisSlider.data("autorotation");
		const firstSlide = $(".js-inspiration-teaser-slides").first().data("speed");
		let speedSlide;
		const configurations = {
			infinite: true,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			slidesToShow: 2,
			slidesToScroll: 2,
			autoplay: true,
			autoplaySpeed: firstSlide != "" ? firstSlide : speed != "" ? speed : 3000,
			speed: 600,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: false,
			responsive: [
				{
					breakpoint: 719,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						customPaging(slider, i) {
							return $('<button type="button" data-role="none" role="button" tabindex="0" />').text(i + 1);
						},
					},
				},
			],
		};
		const setAutoplaySpeed = function (slick, currentSlide) {
			const curSlide = slick.$slides.filter(`[data-slick-index="${currentSlide}"]`);
			speedSlide = curSlide.data("speed");

			if (speedSlide != "") {
				thisSlider.slick("slickSetOption", "autoplaySpeed", speedSlide);
			} else {
				if (speed != "") {
					thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
				} else {
					thisSlider.slick("slickSetOption", "autoplaySpeed", 3000);
				}
			}
		};

		thisSlider
			.on("init", (event, slick) => {
				setTimeout(() => {
					setAutoplaySpeed(slick, 0);
				}, 10);
			})
			.on("afterChange", (event, slick, currentSlide) => {
				setAutoplaySpeed(slick, currentSlide);
			})
			.slick(configurations);
	});
}

function consultantSlider() {
	consultantTeaser.each(function () {
		const thisSlider = $(this);
		const configurations = {
			infinite: true,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			slidesToShow: 2,
			slidesToScroll: 2,
			autoplay: true,
			speed: 600,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: false,
			responsive: [
				{
					breakpoint: 719,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						customPaging(slider, i) {
							return $('<button type="button" data-role="none" role="button" tabindex="0" />').text(i + 1);
						},
					},
				},
			],
		};
		thisSlider.slick(configurations);
	});
}

function fadeSlider() {
	fadeTeaser.each(function () {
		const thisSlider = $(this);
		const configurations = {
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			draggable: true,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: false,
			fade: true,
			speed: 2000,
		};
		const setAutoplaySpeed = function () {
			const speed = thisSlider.data("autorotation");
			thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
		};

		thisSlider
			.on("init", () => {
				setTimeout(() => {
					setAutoplaySpeed();
				}, 500);
			})
			.slick(configurations);
	});
}

function testimonialSlider() {
	testimonialBox.each(function () {
		const thisSlider = $(this);
		const configurations = {
			settings: "unslick",
			infinite: true,
			arrows: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						autoplay: false,
						autoplaySpeed: 1000,
						speed: 600,
						slidesToShow: 1,
						slidesToScroll: 1,
						draggable: true,
						swipe: true,
						swipeToSlide: false,
						dots: true,
						customPaging(slider, i) {
							return $('<button type="button" data-role="none" role="button" tabindex="0" />').text(i + 1);
						},
					},
				},
			],
		};

		thisSlider.slick(configurations);
	});
}

function _sliderCentermode() {
	sliderCentermode.each(function () {
		smallslides = parseInt($(this).data("smallslides"));
		bigslides = parseInt($(this).data("bigslides"));
		midcount = parseInt($(this).data("midcount"));

		_addAutoPlay($(this));

		const thisSlider = $(this);
		const configurations = {
			infinite: true,
			slidesToShow: bigslides,
			slidesToScroll: bigslides,
			autoplay,
			speed: 600,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			arrows: arrow,
			centerMode: true,
			centerPadding: "80px",
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1279,
					settings: {
						slidesToShow: midcount,
						slidesToScroll: midcount,
					},
				},

				{
					breakpoint: 719,
					settings: {
						arrows: false,
						slidesToShow: smallslides,
						slidesToScroll: smallslides,
						centerPadding: "55px",
						dots: true,
					},
				},
			],
		};

		function setSlideVisibility() {
			//Find the visible  where aria-hidden="false"
			const visibleSlides = $('.js-slider-centermode > .slick-list > .slick-track > .slick-slide[aria-hidden="false"]');
			//Make sure all of the visible slides have an opacity of 1

			$(visibleSlides).each(function () {
				$(this).removeClass("has-opacity");
			});

			//Set the opacity of the first and last partial slides.
			$(visibleSlides).first().prev().addClass("has-opacity");
			$(visibleSlides).last().next().addClass("has-opacity");

			$(".js-slider-centermode-image").on("mousedown touchstart", () => {
				$(visibleSlides).first().prev().removeClass("has-opacity");
				$(visibleSlides).last().next().removeClass("has-opacity");
			});
		}

		thisSlider
			.on("init", () => {
				setSlideVisibility();
			})
			.on("beforeChange", () => {
				$(".slick-slide").each(function () {
					$(this).removeClass("has-opacity");
				});
			})
			.on("afterChange", () => {
				$(".slick-slide").each(() => {
					setSlideVisibility();
				});
			})
			.slick(configurations);
	});
}

function _addAutoPlay(slider) {
	autoplay = slider.data("autoplay");
	arrow = slider.data("arrow");
}

function categorySliderInit() {
	if (document.documentElement.clientWidth <= 719) {
		return;
	}
	categorySlider.each(function () {
		const thisSlider = $(this);
		const configurations = {
			prevArrow: `<button class="c-slider-control  c-slider-control--left [&.slick-disabled]:hidden" aria-label="Zurück">
			<svg class="svg-icon" style="transform: rotate(180deg)">
				<use xlink:href="#icon-arrow-fat-right" href="#icon-arrow-fat-right"/>
			</svg>
		</button>`,
			nextArrow: `<button class="c-slider-control c-slider-control--right [&.slick-disabled]:hidden" aria-label="Weiter">
			<svg class="svg-icon">
				<use xlink:href="#icon-arrow-fat-right" href="#icon-arrow-fat-right"/>
			</svg>
		</button>`,
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1370,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 1030,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 719,
					settings: "unslick",
				},
			],
		};

		thisSlider.slick(configurations);
	});
}

function teaserSliderContainerComponentInit() {
	teaserSliderContainerComponent.forEach((slider) => {
		if (slider.children.length < 2) return;
		const $slider = $(slider);
		const sliderContainer = slider.parentElement;
		const dataSliderProgress = sliderContainer.querySelector("[data-slider-progress]");
		const dataSliderProgressNumber = sliderContainer.querySelector("[data-slider-progress-number]");

		const updateCurrentSliderProgress = (currentSlide = $slider[0].slick.currentSlide + 1) => {
			dataSliderProgressNumber.innerText = `${currentSlide} | ${$slider[0].slick.slideCount}`;
		};

		const configurations = {
			arrows: false,
			speed: 600,
			autoplay: true,
			autoplaySpeed: 6000,
			pauseOnFocus: false,
			pauseOnHover: false,
		};

		$slider.on("init", () => {
			dataSliderProgress.style.width = "100%";
			slider.querySelectorAll(".slick-slide").forEach((slide) => {
				slide.classList.remove("hidden");
			});
		});

		$slider.slick(configurations);
		updateCurrentSliderProgress();

		const transitionClasses = ["transition-[width]", "ease-linear", "duration-[6000ms]"];

		$slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
			updateCurrentSliderProgress(nextSlide + 1);

			// remove transition classes to make it instantly reset progress
			dataSliderProgress.classList.remove(...transitionClasses);
			dataSliderProgress.style.width = "0";
		});

		$slider.on("afterChange", () => {
			dataSliderProgress.classList.add(...transitionClasses);
			dataSliderProgress.style.width = "100%";
		});

		sliderContainer.querySelector("[data-previous-arrow]")?.addEventListener("click", () => {
			$slider.slick("slickPrev");
		});

		sliderContainer.querySelector("[data-next-arrow]")?.addEventListener("click", () => {
			$slider.slick("slickNext");
		});
	});
}

function responsiveSliderReinitializer() {
	const reinit = () => {
		if (!categorySlider.hasClass("slick-initialized")) {
			categorySliderInit();
		}
	};

	window.addEventListener("orientationchange", reinit);
	window.addEventListener("resize", reinit);
}

export { init };
