// DOM
let $filterMobile;
let $filterItems;
let filterItemLinkDiv;
let $filterItemLink;
let $filterItemContent;
let filterCloseDiv;

// mobile Taps

// Settings

function init() {
	$filterMobile = $(".js-filter-mobile");
	$filterItems = $(".js-filter-mobile-items");

	// wird bei Jobs erst später in einem mustache template geladen
	filterItemLinkDiv = ".js-filter-link";
	$filterItemLink = $(".js-filter-link");
	$filterItemContent = $(".js-filter-item-content");
	filterCloseDiv = ".js-filter-mobile-close";

	$filterMobile.on("click", () => {
		if ($filterItems.hasClass("is-open")) {
			$filterItems.removeClass("is-open");
			$filterMobile.removeClass("is-open");
		} else {
			$filterItems.addClass("is-open");
			$filterMobile.addClass("is-open");
		}
		if ($(".js-sort-mobile").hasClass("is-open")) {
			$(".js-sort-mobile").removeClass("is-open");
			$(".js-sort-mobile-items").removeClass("is-open");
		}
	});

	$filterItems.on("click", filterItemLinkDiv, function (e) {
		$filterItemContent = $filterItemContent.length ? $filterItemContent : $(".js-filter-item-content");
		$filterItemLink = $filterItemLink.length ? $filterItemLink : $(filterItemLinkDiv);

		if ($filterItemLink.hasClass("is-open") && !$(this).hasClass("is-open")) {
			$filterItemLink.removeClass("is-open");
			$filterItemContent.removeClass("is-open");
		}

		if ($(this).hasClass("is-open")) {
			e.preventDefault();
			$(this).removeClass("is-open");
			$(this).parent().find($filterItemContent).removeClass("is-open");
		} else {
			e.preventDefault();
			$(this).addClass("is-open");
			$(this).parent().find($filterItemContent).addClass("is-open");
		}
	});

	$filterItems.on("click", filterCloseDiv, () => {
		$filterItemContent = $filterItemContent.length ? $filterItemContent : $(".js-filter-item-content");
		$filterItemLink = $filterItemLink.length ? $filterItemLink : $(filterItemLinkDiv);

		$filterItems.removeClass("is-open");
		$filterMobile.removeClass("is-open");
		$filterItemLink.removeClass("is-open");
		$filterItemContent.removeClass("is-open");
	});
}

export { init };
