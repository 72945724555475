/**
 * initpopUpOverlay
 */

import * as popUpOverlay from "@sharedJS/popUpOverlay";
import * as storage from "@sharedJS/storage";
import * as utils from "@sharedJS/utils";
import pushDataLayer from "@ocm/services/dataLayer.js";

//DOM
let $popUpLayer;
let $activatePopUp;
let $deactivatePopUp;
let $activeButton;
let $pageLoadPopUp;
let $multiPagePopUp;
let $parameterPopUp;
let $onTimePopUp;
let $onClickPopUp;

let popupName;

function init() {
	$popUpLayer = $(".js-classic-popup");
	$activatePopUp = $(".js-activate-popup");
	$pageLoadPopUp = $(".js-pageload-popup");
	$parameterPopUp = $(".js-parameter-popup");
	$onTimePopUp = $(".js-ontime-popup");
	$onClickPopUp = $(".js-onclick-popup");
	$multiPagePopUp = $(".js-multi-page-popup");

	// Activate Classic-PopUp on 'x' page loads
	if ($multiPagePopUp.length) {
		popupName = $multiPagePopUp.data("track-classic");
		_handleMultiPagePopUp();
	}

	// Activate Classic-PopUp on click
	if ($popUpLayer.length) {
		$activatePopUp.on("click", function () {
			$activeButton = $(this).data("layer-button");
			$onClickPopUp = $(`.js-onclick-popup[data-layer="${$activeButton}"]`);

			popUpOverlay.openPopup($onClickPopUp, { blur: false });
		});
	}

	// Activate Classic-PopUp on pageLoad
	$(window).load(() => {
		if ($pageLoadPopUp.length) {
			popUpOverlay.openPopup($pageLoadPopUp, { blur: false });
		}
	});

	// Activate Classic-PopUp when parameter is given
	$(window).load(() => {
		if ($parameterPopUp.length) {
			const param = "uid"; //todo global popup rework for shop and classic
			const showPopup = utils.getUrlParameter(param);
			if (showPopup) {
				popUpOverlay.openPopup($parameterPopUp, { blur: true });
			}
		}
	});

	// Activate Classic-PopUp after a period of time
	$(window).load(() => {
		function _countdownPopup() {
			if ($onTimePopUp.length) {
				popUpOverlay.openPopup($onTimePopUp, { blur: false });
			}
		}

		setTimeout(_countdownPopup, 3000);
	});

	// Activate close function after layers loaded
	_closeclassicPopUpLayer();
}

function _closeclassicPopUpLayer() {
	$deactivatePopUp = $(".js-close-popup");

	// Deactivate Classic-PopUp on click
	$deactivatePopUp.on("click", () => {
		popUpOverlay.closePopup($popUpLayer);
	});
}

// eslint-disable-next-line complexity
function _handleMultiPagePopUp() {
	// TODO: new concept for every popup including classic and shop
	const cookieName = "classicPageViews";
	// threshold after how many pageloads the pop up should be triggered
	const triggerThreshold = 3;
	// get pageViews value if cookie is set or default to 0
	let pageViews = parseInt(storage.getCookie(cookieName), 10) || 0;

	// return if the triggerThreshold is already reached
	if (pageViews > triggerThreshold) {
		return;
	}

	// return if another layer is present
	if ($pageLoadPopUp.length || $parameterPopUp.length || $onTimePopUp.length) {
		return;
	}
	// increase pageView count
	pageViews++;

	// show popup if pageView count is the same as the triggerThreshold
	if (pageViews === triggerThreshold) {
		setTimeout(() => {
			popUpOverlay.openPopup($multiPagePopUp, { blur: false });
		}, 1500);

		pushDataLayer({ event: "classic-multiPagePopup", popupName });
	}
	//set cookie to new value
	storage.setCookie(cookieName, pageViews, 2);
}

export { init };
