// DOM
let brandTeaser;

// lokal Variables
let bannerList;
let resizeTimerOut = null;

function init() {
	window.addEventListener("load", initOnLoad);
}

function initOnLoad() {
	brandTeaser = $(".js-brand-teaser");
	if (!brandTeaser.length) {
		return;
	}

	brandTeaser.addClass("is-visible");

	bannerList = [];

	brandTeaser.each(function () {
		bannerList.push({
			banner: $(this),
			items: $(this).find(".js-brands-teaser--item"),
			moreButton: $(this).find(".js-brands-teaser--more"),
		});
	});

	// Bind Actions
	$(window).on("resize", () => {
		if (resizeTimerOut) {
			clearTimeout(resizeTimerOut);
			resizeTimerOut = null;
		}
		resizeTimerOut = setTimeout(checkbrands, 250);
	});

	checkbrands();
	window.removeEventListener("load", initOnLoad);
}

function checkbrands() {
	for (let i = 0; i < bannerList.length; i++) {
		showAndHideBrands(bannerList[i]);
	}
}

function showAndHideBrands(bannerObj) {
	const bannerWidth = bannerObj.banner.width();
	const moreButtonWidth = bannerObj.moreButton.outerWidth();
	const remainingWidth = bannerWidth - moreButtonWidth;

	const bannerHeight = bannerObj.banner.height();
	const itemHeight = bannerObj.moreButton.height();
	const offsetTop = bannerHeight - itemHeight;

	bannerObj.items.each(function () {
		const thisOffsetLeft = $(this).position().left;
		const thisOffsetTop = $(this).position().top;
		const thisWidth = $(this).outerWidth();

		if (thisOffsetLeft + thisWidth > remainingWidth && thisOffsetTop == offsetTop) {
			$(this).addClass("is-not-visible");
		} else {
			$(this).removeClass("is-not-visible");
		}
	});
}

export { init };
