/**
 * Login Form Handling
 */

import commonPopup from "@JS/shop/10-login-component/commonPopup"; // für den Popup-Layer

let $exceptionLayer;
let isInitialized = false;

function init() {
	if (isInitialized) {
		return;
	}
	isInitialized = true;
	// DOM
	$exceptionLayer = $("#Exception-layer");

	if ($exceptionLayer.length < 1) {
		return;
	}
	$("#exception2Login").on("click", toggleExceptionToLogin);
}

function toggleExceptionToLogin() {
	if ($("#Registration-layer").length) {
		commonPopup.showLayer("Registration");
	} else if ($("#LoginRegistration-layer").length) {
		commonPopup.showLayer("LoginRegistration");
	}
}

export { init };
