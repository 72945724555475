//DOM
let $toggleWrapper;
let $togglePrevent;

function init() {
	$toggleWrapper = $(".js-offer-toggle");
	$togglePrevent = $(".js-toggle-prevent");

	$togglePrevent.on("click", (e) => {
		e.stopPropagation();
	});

	if ("ontouchstart" in window) {
		//check for touch device
		$toggleWrapper.on("click", function () {
			$(this).toggleClass("is-open");
		});
	} else {
		$toggleWrapper.hover(function () {
			$(this).toggleClass("is-open");
		});
	}
}

export { init };
