import Player from "@vimeo/player";

function init() {
	const fullWidthTeaserSliderContainerComponent = document.querySelectorAll(
		"[data-full-width-teaser-slider-container-component]"
	);

	fullWidthTeaserSliderContainerComponent.forEach((slider) => {
		if (slider.children.length <= 1) {
			initSingleSlide(slider.children[0]);
		} else {
			initSlider(slider);
		}
	});
}

function initSlider(slider) {
	const $slider = $(slider);
	const sliderContainer = slider.parentElement;

	const dataSliderProgress = sliderContainer.querySelector("[data-slider-progress]");
	const dataSliderProgressNumber = sliderContainer.querySelector("[data-slider-progress-number]");

	const updateCurrentSliderProgress = (currentSlide = $slider[0].slick.currentSlide + 1) => {
		dataSliderProgressNumber.innerText = `${currentSlide} | ${$slider[0].slick.slideCount}`;
	};

	const sliderConfiguration = {
		lazyLoad: "ondemand",
		arrows: false,
		speed: 600,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnFocus: false,
		pauseOnHover: false,
	};

	$slider.on("init", (event, slick) => {
		dataSliderProgress.style.width = "100%";

		slider.querySelectorAll(".slick-slide").forEach((slide) => {
			initSlideContent(slider, slide);
		});

		setTimeout(() => {
			slick.$slides[0]?.vimeoPlayer?.play();
		}, 5);
	});

	$slider.slick(sliderConfiguration);
	updateCurrentSliderProgress();

	const transitionClasses = ["transition-[width]", "ease-linear", "duration-[6000ms]"];

	$slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
		slick.$slides[currentSlide]?.vimeoPlayer?.stop();

		updateCurrentSliderProgress(nextSlide + 1);
		// remove transition classes to make it instantly reset progress
		dataSliderProgress.classList.remove(...transitionClasses);
		dataSliderProgress.style.width = "0";
	});

	$slider.on("afterChange", (event, slick, currentSlide) => {
		slick.$slides[currentSlide]?.vimeoPlayer?.play();

		dataSliderProgress.classList.add(...transitionClasses);
		dataSliderProgress.style.width = "100%";
	});

	sliderContainer.querySelector("[data-previous-arrow]")?.addEventListener("click", () => {
		$slider.slick("slickPrev");
	});

	sliderContainer.querySelector("[data-next-arrow]")?.addEventListener("click", () => {
		$slider.slick("slickNext");
	});
}

function initSingleSlide(slide) {
	slide.classList.remove("hidden");

	const player = initVimeoPLayer(slide);
	if (!player) return;

	player.setLoop(true);
	player.play();
}

function initSlideContent(slider, slide) {
	slide.classList.remove("hidden");

	const player = initVimeoPLayer(slide);
	if (!player) return;

	player.setLoop(false);
	player.on("ended", () => {
		$(slider).slick("slickNext");
	});

	// (3) make VideoPlayer accessible to the slide
	slide.vimeoPlayer = {};
	slide.vimeoPlayer.play = () => {
		player.play();
		$(slider).slick("slickPause");
	};
	slide.vimeoPlayer.stop = () => {
		player.pause();
		player.setCurrentTime(0);
		$(slider).slick("slickPlay");
	};
}

function initVimeoPLayer(slide) {
	// (1) check for vimeo-Video
	const iframe = slide.getElementsByTagName("iframe")[0];
	if (!iframe || !iframe.getAttribute("id").startsWith("videoframe_")) return;

	// (2) set iframe to URL
	const width = window.innerWidth;
	const mobileURL = makeVimeoURL(iframe.dataset.srcMobile);
	const desktopURL = makeVimeoURL(iframe.dataset.srcDesktop);
	iframe.src = width < 720 ? mobileURL : desktopURL;

	return new Player(iframe);
}

function makeVimeoURL(passedUrl) {
	passedUrl = passedUrl.replaceAll("&amp;", "&");
	const url = new URL(passedUrl);
	const hash = url.searchParams.get("h");
	const searchElements = [
		`h=${hash}`,
		"dnt=1",
		"autoplay=0",
		"loop=0",
		"muted=1",
		"controls=0",
		"byline=1",
		"keyboard=0",
		"portrait=0",
		"title=0",
	];
	url.search = `?${searchElements.join("&")}`;
	return url;
}

export { init };
