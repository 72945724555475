/**
 * initpopUpOverlay
 */

let currentPopup;
const popupQueue = [];

function init() {}

function openPopup(targetDiv, options) {
	// `targetDiv` might not be a jQuery selection
	if (!(targetDiv instanceof jQuery)) targetDiv = $(targetDiv);

	if (currentPopup) {
		popupQueue.push({ targetDiv, options });
		return;
	}
	currentPopup = targetDiv;

	options = options || {};

	const defaultOptions = {
		autoopen: true,
		blur: true,
		opacity: "0.8",
		escape: true,
		detach: false,
		transition: "all 0.2s ease-in",
		onopen: null,
		onclose: null,
	};

	$.extend(defaultOptions, options);

	if (targetDiv != null) {
		targetDiv.popup({
			autoopen: defaultOptions.autoopen,
			blur: defaultOptions.blur,
			opacity: defaultOptions.opacity,
			escape: defaultOptions.escape,
			detach: defaultOptions.detach,
			transition: defaultOptions.transition,
			onclose() {
				onCloseCallback(defaultOptions.onclose);
			},
			onopen: defaultOptions.onopen,
		});
	}
}

function onCloseCallback(callback) {
	if (typeof callback === "function") {
		callback();
	}
	checkForNextPopup();
}

function checkForNextPopup() {
	currentPopup = null;
	const _nextPopup = popupQueue.shift();
	if (_nextPopup) {
		openPopup(_nextPopup.targetDiv, _nextPopup.options);
	}
}

function closePopup(targetDiv) {
	if (targetDiv) {
		targetDiv.popup("hide");
	}
}

export { init, openPopup, closePopup };

// Popup data:
//ocusedelementbeforepopup
//popup-visible
//popupInitialized
//popupVisible
//popupoptions
