/**
 * ocm.classicMapAdmin.js
 *
 * Admin  panel for the google maps integration.
 *
 * Creates the admin layout via js "injection"
 *
 * Create and position new markers, edit existing markers
 *
 * Saves the Marker in a local variable which can be copied and then needs to be pasted in the CMS cockpit
 *
 * ////// REMOVE //////
 * Has an inital formatter because the objectstructure has changed
 * Search for "That's the spot" to jump to the formatter
 * ///////////////////
 *
 */

import * as classicMapConfig from "@classicJS/classicMapConfig";

var $adminPanel, $closeButton, $adminContent;

var markerList, adminActiveClass, cache;

var map, fieldConfig, typeConfig, flagConfig, anchorConfig;

var fieldArray;

function initializeMapAdmin(markers, _map) {
	map = _map;

	// append Infopanel only if admin is active
	var appendInfoPanel =
		'<div class="[ c-maps-admin__info-panel ] [ js-maps-info-panel ]"><div class="[ c-maps-admin__container ] [ js-maps-admin-container ]"><div class="[ js-maps-admin-content ]"></div></div><div class="[ c-maps-admin__close ] [ js-maps-admin-close ]"><i class="[ c-icon c-icon--close ]"></i></div></div>';
	$(".js-maps-admin-area").append(appendInfoPanel);

	$adminPanel = $(".js-maps-info-panel");
	$adminContent = $(".js-maps-admin-content");
	$closeButton = $(".js-maps-admin-close");

	adminActiveClass = "c-maps__marker-container--admin-active";

	markerList = [];
	cache = {};

	fieldConfig = classicMapConfig.getFieldConfig();
	typeConfig = classicMapConfig.getTypeConfig();
	flagConfig = classicMapConfig.getFlagConfig();
	anchorConfig = classicMapConfig.getAnchorConfig();

	fieldArray = fieldConfig;

	$(".js-maps-admin").removeClass("u-hide");

	// initialize external functions

	// private function calls
	_initializeCloseHandler();
	showCurrentZoomLevel();

	markers.forEach(function (marker) {
		google.maps.event.clearListeners(marker, "click");
		_setMarkerEventListener(marker);
		markerList.push(marker);
	});
	_createNewMarker(map);

	_saveMap(markers);
}

function _setMarkerEventListener(marker) {
	google.maps.event.addListener(marker, "click", function () {
		$adminPanel.addClass("is-active");

		var $currentMarker = $('.c-maps__marker-container[data-marker="' + marker.ocmItem.id + '"]');

		var isActive = $currentMarker.hasClass(adminActiveClass);

		if (!isActive) {
			$(".c-maps__marker-container").removeClass(adminActiveClass);
			$currentMarker.addClass(adminActiveClass);
			var oldZIndex = marker.getZIndex() || 1;
			marker.setZIndex(oldZIndex + 1);

			_setInfoPanelContent(marker);
			_initializeSingleUnlockButton(marker);
		}
	});
}

function _setInfoPanelContent(marker) {
	var generatedInput = [];
	var item = marker.ocmItem;

	$adminContent.empty();

	generatedInput.push("<h1>" + item.title + "</h1>");

	$.each(fieldArray, function (index, value) {
		switch (value.type) {
			case "text":
				generatedInput.push(
					'<div class="[ c-form-row ] [ o-layout ] "><div class="[ u-1/5 ] [ o-layout__item ]"><label class="[ c-maps-admin__label ]">' +
						value.label +
						'</label></div><div class="[ u-4/5 ] [ o-layout__item ]"><input class="[ o-text-input ] [ js-maps-admin-input-' +
						value.id +
						' ]" type="text" placeholder="' +
						value.label +
						'" class="u-p-small js-marker-' +
						value.id +
						'" value="' +
						(item[value.id] ? item[value.id] : "") +
						'"></div></div>'
				);
				if (value.description) {
					generatedInput.push(
						'<div class="[ c-form-row ] [ o-layout ] " style="margin-top:-10px"><div class="[ u-1/5 ] [ o-layout__item ]"></div><div class="[ o-layout__item ] [ u-4/5 ] [ c-maps-admin__field-description ]">' +
							value.description +
							"</div></div>"
					);
				}
				break;
			case "textarea":
				generatedInput.push(
					'<div class="[ c-form-row ] [ o-layout ] "><div class="[ u-1/5 ] [ o-layout__item ]"><label class="[ c-maps-admin__label ]">' +
						value.label +
						'</label></div><div class="[ u-4/5 ] [ o-layout__item ]"><textarea class="[ o-textarea ] [ js-maps-admin-input-' +
						value.id +
						" ] [ u-p-small js-marker-" +
						value.id +
						' ]" placeholder="' +
						value.label +
						'">' +
						(item[value.id] ? item[value.id] : "") +
						"</textarea></div></div>"
				);
				break;
			case "select":
				var selectType;
				var currentType;
				var optionsArray = [];

				switch (value.id) {
					case "type":
						selectType = typeConfig;
						currentType = "type";
						break;
					case "flag":
						selectType = flagConfig;
						currentType = "flag";
						break;
					case "anchor":
						selectType = anchorConfig;
						currentType = "anchor";
						break;
					default:
						selectType = typeConfig;
						currentType = "type";
				}
				$.each(selectType, function (index, value) {
					optionsArray.push(
						'<option value="' +
							value +
							'"' +
							(value === item[currentType] ? "selected" : "") +
							">" +
							value +
							"</option>"
					);
				});

				generatedInput.push(
					'<div class="[ c-form-row ] [ o-layout ] "><div class="[ u-1/5 ] [ o-layout__item ]"><label class="[ c-maps-admin__label ]">' +
						value.label +
						'</label></div><div class="[ u-4/5 ] [ o-layout__item ]"><select class="[ u-p-small ] [ js-maps-admin-input-' +
						value.id +
						' ]">' +
						optionsArray.join("") +
						"</select></div></div>"
				);
				break;
		}
	});

	var cacheClass = cache[item.id] ? "is-unlocked" : "";
	generatedInput.push(
		'<button data-id="' +
			item.id +
			'" class="[ o-btn c-btn--classic-o c-maps-admin__lock-button ] [ js-maps-admin-unlock-single ]' +
			" " +
			cacheClass +
			'">Unlock</button>'
	);

	generatedInput.push(
		'<a href="javascript:void(0);" class="o-btn c-btn--classic c-maps-admin__save-marker js-save-marker u-float-right" data-id="' +
			item.id +
			'">Speichern</a>'
	);

	$adminContent.append(generatedInput.join(""));

	_initializeCurrentMarkerSave(marker);
}

function _initializeSingleUnlockButton(marker) {
	$("body").off("click", ".js-maps-admin-unlock-single");
	$("body").off("click", '.js-maps-admin-unlock-single[data-id="' + marker.ocmItem.id + '"]');
	$("body").on("click", '.js-maps-admin-unlock-single[data-id="' + marker.ocmItem.id + '"]', function () {
		_toggleLockSingleMarker(marker);
	});
}

function _toggleLockSingleMarker(marker) {
	var isDraggable = marker.getDraggable();
	var $currentMarker = $('.c-maps__marker-container[data-marker="' + marker.ocmItem.id + '"]');
	var $unlockButton = $(".js-maps-admin-unlock-single");

	isDraggable = !isDraggable;
	marker.setDraggable(isDraggable);

	cache[marker.ocmItem.id] = isDraggable;

	if (isDraggable) {
		_changeMarkerPosition(marker);
		$unlockButton.addClass("is-unlocked");
		$currentMarker.addClass("is-unlocked");
		$unlockButton.html("lock");
	} else {
		$unlockButton.removeClass("is-unlocked");
		$currentMarker.removeClass("is-unlocked");
		$unlockButton.html("unlock");
	}
}

function _changeMarkerPosition(marker) {
	google.maps.event.addListener(marker, "dragend", function () {
		var newPosition = marker.getPosition();
		marker.ocmItem.lat = newPosition.lat();
		marker.ocmItem.lng = newPosition.lng();
	});
}

function _initializeCurrentMarkerSave(marker) {
	var $saveButton = $(".js-save-marker");
	$saveButton.on("click", _saveCurrentMarker.bind(null, marker));
	$saveButton.on("click", _updateMarkerLabel.bind(null, marker));
	$saveButton.on("click", _updateMarkerAnchor.bind(null, marker));
}

function _saveCurrentMarker(marker) {
	$.each(fieldConfig, function () {
		var field = this;
		var value = $(".js-maps-admin-input-" + field.id + "").val();

		switch (field.id) {
			case "label":
			case "title":
			case "link":
				var contentArray = value.split(",");
				marker.ocmItem[field.id] = contentArray;
				break;
			case "priority":
				var contentArray = value.split(",").map(Number);
				marker.ocmItem[field.id] = contentArray;
				break;
			default:
				marker.ocmItem[field.id] = $(".js-maps-admin-input-" + field.id + "").val();
		}
	});
}

function _updateMarkerLabel(marker) {
	var markerToUpdate = $('div[data-marker="' + marker.ocmItem.id + '"] .js-maps-marker-label');

	switch (marker.ocmItem.type) {
		case "store":
			if (marker.ocmItem.label != "") {
				markerToUpdate.html(marker.ocmItem.label.join("<br>"));
			} else {
				markerToUpdate.html(marker.ocmItem.title.join("<br>"));
			}
			break;
		case "multi":
			if (marker.ocmItem.label != "") {
				markerToUpdate.html(marker.ocmItem.label.join("<br>"));
			} else {
				markerToUpdate.html(marker.ocmItem.title.join("<br>"));
			}
			break;
		default:
			markerToUpdate.html('<img src="' + marker.ocmItem.icon + '" />');
	}
}

function _updateMarkerAnchor(marker) {
	var markerToUpdate = $('div[data-marker="' + marker.ocmItem.id + '"]');
	var markerPointToUpdate = markerToUpdate.find(".c-maps__marker-point");

	switch (marker.ocmItem.type) {
		case "store":
		case "multi":
			if (marker.ocmItem.anchor === "right") {
				markerToUpdate.addClass("c-maps__marker-container--right");
				markerPointToUpdate.addClass("c-maps__marker-point--right");
			} else {
				markerToUpdate.removeClass("c-maps__marker-container--right");
				markerPointToUpdate.removeClass("c-maps__marker-point--right");
			}
			break;
		default:
	}
}

function showCurrentZoomLevel() {
	var currentZoomLevel = map.getZoom();
	var zoomContainer =
		'<div class="[ c-maps-admin__zoom-level ] [ js-maps-admin-zoom ]"><span class="[ c-maps-admin__zoom-text ]">' +
		currentZoomLevel +
		"</span></div>";
	$("body").append(zoomContainer);

	map.addListener("zoom_changed", function () {
		currentZoomLevel = map.getZoom();
		$(".js-maps-admin-zoom span").text(currentZoomLevel);
	});
}

function _createNewMarker(map) {
	var createButton = $(".js-maps-create-marker");

	createButton.on("click", function () {
		if (createButton.hasClass("active")) {
			_clearListeners();
		} else {
			createButton.addClass("active");
			google.maps.event.addListener(map, "click", function (event) {
				_placeMarker(event.latLng, map);
				_clearListeners();
			});
		}
	});

	function _clearListeners() {
		createButton.removeClass("active");
		google.maps.event.clearListeners(map, "click");
	}
}

function _placeMarker(location, map) {
	var uniqueID = Date.now();

	var markerContent = '<div class="[ c-maps__marker-container ]" data-marker="' + uniqueID + '">';
	markerContent += '<div class="c-maps__marker-point"></div>';
	markerContent += '<div class="c-maps__marker-point-active"></div>';
	markerContent +=
		'<div class="[ c-maps__marker ] [ js-new-marker-title ]" id="' + uniqueID + '">New Marker ' + uniqueID + "</div>";
	markerContent += "</div>";

	var newMarker = new RichMarker({
		position: location,
		map: map,
		flat: true,
		draggable: false,
		anchor: RichMarkerPosition.LEFT,
		content: markerContent,
	});

	$("body").on("input", ".js-maps-admin-input-id", function () {
		var newMarkerTitle = $(".js-maps-admin-input-id").val();
		$("#" + uniqueID + "").html(newMarkerTitle);
	});

	newMarker.ocmItem = {
		id: uniqueID,
		type: "",
		title: "",
		lat: location.lat(),
		lng: location.lng(),
		address: "",
		link: "",
		priority: [17, 18, 19],
		icon: "",
	};

	markerList.push(newMarker);

	_setMarkerEventListener(newMarker);
}

function _saveMap() {
	var adminPanel = $(".c-maps-admin__admin-panel");
	var adminOutput = $(".js-maps-output");

	$(".js-maps-save-object").on("click", function () {
		var ocmObject = [];

		// That's the spot
		// initial formatting function
		// REMOVE AFTER INITIAL CONVERTING
		//////////////////////////////////

		$.each(markerList, function (index, item) {
			if (item.ocmItem.anchor === "") {
				item.ocmItem.anchor = "left";
			}

			$.each(fieldConfig, function (index, field) {
				if (!item.ocmItem[field.id]) {
					item.ocmItem[field.id] = "";
				}
			});

			if (item.ocmItem.label.constructor === Array) {
				item.ocmItem.label = item.ocmItem.label;
			} else {
				item.ocmItem.label = item.ocmItem.label.split(",");
			}

			if (item.ocmItem.title.constructor === Array) {
				item.ocmItem.title = item.ocmItem.title;
			} else {
				item.ocmItem.title = item.ocmItem.title.split(",");
			}

			// if(item.ocmItem.priority[0] === 17) {
			//     if(item.ocmItem.type === 'multi') {
			//         item.ocmItem.priority = [ 17, 18 ];
			//     }
			// }

			if (item.ocmItem.priority === 1 || item.ocmItem.priority === "1") {
				if (item.ocmItem.type === "multi") {
					item.ocmItem.priority = [17, 18];
				} else {
					item.ocmItem.priority = [17, 18, 19];
				}
			}

			if (item.ocmItem.priority === "2") {
				item.ocmItem.priority = [18, 19];
			}

			if (item.ocmItem.priority === "3") {
				item.ocmItem.priority = [19];
			}

			ocmObject.push(item.ocmItem);
		});

		var jsonObject = JSON.stringify(ocmObject, null, 2);

		adminOutput.text("<div><script>var ocmStores =" + jsonObject + "</script></div>");

		adminPanel.addClass("show");
	});

	$(".js-maps-select").on("click", _selectText.bind(null, "output"));

	$(".js-maps-close").on("click", function () {
		adminPanel.removeClass("show");
	});
}

function _selectText(containerid) {
	if (document.selection) {
		var range = document.body.createTextRange();
		range.moveToElementText(document.getElementById(containerid));
		range.select();
	} else if (window.getSelection) {
		var range = document.createRange();
		range.selectNode(document.getElementById(containerid));
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);
	}
}

function _initializeCloseHandler() {
	$closeButton.on("click", function () {
		$adminPanel.removeClass("is-active");
		$(".c-maps__marker-container").removeClass(adminActiveClass);
	});
}

export { initializeMapAdmin };
