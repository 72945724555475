/**
 * JS DROPDOWN FILTER CLASSIC
 */

import * as utils from "@sharedJS/utils";

let $dropDownFilter;
let $dropDownFilterInner;
let $dropDownCategory;
let $dropDownCategoryItem;
let $dropDownCategoryReset;
let $brandListButton;
let $brandListRow;
let $brandListItemLetter;

// Settings / Cahce
let filterElements;

function init() {
	// Dependencies

	// Init Bindings
	initOpenDropDown();
	initChangeCategory();

	// Settings / Cache
	filterElements = {};

	// Check for preselected Filter
	const _preSelectedCatID = utils.getUrlParameter("category");
	if (_preSelectedCatID) {
		const filterHeadline = _getFilterText(_preSelectedCatID);
		// only if Filter exists
		if (filterHeadline) {
			_setFilterHeadline(filterHeadline);
			_checkEachBrandsCategory(_preSelectedCatID);

			_checkBrandAvaibility();
			_jumpToLetter();
		}
	}
}

function initOpenDropDown() {
	$dropDownFilter = $(".js-dropdown-filter");
	$dropDownFilterInner = $(".js-dropdown-inner");

	$dropDownFilter.on("click", function (event) {
		event.preventDefault();

		// show or hide DropDown-Filter Inner
		if ($(this).hasClass("is-open")) {
			$dropDownFilter.removeClass("is-open");
			$dropDownFilterInner.removeClass("is-open");
		} else {
			$dropDownFilter.addClass("is-open");
			$dropDownFilterInner.addClass("is-open");
		}
	});
}

function initChangeCategory() {
	$dropDownCategoryItem = $(".js-dropdown-category-item");
	$dropDownCategoryReset = $(".js-dropdown-category-reset");

	// Click FilterItem
	$dropDownCategoryItem.on("click", function (event) {
		event.preventDefault();
		// set variable with content from clicked element
		const _chosenCategoryAttr = $(this).data("categories");
		// change main category
		_setFilterHeadline($(this).text());

		//check if any brand has the chosenCategoryText
		_checkEachBrandsCategory(_chosenCategoryAttr);

		_checkBrandAvaibility();
		_jumpToLetter();
	});

	// Click Filter Reset
	$dropDownCategoryReset.on("click", function (event) {
		event.preventDefault();

		_setFilterHeadline($(this).text());
		_resetFilter();
	});
}

// Check for Dropdown-Filter-Elemenmts and Cache them
function _getFilterText(filterID) {
	if (filterElements.length > 0) {
		return filterElements[filterID];
	}

	const _filterLinks = $dropDownFilterInner.find("a");
	_filterLinks.each(function () {
		const $this = $(this);
		const data = $this.data("categories");
		filterElements[data] = $this.text();
	});

	return filterElements[filterID];
}

function _checkEachBrandsCategory(chosenCategory) {
	$(".js-brandlist-branditem").each(function () {
		$(this).addClass("not-active");

		if ($(this).attr("data-categories")) {
			// Warum wird hier zunächst das attr abgefragt
			const $definedCategories = $(this).data("categories").split(",");
			if ($.inArray(chosenCategory, $definedCategories) != -1) {
				$(this).removeClass("not-active");
			}
		}
	});
}

function _setFilterHeadline(choosenFilterText) {
	$dropDownCategory = $(".js-dropdown-category");
	$dropDownCategory.html(choosenFilterText);
}

function _checkBrandAvaibility() {
	$brandListRow = $(".js-brandlist-row");
	$brandListItemLetter = $(".js-brandlist-letter");
	$brandListButton = $(".js-brandlist-button");

	const $enableddBrands = [];

	// disable brand row letter if not available
	$brandListRow.each(function () {
		if ($(this).find("li.not-active").length == $(this).find("li").length) {
			$(this).find($brandListItemLetter).removeClass("is-filtered");
		} else {
			$(this).find($brandListItemLetter).addClass("is-filtered");
			$enableddBrands.push($(this).attr("id"));
		}
	});

	// disable selection list letter if not available
	$brandListButton.each(function () {
		if ($.inArray($(this).data("letter"), $enableddBrands) != -1) {
			$(this).addClass("is-filtered");
		} else {
			$(this).removeClass("is-filtered");
		}
	});
}

function _resetFilter() {
	$(".js-brandlist-button").removeClass("is-filtered is-active");
	$(".js-brandlist-letter").removeClass("is-filtered is-active");
	$(".js-brandlist-branditem").removeClass("not-active");
}

function _jumpToLetter() {
	//trigger click on first letter without not-active
	const $disabledBrandListButton = $(".js-brandlist-button.is-filtered");
	$disabledBrandListButton.first().trigger("click");
}

export { init };
