import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";
import pushDataLayer from "@ocm/services/dataLayer";
import { hideModal, showModal } from "./infoModals";

const banderoleEl = document.getElementById("js-banderole");
const cartBanderoleEl = document.getElementById("js-cart-banderole");

const headerEl = document.getElementById("js-header");
const headerLogoEl = document.getElementById("c-logo__container");
const shopHomeButtonEl = document.getElementById("shop-home-button");
let documentHasScrollListener = false;

const modalId = "js-banderole-modal";
const cartModalId = "js-cart-banderole-modal";
const voucherModalId = "js-voucher-banderole-modal";
const voucherModalEl = document.getElementById(voucherModalId);

let voucherCode = null;

OcmMessenger.subscribe(messengerPublicationTypes.ROUTE_CHANGE_MOUNTED_APPS, ({ data: { mountedApps } }) => {
	if (mountedApps.includes("mf-cart")) {
		_hide(banderoleEl);
		_slideIn(cartBanderoleEl);
		return;
	}
	_hide(cartBanderoleEl);
	_slideIn(banderoleEl);
});

function _isCartPage() {
	return window.location.pathname.includes("/cart");
}

/*
interface show {
	content: Array<string | {
		text?: string
		bold?: boolean = false
		infoIcon?: false
		link?: string
		voucherCode?: string
	}>,
	modal: {
		headline: string
		text: string
	}
}

examples of usage for gtm in TUB-12463 and TUB-18096
*/

// eslint-disable-next-line complexity
function _getTheme(color) {
	const classList = [];
	switch (color) {
		case "red":
			classList.push("bg-red-primary", "text-white");
			break;
		case "black":
			classList.push("bg-black-primary", "text-white");
			break;
		case "pearl":
			classList.push("bg-pearl", "text-black-primary");
			break;
		case "champagne":
			classList.push("bg-champagne", "text-black-primary");
			break;
		case "smokyQuartz":
			classList.push("bg-smokyQuartz", "text-white");
			break;
		case "ebony":
			classList.push("bg-ebony", "text-white");
			break;
		case "actionColor":
			classList.push("bg-actionColor", "text-black-primary");
			break;
		case "silver":
			classList.push("bg-silver", "text-black-primary");
			break;
		case "gold":
			classList.push("bg-gold", "text-black-primary");
			break;
		case "lightBlue":
			classList.push("bg-lightBlue", "text-white");
			break;
		case "darkBlue":
			classList.push("bg-darkBlue", "text-white");
			break;
		default:
			classList.push("bg-gray-20", "text-black-primary");
			break;
	}
	return classList;
}
function setBanderolePosition(position) {
	if (position === "top") {
		headerEl.before(banderoleEl);
		banderoleEl.classList.add("z-[600]", "relative");
		headerLogoEl.classList.add("transition-[top]", "delay-0", "duration-150", "ease-in-out");
		_adjustHeaderLogoAnimation();
		window.addEventListener("resize", _adjustHeaderLogoAnimation);
	}
}

//ToDO: Achtung, param position und color evtl. nur temporär für den AB Test, nach Abstimmung löschen und fix ins Template übernehmen
function show({ position = "bottom", color = "gray", content, modal }) {
	banderoleEl.innerHTML = _getContentTemplate(content, modalId);
	_addModalContent(modal, modalId);
	setBanderolePosition(position);
	banderoleEl.classList.add(..._getTheme(color));

	const message = content.find((item) => item.text && !item.link)?.text ?? null;
	if (message) {
		document.querySelector("[data-track-banderole]")?.addEventListener("click", (e) => {
			e.preventDefault();
			pushDataLayer({
				event: "GAevent",
				event_category: "Banderole",
				event_action: message,
				event_label: window.recommendationData?.Recommendation?.pageData?.pagePath ?? null,
			});
			window.location.assign(e.currentTarget.href);
		});
	}

	_isCartPage() ? _hide(banderoleEl) : _slideIn(banderoleEl);
}

//ToDO: Achtung, param position und color evtl. nur temporär für den AB Test, nach Abstimmung löschen und fix ins Template übernehmen
function showVoucher({ position = "bottom", color = "gray", content, modal }) {
	if (!modal) return;

	voucherCode = content.find((item) => item.voucherCode)?.voucherCode ?? null;
	if (!voucherCode) return;
	banderoleEl.innerHTML = _getContentTemplate(content, voucherModalId);
	voucherModalEl.querySelector(".js-voucher-modal-text").innerText = modal.text ?? "";
	voucherModalEl.querySelector(".js-voucher-modal-input").value = voucherCode;
	voucherModalEl.querySelector(".js-voucher-modal-legal").innerText = modal.legal ?? "";
	voucherModalEl.querySelector(".js-voucher-modal-copy").addEventListener("click", _copyToClipboard);

	banderoleEl.querySelector("[data-track-voucher=show]").addEventListener("click", _trackVoucherModal);
	voucherModalEl.querySelector("[data-track-voucher=copy]").addEventListener("click", _trackVoucherModal);
	setBanderolePosition(position);
	banderoleEl.classList.add(..._getTheme(color));
	_isCartPage() ? _hide(banderoleEl) : _slideIn(banderoleEl);
}
function _adjustHeaderLogoAnimation() {
	if (!matchMedia("(min-width: 64em)").matches) {
		banderoleEl.style.display = "block";
		headerLogoEl.style.top = shopHomeButtonEl.style.top = `${banderoleEl.offsetHeight}px`;
		document.addEventListener("scroll", _calcStyleTop);
		documentHasScrollListener = true;
	} else {
		if (documentHasScrollListener) {
			document.removeEventListener("scroll", _calcStyleTop);
			headerLogoEl.style.top = shopHomeButtonEl.style.top = 0;
			documentHasScrollListener = false;
		}
	}
}
function _calcStyleTop() {
	if (window.scrollY <= banderoleEl.offsetHeight) {
		headerLogoEl.style.top = shopHomeButtonEl.style.top = `${banderoleEl.offsetHeight - window.scrollY}px`;
	} else {
		headerLogoEl.style.top = shopHomeButtonEl.style.top = 0;
	}
}

function showCart({ content, modal }) {
	cartBanderoleEl.innerHTML = _getContentTemplate(content, cartModalId);
	_addModalContent(modal, cartModalId);
	_slideIn(cartBanderoleEl);
}

function _slideIn(el) {
	if (el.hasChildNodes()) {
		el.style.display = "block";
		requestAnimationFrame(() => el.classList.add("slided-in"));
	}
}

function _hide(el) {
	el.style.display = "none";
	el.classList.remove("slided-in");
}

function _addModalContent(modal, id) {
	if (!modal) return;

	const modalEl = document.getElementById(id);
	modalEl.querySelector(".info-modal__header").innerText = modal.headline;
	modalEl.querySelector(".info-modal__body").innerText = modal.text;
}

function _getContentTemplate(content, modalId) {
	return (
		content
			// eslint-disable-next-line complexity
			.map((contentItem) => {
				if (typeof contentItem === "string") return contentItem;

				let formattedText =
					contentItem.bold && contentItem.text
						? `<strong class="font-semibold">${contentItem.text}</strong>`
						: contentItem.text || "";

				if (contentItem.link) {
					formattedText = `<a href="${contentItem.link}" class="c-banderole__link underline lg:ml-3" data-track-banderole>${formattedText}</a>`;
				}

				if (contentItem.infoIcon) {
					formattedText += `
						<button
							type="button"
							class="-my-1 ml-0.5 grid h-6 w-6 place-content-center"
							onclick='window.ocm.banderole.openModal("${modalId}", this);'
							aria-label="Hinweis anzeigen"
						>
							<svg class="svg-icon" aria-hidden="true"><use href="#icon-info"></use></svg>
						</button>
					`;
				}

				if (contentItem.voucherCode) {
					formattedText = `
						<button
							type="button"
							class="underline"
							onclick='window.ocm.banderole.openModal("${modalId}", this);'
							data-track-voucher="show"
						>Gutscheincode anzeigen</button>
					`;
				}

				// span wrapper is for smooth line breaks without infoIcon on a separate line
				return contentItem.infoIcon
					? `<span class="inline-flex items-center whitespace-nowrap">${formattedText}</span>`
					: formattedText;
			})
			.join("")
	);
}

const _copyToClipboard = async () => {
	if (!voucherCode) return;

	try {
		await navigator.clipboard.writeText(voucherCode);
		hideModal(voucherModalEl);
		setTimeout(_showToast, 360);
	} catch (error) {
		console.error("Writing to clipboard failed", error);
	}
};

const _trackVoucherModal = ({ target }) => {
	pushDataLayer({
		event: "GAevent",
		event_category: "Banderole",
		event_action: `${target.dataset.trackVoucher}_${voucherCode}`,
		event_label: window.recommendationData?.Recommendation?.pageData?.pagePath ?? null,
	});
};

const _showToast = () => {
	OcmMessenger.publish(messengerPublicationTypes.ADD_TOAST, { message: "Gutscheincode wurde kopiert" });
};

/**
 * @param {string} modalId
 * @param {HTMLButtonElement} triggerEl
 */
const openModal = (modalId, triggerEl) => {
	const modal = document.getElementById(modalId);
	if (!modal) return;

	showModal(modal, triggerEl);
};

export { show, showVoucher, showCart, openModal };
