let $textboxSlider;

// Dependencies

function init() {
	$textboxSlider = $(".js-textbox-slider");

	initTextboxSlider();
}

function initTextboxSlider() {
	$textboxSlider.each(function () {
		const thisSlider = $(this);
		const speed = thisSlider.data("autorotation");
		const firstSlide = $(".js-textbox-slides").first().data("speed");
		let speedSlide;
		const configurations = {
			infinite: true,
			prevArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--left ]  [ js-slider-control-left ]"></a>',
			nextArrow:
				'<a href="javascript:void(0);" class="[ c-slider-control  c-slider-control--right ]  [ js-slider-control-right ]"></a>',
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: firstSlide != "" ? firstSlide : speed != "" ? speed : 3000,
			speed: 600,
			draggable: true,
			swipe: true,
			swipeToSlide: false,
			pauseOnHover: true,
			pauseOnFocus: true,
			dots: true,
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						arrows: false,
					},
				},
			],
		};
		const setAutoplaySpeed = function (slick, currentSlide) {
			const curSlide = slick.$slides.filter(`[data-slick-index="${currentSlide}"]`);
			speedSlide = curSlide.data("speed");

			if (speedSlide != "") {
				thisSlider.slick("slickSetOption", "autoplaySpeed", speedSlide);
			} else {
				if (speed != "") {
					thisSlider.slick("slickSetOption", "autoplaySpeed", speed);
				} else {
					thisSlider.slick("slickSetOption", "autoplaySpeed", 3000);
				}
			}
		};
		const handleVideos = function (slick, currentSlide) {
			const curSlide = slick.$slides.filter(`[data-slick-index="${currentSlide}"]`);
			const videoWrapper = curSlide.find(".js-video-player");
			const video = curSlide.find(".js-video-player video");

			video.each(function () {
				$(this).get(0).pause();
			});

			if (video.length) {
				if (!Modernizr.touchevents) {
					if (curSlide.hasClass("js-video-autoplay")) {
						video.on("loadeddata", () => {
							thisSlider.slick("slickPause");
							if (video.get(0).readyState >= 3) {
								video.get(0).play();
							}
						});
					}
				}

				videoWrapper.off("click").click(function () {
					const $this = $(this);

					if ($this.hasClass("is-paused") || videoWrapper.hasClass("is-finished")) {
						thisSlider.slick("slickPause");

						//for mobile devices
						//if enough data is available to start playing
						if (video.get(0).readyState == 4) {
							video.get(0).play();
						}
					} else if ($this.hasClass("is-playing")) {
						//for mobile devices
						//if enough data is available to start playing
						if (video.get(0).readyState == 4) {
							video.get(0).pause();
						}
					}
				});

				curSlide.find("video").on("ended", () => {
					thisSlider.slick("slickPlay");
				});
			}
		};

		thisSlider
			.on("init", (event, slick) => {
				setTimeout(() => {
					setAutoplaySpeed(slick, 0);
					handleVideos(slick, 0);
				}, 10);
			})
			.on("afterChange", (event, slick, currentSlide) => {
				setAutoplaySpeed(slick, currentSlide);
				handleVideos(slick, currentSlide);
			})
			.slick(configurations);
	});
}

export { init };
