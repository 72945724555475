import { onModalOpen, onModalClosed } from "@ocm/services/services.modal";

const modal = document.querySelector(".js-modal");
const modalClose = document.querySelectorAll(".js-modal__close");

const init = () => {
	if (!(modal && modalClose)) return;

	modal.classList.add("c-modal--active");
	onModalOpen(modal);

	[...modalClose].forEach((btn) => btn.addEventListener("click", closeModal));
};

const closeModal = () => {
	modal.classList.remove("c-modal--active");
	onModalClosed();
};

export { init };
