/**
 * initCountdown
 * counter for campaign teaser
 * countdown format: 'February 08 2017 20:00:00 GMT+0100'
 */
import * as renderTemplate from "@sharedJS/renderTemplate";
import * as utils from "@sharedJS/utils";

let $container;

// DOM
let $template;
let $counter;

// Settings
let endDate;
let language;
let translations;

function getRemainingTime() {
	const _second = 1000;
	const _minute = _second * 60;
	const _hour = _minute * 60;
	const _day = _hour * 24;

	const now = new Date();
	const distance = Date.parse(endDate) - Date.parse(now);

	const days = Math.floor(distance / _day);

	const day = days === 1 ? "day" : "days";

	const countdown = {
		days,
		lang: language,
		translations,
		text: translations[day],
	};

	renderTemplate.render($container, $template, countdown);

	if (days < 1) {
		// no js- prefixed clas due to cms restrictions
		$counter.closest(".c-textbox__item").remove();
	}
}

//+++++++++++++++++++++++++++++
// public methods
//+++++++++++++++++++++++++++++

function setClassicCountDown(endDateJson) {
	// Initalize Counter

	// DOM-Elements
	$container = $("#classicCountdownContainer");
	$template = $("#classicCountdownTemplate");
	$counter = $(".js-classic-counter");

	// translations
	// todo: translations aus properties-file (ocm.translations in jsp), dann entfällt auch die Sprachabfrage
	language = utils.getLangcode();
	const defaultTranslation = { still: "in", day: "day", days: "days" };

	try {
		translations = window.translations[language];
	} catch (err) {
		console.error("no translations found");
	}
	translations = $.extend({}, defaultTranslation, translations);

	// End-Date
	const date = { year: 2017, month: 1, day: 1, hour: 0, minutes: 0, seconds: 0 };
	$.extend(date, endDateJson);

	endDate = new Date(date.year, date.month - 1, date.day, date.hour, date.minutes, date.seconds, 0);

	getRemainingTime();
}

export { setClassicCountDown };

// Sample Code / How to use classic counter
// To be used in any Classic-Headerteaser as "Textbox" (todo: general Countdown + spezific Countdown
/*

<script>

    var translations = {}
translations = {
    "en": {"still": "in", "day": "day", "days": "days"},
    "de": {"still": "Noch", "day": "Tag", "days": "Tage"},
    "es": {"still": "in", "day": "day", "days": "days"},
    "fr": {"still": "in", "day": "day", "days": "days"},
    "it": {"still": "in", "day": "day", "days": "days"},
    "pl": {"still": "in", "day": "day", "days": "days"},
    "ru": {"still": "in", "day": "day", "days": "days"},
    "zh": {"still": "in", "day": "day", "days": "days"},
    "cs": {"still": "in", "day": "day", "days": "days"},
    "ko": {"still": "in", "day": "day", "days": "days"}
}
$(function () {
    ocm.classicCountdown.setClassicCountDown({
        "year": 2018,
        "month": 11,
        "day": 24,
        "hour": 10,
        "minutes": 0,
        "seconds": 0
    });
}); </script>

<script type="text/html" id="classicCountdownTemplate">
    <span class="[ c-cl-counter__span ]">{{translations.still}}</span>
    <div class="[ c-cl-counter__days ] [ js-counter-day ]">{{days}}</div>
    <span class="[ c-cl-counter__span ]">{{text}}</span>
</script>

<div id="classicCountdownContainer"></div>

 */
