/**
 * ocm.classicMapBuildings.js
 *
 * Creates polygons and polylines for google maps. The coordinates are defined in the CMS cockpit. These polygons represent the buildings while the polylines represent the separation inside a room.
 *
 */
import * as utils from "@sharedJS/utils";

// create areas
function createPolygonAreas(google, map) {
	$.each(ocmAreas, function (i) {
		var ocmBuildings = this.buildings;

		$.each(ocmBuildings, function () {
			var googleLatLng = [];
			var color = this.color;

			$.each(this.coords, function () {
				googleLatLng.push(new google.maps.LatLng(this[0], this[1]));
			});

			var buildingPath = new google.maps.Polygon({
				paths: googleLatLng,
				strokeWeight: 0,
				fillColor: color,
				fillOpacity: 1,
			});
			buildingPath.setMap(map);
		});
	});
}

function createPolyline(google, map) {
	$.each(ocmLines, function (i) {
		var ocmBuildings = this.buildings;

		$.each(ocmBuildings, function () {
			var googleLatLng = [];
			var color = utils.lightenDarkenColor(this.color, 30);

			$.each(this.coords, function () {
				googleLatLng.push(new google.maps.LatLng(this[0], this[1]));
			});

			var buildingPath = new google.maps.Polyline({
				path: googleLatLng,
				strokeWeight: 2,
				strokeColor: color,
				strokeOpacity: 1,
			});
			buildingPath.setMap(map);
		});
	});
}

export { createPolyline, createPolygonAreas };
